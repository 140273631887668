export class ProfileComposition {
    partId: number;

    constructor (partId: number) {
        this.partId = partId;
    };
}

export class ProfilePartDto {
    profileId: number;
    inherited: boolean; // 0 or 1

    constructor (profileId: number, inherited: boolean) {
        this.profileId = profileId;
        this.inherited = inherited;
    };
}

