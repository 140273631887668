import { IActionInputInfo } from "../../../../models/dtos/data-provider/ConnectorDto";
import { ParameterMappingType } from "../../../../models/dtos/data-provider/DataQueryDto";

export class DataQueryFormParameterMapping {
  actionInputName: string;
  description?: string;

  type?: ParameterMappingType;
  providerParameter?: string;
  parameters?: string[];
  standard: boolean;
  value?: string;

  constructor(
    nameOrConnector: string | IActionInputInfo,
    description?: string,
    type?: ParameterMappingType,
    providerParameter?: string,
    parameters: string[] = [],
    standard: boolean = true,
    value?: string
  ) {
    if (typeof nameOrConnector === "string") {
      this.actionInputName = nameOrConnector;
      this.description = description;
      this.type = type;
      this.providerParameter = providerParameter;
      this.parameters = parameters;
      this.standard = standard;
      this.value = value;
    } else {
      this.actionInputName = nameOrConnector.name;
      this.description = undefined;
      this.type = undefined;
      this.providerParameter = undefined;
      this.parameters = undefined
      this.standard = true;
      this.value = value;
    }
  }
}

export type DataQueryForm = {
  id?:number,
  dataQueryTypeId:number,
  action?:string,
  parameterMapping:DataQueryFormParameterMapping[]
}
