
export class RelationTypeDto {
    fromThingTypeId: number;
    toThingTypeId: number;
    name: string;
    description?: string;
    isConstrained: boolean;

    constructor(
        fromThingTypeId: number, 
        toThingTypeId: number, 
        name: string,
        isConstrained: boolean, 
        description?: string) 
    {
        this.fromThingTypeId = fromThingTypeId;
        this.toThingTypeId = toThingTypeId;
        this.name = name;
        this.isConstrained = isConstrained;
        this.description = description;
    };
}

export class RelationTypeCompleteDto extends RelationTypeDto {
    id: number;

    constructor(
        id: number, 
        fromThingTypeId: number, 
        toThingTypeId: number, 
        name: string,
        isConstrained: boolean, 
        description?: string) 
    {
        super(fromThingTypeId, toThingTypeId, name, isConstrained, description);
        this.id = id;
    };
}