import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbsContainer } from "./Breadcrumbs.styles";
import useBreadcrumbHook from "./Breadcrumbs.hook";
import { Link, useMatches } from "@plasma/ui.application.router";
import { Breadcrumb } from "antd";
import { useStore } from "../../../stores/store";
import { observer } from "mobx-react-lite";

export default observer (function Breadcrumbs() {
  const { i18n,t } = useTranslation();
  const logic = useBreadcrumbHook();
  const matches = useMatches();
const {ActionStore} = useStore()

  const crumbItems = useMemo(() => {

    let crumbs = matches
      .filter((match: any) => Boolean(match.handle?.crumb))
      .map((match: any) => ({
        path: match.pathname,
        elements: match.handle.crumb(match.params, match.data),
      }));

    return crumbs.length > 0
      ? crumbs.map((c) => ({
          title: t(c.elements[0]),
          path: c.path,
        }))
      : [{ title: "404 Page not found", path: null }];
  }, [matches,i18n.language]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumb style={{ color: "black" }}>
        {[
          ...crumbItems.map((c, ii) => (
            <Breadcrumb.Item key={ii}>
              {ii < crumbItems.length - 1 ? (
                <Link className="bread-link" to={c.path}>
                  {t(c.title)}
                </Link>
              ) : (
                <>{t(c.title)}</>
              )}
            </Breadcrumb.Item>
          )),
        ]}
      </Breadcrumb>
      <div className="action-bar">
        {ActionStore.actions}
      </div>
    </BreadcrumbsContainer>
  );
}
)

