import React from 'react';

import { Route } from '@plasma/ui.application.router';

const Selection = React.lazy(() => import('.'));

export const DataProviderSelectionRoutes = [
  new Route({
    path: '',
    element: Selection,
  }),
];
