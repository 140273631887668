import React from 'react';

import { Route } from '@plasma/ui.application.router';
import services from "../../../../api/agent";

const CreateProfile = React.lazy(() => import('.'));

export const CreateProfileRoutes = [
  new Route({
    path: 'create',
    element: CreateProfile,
    loader: ()=> {
      return {type:"create"}
    },
    handle: {
      crumb: (_params: any) => {
        return ["profiles.create.title"];
      },
    },
  }),
  new Route(  {
    path: "edit/:id",
    element: CreateProfile,
    children: [],
    loader: async ({ params }) => {
      const data = await services.profiles
        .getById(Number.parseInt(params.id!))
        .then((respone) => respone.data);
      return {name:data.name,type:"edit"};
    },
    handle: {
      crumb: (_params: any, data: any) => {
        return [data.name];
      },
    },
  }),
  new Route(  {
    path: "view/:id",
    element: CreateProfile,
    children: [],
    loader: async ({ params }) => {
      const data = await services.profiles
        .getById(Number.parseInt(params.id!))
        .then((respone) => respone.data);
      return {name:data.name,type:"view"};
    },
    handle: {
      crumb: (_params: any, data: any) => {
        return [data.name];
      },
    },
  })
];