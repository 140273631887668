import { Navigate, Route, RouteObject } from "@plasma/ui.application.router";
import { AboutRoutes } from "./pages/about";

import React from "react";
import { Layout } from "./components/layout";
import { SystemRoutes } from "./pages/systems/systems.routes";
import { ConfigurationRoutes } from "./pages/configuration/configuration.routes";

export const routes: RouteObject[] = [
  new Route({
    path: "/",
    element: Layout,
    children: [
      new Route({
        path: "",
        element: () => <Navigate to="/systems" replace />,
      }),
      ...SystemRoutes,
      ...ConfigurationRoutes,
      new Route({
        path: "/about",
        children: [...AboutRoutes],
        handle: {
          crumb: (_params: any) => {
            return ["about.title"];
          },
        },
      }),
    ],
  }),
];
