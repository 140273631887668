import React from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../../stores/QueryClient";
import { ThingTypeCompleteDto } from "../../../../models/dtos/thing-type/ThingTypeDtos";

export default function ThingTypeGeneralForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const cached = queryClient.getQueryData<{ thingType: ThingTypeCompleteDto }>([
    "selectedThingType",
  ]);
  return (
    <div className="form-container">
      <Form
        initialValues={cached?.thingType}
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={onChange}
      >
        <FormField required name={"name"} label={t("thing-type.name")}>
          <TextInput disabled={viewOnly}/>
        </FormField>
        <FormField name={"description"} label={t("thing-type.description")}>
          <TextArea rows={5} disabled={viewOnly}/>
        </FormField>
      </Form>
    </div>
  );
}
