import React, { useEffect } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import services from "../../../api/agent";
import { queryClient } from "../../../stores/QueryClient";
import CheckboxGroup from "../../../components/shared/CheckboxGroup/CheckboxGroup";
import { DataProvider } from "../../../models/dtos/data-provider/DataProviderDto";

export default function SystemDataProvidersForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean
}) {
  const { t } = useTranslation();
  const { data } = useQuery(["dataProviders"], services.dataProviders.getAll);
  const cached = queryClient.getQueryData<{ associatedDataProviders: DataProvider[] }>([
      "selectedSystem",
    ]);

  const methods = useForm()
  useEffect(()=>{
    if(cached?.associatedDataProviders)
      methods.setValue("data-providers",cached?.associatedDataProviders.map((e)=>(e.id)))
  },[])
  return (
    <div className="form-container">
      <Form
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={(e) => onChange(e)}
        methods={methods}
      >
        <FormField name={"data-providers"}>
          <CheckboxGroup 
            data={data?.data.map((d)=>({id:d.id,name:d.name}))??[]}
            disabled={viewOnly}
          />
        </FormField>
      </Form>
    </div>
  );
}