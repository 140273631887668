import React, { useEffect, useState } from "react";
import {
  useLoaderData,
  useNavigate,
  useParams,
} from "@plasma/ui.application.router";
import {
  DataProviderBase,
  Parameter,
} from "../../../../models/dtos/data-provider/DataProviderDto";
import { DataQueryForm } from "./QueriesClass";
import DataProviderGeneralForm from "./DataProviderGeneralForm";
import DataProviderConnectionForm from "./DataProviderConnectionForm";
import DataProviderParameterForm from "./DataProviderParameterForm";
import DataProviderQueriesForm from "./DataProviderQueriesForm";
import { queryClient } from "../../../../stores/QueryClient";
import {
  Connector,
  DataProviderConnectorDto,
  ConnectorParameterDto,
} from "../../../../models/dtos/data-provider/ConnectorDto";
import services from "../../../../api/agent";
import {
  DataQueryBase,
  ParameterMapping,
} from "../../../../models/dtos/data-provider/DataQueryDto";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStore } from "../../../../stores/store";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";
import { AxiosError } from "axios";

function useCreateDataProviderHook() {
  const loaderData = useLoaderData() as { type: "create" | "edit" | "view" };
  const actionType = loaderData.type;
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const deleteHandler = async (name:string|undefined) => {
    if (id) await services.dataProviders.deleteById(parseInt(id));
    return name;
  };

  const { mutate: deleteProvider, isLoading: isDeleting } = useMutation({
    mutationFn: deleteHandler,
     onSuccess: (name) => {
        notificationant.success({
          message: t("notification.success.title"),
          description: (
            <Trans
              i18nKey={"notification.delete.success.description"}
              values={{
                itemType: t("data-providers.title.single"),
                itemName: name,
              }}
            />
          ),
        });
        closeHandler();
      },
      onError: (error) => {
        notificationant.error({
          message: t("notification.delete.error.title"),
          description: (
            <Trans
              i18nKey={"notification.delete.error.description"}
              values={{ itemType: t("data-providers.title.single"), itemName: "" }}
            />
          ),
        });
      },
  });
  const { data: selectedProvider } = useQuery(
    ["selectedDataProvider"],
    () =>
      Promise.all([
       services.dataProviders.getById(parseInt(id!)),
       services.dataProviders.getConnection(parseInt(id!)),
       services.dataProviders.getDataQueries(parseInt(id!)),
      ]).then((r) => {
        return { provider: r[0].data,connector:r[1].data,queries:r[2].data };
      }),
    { enabled: !!id, refetchOnWindowFocus: false }
  );
  const { ActionStore } = useStore();

  useEffect(() => {
    if (loaderData.type === "edit" || loaderData.type === "view") {
      ActionStore.setActions(
        <>
          {loaderData.type === "view" ? (
            <Button
              title="Edit"
              onClick={() =>
                navigate(`/configuration/data-providers/edit/${id}`)
              }
            />
          ) : null}
          <ConfirmModal
            valueToValidate={selectedProvider?.provider.name}
            okButtonType="danger"
            content={
              <Trans
                i18nKey={"confirm.delete.description"}
                values={{ itemName: selectedProvider?.provider.name }}
              />
            }
            title={t("confirm.delete.title")}
            okText={t("button.delete")}
            cancelText={t("button.cancel")}
            confirm={true}
            onOk={() => deleteProvider(selectedProvider?.provider.name)}
          >
            <Button
              loading={isDeleting}
              type="secondary"
              title={t("button.delete")}
              icon={<Icon name="delete_outline" />}
            />
          </ConfirmModal>
        </>
      );
    }
  }, [i18n.language, isDeleting, selectedProvider,loaderData]);

  useEffect(()=>{
    return () => {
      queryClient.removeQueries(["selectedDataProvider"]);
      ActionStore.clearNodes();
    };
  },[])

  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [generalData, setGeneralData] = useState<{
    name: string;
    dataProviderTypeId: number;
    description?: string;
  }>();
  const [parameterData, setParameterData] = useState<{
    parameter: Parameter[];
  }>({parameter:[]});
  const [connectionData, setConnectionData] = useState<{
    connectionType: string;
    url: string;
  }>();
  const [queryData, setQueryData] = useState<{
    [key: string]: DataQueryForm;
  }>();
  const navigate = useNavigate();
  const nextStepHandler = () => {
    setCurrentStep(currentStep + 1);
  };

  const saveHandler = async () => {
    let newDataProviderId:number;
    if (actionType==="create") {
      if (generalData && parameterData && connectionData && queryData) {
        try {
          setIsSaving(true);
          const providerBase = new DataProviderBase(
            generalData.dataProviderTypeId,
            generalData.name,
            generalData.description,
            parameterData.parameter
          );
  
          
          try {
            newDataProviderId = (
              await services.dataProviders.createDataProvider(providerBase)
            ).data.id;
          }
          catch (e){
            const errorMessage = t("notification.create.error.description", {
                itemType: t("data-providers.title.single"),
                itemName: "",
                error: e instanceof AxiosError ? e.response?.data : ""
            });
            notificationant.error({
                message: t("notification.create.error.title"),
                description: (
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                ),
            });
            return
          }
  
          if (connectionData) {
            const cached = queryClient.getQueryData<{ data: Connector[] }>([
              "connectors",
            ]);
  
            const name = connectionData.connectionType;
            const configs = cached?.data
              .find((c) => c.name === name)
              ?.connectionParameters.map((c) => c.name);
            const realParams = Object.entries(connectionData);
            const realConfigs = realParams.filter((p) => configs?.includes(p[0])&&p[1]);
            const connection = new DataProviderConnectorDto(
              name,
              realConfigs.map((c) => new ConnectorParameterDto(c[0], c[1]))
            );
            
            try {
                await services.dataProviders.createConnection(
                  newDataProviderId,
                  connection
                );              
            }
            catch (e){
              const errorMessage = t("notification.create.error.description", {
                  itemType: t("data-provider.connection-type"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
              notificationant.error({
                  message: t("notification.create.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e
            }
          }
  
          for (const key in queryData) {
            if (Object.prototype.hasOwnProperty.call(queryData, key)) {
              const dataQuery = queryData[key];
              const newDataQuery = new DataQueryBase(
                dataQuery.dataQueryTypeId,
                newDataProviderId,
                dataQuery.action,
                dataQuery.parameterMapping.map(
                  (e) =>
                    new ParameterMapping(
                      e.actionInputName,
                      e.type,
                      e.value,
                      e.providerParameter,
                      e.parameters
                    )
                )
              );
              try {
                await services.dataProviders.createDataQuery(
                  newDataProviderId,
                  newDataQuery
                );
              }
              catch (e){
                const errorMessage = t("notification.create.error.description", {
                    itemType: t("data-provider.data-queries.title"),
                    itemName: "",
                    error: e instanceof AxiosError ? e.response?.data : ""
                });
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    ),
                });
                throw e
              }
            }
          }
          notificationant.success({
            message: t("notification.success.title"),
            description: (
              <Trans
                i18nKey={"notification.create.success.description"}
                values={{ itemType: t("data-providers.title") , itemName:""}}
              />
            ),
          });
          navigate("/configuration/data-providers");
        } catch (e) {
          try {
            await services.dataProviders.deleteById(
              newDataProviderId!
            );
          } catch {}
        } finally {
          setIsSaving(false);
        }
      }
    }
    else{
      if (generalData && parameterData && connectionData && queryData) {
        try {
          setIsSaving(true);
          const providerBase = new DataProviderBase(
            generalData.dataProviderTypeId,
            generalData.name,
            generalData.description,
            parameterData.parameter
          );

          try {
            newDataProviderId = selectedProvider!.provider.id
            await services.dataProviders.updateById(newDataProviderId,providerBase)
          }
          catch (e){
            const errorMessage = t("notification.update.error.description", {
                itemType: t("data-providers.title.single"),
                itemName: "",
                error: e instanceof AxiosError ? e.response?.data : ""
            });
            notificationant.error({
                message: t("notification.update.error.title"),
                description: (
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                ),
            });
            throw e
          }
  
          if (connectionData) {
            const cached = queryClient.getQueryData<{ data: Connector[] }>([
              "connectors",
            ]);
  
            const name = connectionData.connectionType;
            const configs = cached?.data
              .find((c) => c.name === name)
              ?.connectionParameters.map((c) => c.name);
            const realParams = Object.entries(connectionData);
            const realConfigs = realParams.filter((p) => configs?.includes(p[0])&&p[1]);
            const connection = new DataProviderConnectorDto(
              name,
              realConfigs.map((c) => new ConnectorParameterDto(c[0], c[1]))
            );
            
            try{
              await services.dataProviders.updateConnection(newDataProviderId,connection)
            }
            catch (e){
              const errorMessage = t("notification.update.error.description", {
                  itemType: t("data-provider.connection-type"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
              notificationant.error({
                  message: t("notification.update.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e
            }
          }
  
          for (const key in queryData) {
            if (Object.prototype.hasOwnProperty.call(queryData, key)) {
              const dataQuery = queryData[key];
              const newDataQuery = new DataQueryBase(
                dataQuery.dataQueryTypeId,
                newDataProviderId,
                dataQuery.action,
                dataQuery.parameterMapping.map(
                  (e) =>
                    new ParameterMapping(
                      e.actionInputName,
                      e.type,
                      e.value,
                      e.providerParameter,
                      e.parameters
                    )
                )
              );

              if (dataQuery.id) {
                try {
                  await services.dataProviders.updateDataQuery(
                    newDataProviderId,
                    dataQuery.id!,
                    newDataQuery
                  );
                }
                catch (e){
                  const errorMessage = t("notification.update.error.description", {
                      itemType: t("data-provider.data-queries.title"),
                      itemName: "",
                      error: e instanceof AxiosError ? e.response?.data : ""
                  });
                  notificationant.error({
                      message: t("notification.update.error.title"),
                      description: (
                          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                      ),
                  });
                  throw e
                }
              }
              else{
                try {
                  await services.dataProviders.createDataQuery(
                    newDataProviderId,
                    newDataQuery
                  );
                }
                catch (e){
                  const errorMessage = t("notification.create.error.description", {
                      itemType: t("data-provider.data-queries.title"),
                      itemName: "",
                      error: e instanceof AxiosError ? e.response?.data : ""
                  });
                  notificationant.error({
                      message: t("notification.create.error.title"),
                      description: (
                          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                      ),
                  });
                  throw e
                }
              }              
            }
          }
          notificationant.success({
            message: t("notification.success.title"),
            description: (
              <Trans
                i18nKey={"notification.update.success.description"}
                values={{ itemType: t("data-providers.title.single") , itemName:""}}
              />
            ),
          });
          navigate("/configuration/data-providers");
        } catch (e) {
          
        } finally {
          setIsSaving(false);
        }
      }
    }
    
  };

  const steps = [
    {
      title: "data-provider.step.general.title",
      icon: "sell",
      description: "data-provider.step.general.description",
      element: (
        <DataProviderGeneralForm
          onSubmit={nextStepHandler}
          index={0}
          key={0}
          onChange={setGeneralData}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
      title: "data-provider.step.parameters.title",
      icon: "tune",
      description: "data-provider.step.parameters.description",
      element: (
        <DataProviderParameterForm
          index={1}
          key={1}
          onChange={setParameterData}
          onSubmit={nextStepHandler}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
      title: "data-provider.step.connections.title",
      icon: "webhook",
      description: "data-provider.step.connection.description",
      element: (
        <DataProviderConnectionForm
          index={2}
          key={2}
          onSubmit={nextStepHandler}
          onChange={setConnectionData}
          viewOnly={actionType === "view"}
        />
      ),
    },

    {
      title: "data-provider.step.queries.title",
      icon: "query_stats",
      description: "data-provider.step.queries.description",
      element: (
        <DataProviderQueriesForm
          index={3}
          key={3}
          onSubmit={saveHandler}
          parameters={parameterData?.parameter}
          connectorName={connectionData?.connectionType!}
          onChange={setQueryData}
          viewOnly={actionType === "view"}
        />
      ),
    },
  ];

  const prevStepHandler = () => {
    setCurrentStep(currentStep - 1);
  };
  
  const closeHandler = () => {
    navigate("/configuration/data-providers");
  };

  return {
    currentStep,
    steps,
    nextStepHandler,
    closeHandler,
    prevStepHandler,
    setCurrentStep,
    isSaving,
    actionType,
  };
}

export default useCreateDataProviderHook;
