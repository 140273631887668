import React, { useEffect } from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";


import themes from "../../../themes";
import { MainContainer } from "./relation-types.styles";
import ClickableItemCard from "../../../components/shared/ClickableItem/ClickableItem";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../../stores/store";
import TitlePage from "../../../components/shared/TitlePage";
import { ClickableItemMetadata } from "../../../components/shared/ClickableItem/ClickableItem.types";

import Button from '@plasma/ui.general.button';

import { Icon } from "@plasma/ui.general.icon";
import { useNavigate } from "@plasma/ui.application.router";
import useRelationTypesHook from "./relation-types.hook";
import Tag from '@plasma/ui.display.tag'

function RelationTypes(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { i18n, t } = useTranslation();
    const {relationTypeOverviewData, isLoading} = useRelationTypesHook();
    const { ActionStore } = useStore();
      const navigate = useNavigate()
    useEffect(() => {
        ActionStore.setActions(<Button  title={t("button.create.relation-type")} icon={<Icon name="add"/>} onClick={()=>navigate("create")} />);
        return(()=>{
          ActionStore.clearNodes()
        })
      }, [i18n.language]);

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                        {isLoading ? (
                    <SmsSpin />
                ) : relationTypeOverviewData ? (
                    <div className="item-container">
                    {relationTypeOverviewData.map((p) => 
                        <ClickableItemCard
                        item={
                            new ClickableItemMetadata(p.id, p.name!, "",p.description??"", <><Tag >{p.fromThingType}</Tag> <Icon name="chevron_right" /> <Tag >{p.toThingType}</Tag></>)
                        }
                        onSelect={() =>navigate(`view/${p.id}`)}
                        />
                    )}
                    </div>
                ) : (
                    "no elements"
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(RelationTypes);
