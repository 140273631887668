export interface Connector {
  name: string;
  displayName: string;
  description?: string,
  connectionParameters: ConnectionParameterInfo[];
  actions: IActionInfo[];
}

export interface ConnectionParameterInfo {
  name: string;
  displayName: string;
  description?: string,
  type: ConnectionParameterTypes;
  format?: string;
  isRequired: boolean;
  defaultValue?: any;
}

export type ConnectionParameterTypes = "String" | "Number" | "Boolean" | "Date" | "Json"

interface IActionInfo {
  name: string;
  displayName: string;
  description?: string,
  inputs: IActionInputInfo[];
}

export interface IActionInputInfo {
  name: string;
  displayName: string;
  description?: string,
  type: ConnectionParameterTypes;
  format?: string;
  isRequired: boolean;
  defaultValue?: any;
}


export class ConnectorParameterDto {
  name: string;
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}

export class DataProviderConnectorDto {
  connectorName: string;
  parameterValues?: ConnectorParameterDto[];

  constructor(connectorName: string, parameterValues?: ConnectorParameterDto[]) {
    this.connectorName = connectorName;
    this.parameterValues = parameterValues;
  }
}
