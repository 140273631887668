import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const BreadcrumbsContainer = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 16px;
    .bread-link{
        color: #1890ff;;
    }
    .bread-item{
        color:black;
    }
    .action-bar{
        display: flex;
        gap: 10px;
        align-items: center;
    }
`