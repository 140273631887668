import React, { memo } from "react";
import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { MainContainer } from "./create-profile.styles";
import Steps from "@plasma/ui.navigation.steps";
import { useTranslation } from "react-i18next";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";
import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";
import { Icon } from "@plasma/ui.general.icon";
import useCreateProfileHook from "./create-profile.hook";

function CreateProfile() {

  const { steps, currentStep, closeHandler, prevStepHandler, setCurrentStep, isSaving, actionType } = useCreateProfileHook();

  const { t } = useTranslation();

  return (
    <ThemeProvider>
      <MainContainer>
        {actionType !== "view" ?
          <div className="step-container">
            <Steps direction="vertical" current={currentStep}>
              {steps.map((step, ii) => (
                <Steps.Step
                  key={ii.toString()}
                  title={t(step.title)}
                />
              ))}
            </Steps>
          </div>
          : null}
        <div className="create-container">
          <div className="create-content">
            <Tabs
              onTabClick={(x) => { if (actionType === "view") setCurrentStep(parseInt(x)) }}
              className="tabs"
              tabBarStyle={{ display: actionType !== "view" ? "none" : "unset" }}
              activeKey={currentStep.toString()}
            >
              {steps.map((s, ii) => (
                <Tabs.TabPane tab={
                  <CustomTabPane
                    icon={<Icon name={s.icon} variant="filled" />}
                    title={t(s.title)}
                  />
                } 
                key={ii.toString()}
                >
                  {s.element}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
          <div className="create-toolbar">
            <ConfirmModal
              okButtonType="danger"
              onOk={closeHandler}
              content={t("confirm.creation.exit.description")}
              title={t("confirm.creation.exit.title")}
              okText={t("button.exit")}
              confirm={true}
              cancelText={t("button.stay")}
            >
              <Button type="text" title={t(actionType !== "view" ? "button.cancel" : "button.close")} />
            </ConfirmModal>
            {actionType !== "view" ? <div className="button-container">
              {currentStep > 0 ? (
                <Button
                  type="secondary"
                  onClick={prevStepHandler}
                  title={t("button.previous")}
                />
              ) : null}
              {(currentStep < steps.length - 1) ? (
                <Button htmlType="submit" form={`submit-outside${currentStep}`} title={t("button.next")} />
              ) : <Button loading={isSaving} htmlType="submit" form={`submit-outside${currentStep}`} title={t("button.save")} />}
            </div> : null}
          </div>
        </div>
      </MainContainer>
    </ThemeProvider>
  );
}

export default memo(CreateProfile) as unknown as typeof CreateProfile;