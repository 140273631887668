import React from "react";
import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import themes from "../../../themes";
import { MainContainer } from "./configuration-selection.styles";
import { ClickableItemMetadata } from "../../../components/shared/ClickableItem/ClickableItem.types";
import ClickableItemCard from "../../../components/shared/ClickableItem/ClickableItem";
import useConfigurationHook from "./configuration-selection.hook";

import TitlePage from "../../../components/shared/TitlePage";

function Configuration(props: Readonly<ThemeOverride>) {
  const { size, themeMode } = props;
  const logic = useConfigurationHook();
  const { t } = useTranslation();

  const configItems = [
    new ClickableItemMetadata(
      1,
      t("thing-types.title"),
      "/configuration/thing-types",
      t("thing-types.description")
    ),
    new ClickableItemMetadata(
      2,
      t("profiles.title"),
      "/configuration/profiles",
      t("profiles.description")
    ),
    new ClickableItemMetadata(
      3,
      t("relation-types.title"),
      "/configuration/relation-types",
      t("relation-types.description")
    ),
    new ClickableItemMetadata(
      4,
      t("data-providers.title"),
      "/configuration/data-providers",
      t("data-providers.description")
    ),
  ];

  return (
    <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
      <MainContainer>
        <div className="item-container">
          {configItems.map((item) => (
            <ClickableItemCard
              key={item.id}
              item={item}
              onSelect={() => logic.handleNavigate(item.path)}
            />
          ))}
        </div>
      </MainContainer>
    </ThemeProvider>
  );
}

export default observer(Configuration);
