import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const CreateDataProvider = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  .step-container {
    height: 100%;
    padding: 15px;
    padding-right: 75px;
    border-right: 1px solid lightgray;
  }
  .create-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .create-content {
      flex-grow: 1;
      width: 100%;
      overflow: auto;
      .tabs {
        height: 100%;
        width: 100%;
      }
    }
    .create-toolbar {
      border-top: 1px solid lightgray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px;
      .button-container {
        display: flex;
        gap: 16px;
      }
    }
  }
`;
