export class IdentifierRequirementBase {
    systemId: number;
    thingTypeId: number;
    name: string;
    description?: string;
    editable: boolean;
    mandatory: boolean;
   

    constructor(
        systemId: number,
        thingTypeId: number,
        name: string,
        editable: boolean,
        mandatory: boolean,
        description?: string
    ) {
        this.systemId=systemId
        this.thingTypeId=thingTypeId
        this.name=name
        this.description=description
        this.editable=editable
        this.mandatory=mandatory
    };
}

export class IdentifierRequirementDto extends IdentifierRequirementBase{
    id:number;

    constructor(
        id:number,
        systemId: number,
        thingTypeId: number,
        name: string,
        editable: boolean,
        mandatory: boolean,
        description?: string
    ){
        super(systemId, thingTypeId, name, editable, mandatory, description)
        this.id = id
    }
}