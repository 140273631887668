import React, { useEffect, useMemo, useState } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { Trans, useTranslation } from "react-i18next";
import Button from "@plasma/ui.general.button";
import Select from "@plasma/ui.input.select";
import { Icon } from "@plasma/ui.general.icon";
import styled from "styled-components";
import Popconfirm from "@plasma/ui.feedback.pop-confirm";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";

import { RelationTypeDto } from "../../../../models/dtos/relation-type/RelationTypeDtos";
import { queryClient } from "../../../../stores/QueryClient";
import {
  RelationTypeConstraintCompleteDto,
  RelationTypeConstraintDto,
} from "../../../../models/dtos/relation-type/RelationTypeConstraintDtos";
import Switch from "@plasma/ui.input.switch";

export default function RelationTypeConstraintsForm({
  onChange,
  index,
  onSubmit,
  viewOnly,
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const cached = queryClient.getQueryData<{
    relationType: RelationTypeDto;
    constraints: RelationTypeConstraintCompleteDto[];
  }>(["selectedRelationType"]);

  const [isConstrained, setIsConstrained] = useState(false);
  const methods = useForm()
  const changeHandler = (e:boolean) => {
    setIsConstrained(e);
  };
  useEffect(() => {
    const constrained = cached?.relationType.isConstrained || false;
    if (constrained) methods.setValue("constrained", constrained);
    setIsConstrained(constrained);
    if (cached?.constraints)
      methods.setValue("constraints", cached?.constraints);
  }, []);
  
  return (
    <div className="form-container">
      <Form
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={(x) => {
          onChange(x);
        }}
        methods={methods}
      >
        <FormField
          name={"constrained"}
          valuePropName={"checked"}
          label={t("relation-type.property.constrained")}
        >
          <Switch onClick={x => changeHandler(x)} disabled={viewOnly} />
        </FormField>
        {isConstrained ? (
          <FormField name={"constraints"} required>
            <ConstraintConfigurator disabled={viewOnly} />
          </FormField>
        ) : null}
      </Form>
    </div>
  );
}

const ConstraintConfigurator = (constraints: any) => {
  const { t } = useTranslation();

  const { data: availableProfiles } = useQuery(["availableProfiles"], () =>
    services.profiles.getAll().then((r) => {
      return r.data.map((p) => ({ value: p.id, label: p.name }));
    })
  );

  const changeHandler = (index: number, key: string, value: string) => {
    if (constraints.value) {
      const newValue = [...constraints.value];
      newValue[index][key] = value;
      constraints.onChange(newValue);
    }
  };

  const addHandler = () => {
    if (constraints.value) {
      const newValue = [...constraints.value];
      newValue.push({
        fromProfileId: undefined,
        toProfileId: undefined,
      });
      constraints.onChange(newValue);
    } else {
      constraints.onChange([
        { fromProfileId: undefined, toProfileId: undefined },
      ]);
    }
  };
  const deleteHandler = (index: number) => {
    if (constraints.value) {
      const newValue = [...constraints.value];
      newValue.splice(index, 1);
      constraints.onChange(newValue);
    }
  };

  return (
    <>
      <ConstraintGrid>
        <div className="row header">
          <span className="cell">
            {t("relation-type.constraint.property.fromProfile")}
          </span>
          <span className="cell">
            {t("relation-type.constraint.property.toProfile")}
          </span>
          <span className="cell">
            <div
              style={{ height: 0, overflow: "hidden" }}
              className="cell toolbar"
            >
              {!constraints.disabled ? (
                <Button
                  style={{ border: 0 }}
                  icon={<Icon name="delete" />}
                  type="tertiary"
                />
              ) : null}
            </div>
          </span>
        </div>
        {constraints?.value?.map((p: RelationTypeConstraintDto, ii: any) => (
          <div key={ii} className="row">
            <div className="cell">
              <Select
                options={availableProfiles}
                onChange={(v) => changeHandler(ii, "fromProfileId", v)}
                value={p.fromProfileId}
                disabled={constraints.disabled}
              />
            </div>
            <div className="cell">
              <Select
                options={availableProfiles}
                onChange={(v) => changeHandler(ii, "toProfileId", v)}
                value={p.toProfileId}
                disabled={constraints.disabled}
              />
            </div>
            <div className="cell toolbar">
              {!constraints.disabled ? (
                <Popconfirm
                  okButtonProps={{ type: "tertiary" }}
                  cancelButtonProps={{ type: "tertiary" }}
                  icon={<Icon name="delete" />}
                  onConfirm={() => deleteHandler(ii)}
                  title={
                    <Trans
                      i18nKey={"delete-modalconfirm-message.short"}
                      values={{ name: "this" }}
                    />
                  }
                >
                  <Button
                    style={{ border: 0 }}
                    icon={<Icon name="delete" />}
                    type="tertiary"
                  />
                </Popconfirm>
              ) : null}
            </div>
          </div>
        ))}
      </ConstraintGrid>
      {!constraints.disabled ? (
        <div>
          <Button
            style={{ marginLeft: 2 }}
            onClick={addHandler}
            icon={<Icon name="add" />}
            type="tertiary"
            title={t("button.add")}
          />
        </div>
      ) : null}
    </>
  );
};

const ConstraintGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  border-collapse: collapse;
  margin-bottom: 16px;
  .icon-button {
    border: 0px;
  }
  .row {
    display: contents;
  }
  .cell {
    padding-top: 16px;
    padding-right: 16px;
    &.toolbar {
      display: flex;
      padding-right: 0px;
      padding-right: 2px;
    }
  }
  .header .cell {
    padding-top: 0px;
  }
`;
