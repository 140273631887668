import { makeAutoObservable } from "mobx";

import { SystemCompleteDto, SystemDto } from "../models/dtos/system/SystemDtos";
import services from "../api/agent";
import { ThingTypeCompleteDto } from "../models/dtos/thing-type/ThingTypeDtos";
import { RelationTypeCompleteDto } from "../models/dtos/relation-type/RelationTypeDtos";
import { FeatureCompleteDto } from "../models/dtos/FeatureDtos";
import { ProfilePartDto } from "../models/dtos/profile/ProfileComposition";

export default class SystemStore {
  systems: SystemCompleteDto[] = [];
  systemsAreLoading: boolean = false;

  chosenSystemIsLoading: boolean = false;
  chosenSystem: SystemCompleteDto | null = null;

  associatedThingTypes: ThingTypeCompleteDto[] = [];
  associatedThingTypesAreLoading: boolean = false;

  associatedRelationTypes: RelationTypeCompleteDto[] = [];
  associatedRelationTypesAreLoading: boolean = false;

  associatedProfiles: ProfilePartDto[] = [];
  associatedProfilesAreLoading: boolean = false;

  associatedFeatures: FeatureCompleteDto[] = [];
  associatedFeaturesAreLoading: boolean = false;

  // Systems
  fetchSystems = async () => {
    this.systemsAreLoading = true;
    
    await services.systems.getAll()
    .then((response) => {
        this.systems = response.data;
        this.systemsAreLoading = false;
    });
  };

  reloadSystems = async () => {
    this.systems = [];
    await this.fetchSystems();
  };

  createSystem = async (system: SystemDto) => {
    await services.systems.create(system).then((response) => {
      this.systems.push(response.data);
    });
  };

  deleteSystem = async (id: number) => {
    await services.systems.delete(id).then(() => {
      const indexToRemove = this.systems.findIndex((system) => system.id === id);
      this.systems.splice(indexToRemove, 1);
    });
  };

  // Chosen system
  fetchChosenSystem = async (id: number) => {
    this.chosenSystemIsLoading = true;
    await services.systems.getById(id)
        .then((respone) => {this.chosenSystem=respone.data; this.chosenSystemIsLoading=false;});
  };

  updateSystem = async (system: SystemCompleteDto) => {
    const { id, ...updatedSystem } = system;
    await services.systems.update(id, updatedSystem).then((respone) => {
      const responseData = respone.data;
      this.chosenSystem = responseData;
      this.systems.map((system) => {
        if (system.id === responseData.id) {
          Object.assign(system, responseData);
        }
      });
    });
  };

  reloadChosenSystem = async (id: number) => {
    this.chosenSystem = null;
    await this.fetchChosenSystem(id);
  };

  // Associated system thing types
  fetchAssociatedThingTypes = async (id: number) => {
    this.associatedThingTypesAreLoading = true;

    await services.systems.getThingTypes(id)
    .then((respone) => {
      this.associatedThingTypes=respone.data; 
      this.associatedThingTypesAreLoading=false;
    });
  };

  reloadAssociatedThingTypes = async (id: number) => {
    this.associatedThingTypes = [];
    await this.fetchAssociatedThingTypes(id);
  };

  // Associated system relation types
  fetchAssociatedRelationTypes = async (id: number) => {
    this.associatedRelationTypesAreLoading = true;

    await services.systems.getRelationTypes(id)
    .then((respone) => {
      this.associatedRelationTypes=respone.data; 
      this.associatedRelationTypesAreLoading=false;
    });
  };

  reloadAssociatedRelationTypes = async (id: number) => {
    this.associatedRelationTypes = [];
    await this.fetchAssociatedRelationTypes(id);
  };

  // Associated system profiles
  fetchAssociatedProfiles = async (id: number) => {
    this.associatedProfilesAreLoading = true;

    await services.systems.getProfileCompositions(id)
    .then((respone) => {
      this.associatedProfiles=respone.data; 
      this.associatedProfilesAreLoading=false;
    });
  };

  reloadAssociatedProfiles = async (id: number) => {
    this.associatedProfiles = [];
    await this.fetchAssociatedProfiles(id);
  };

  // Associated system features
  fetchAssociatedFeatures = async (id: number) => {
    this.associatedFeaturesAreLoading = true;

    await services.systems.getFeatures(id)
    .then((respone) => {
      this.associatedFeatures=respone.data; 
      this.associatedFeaturesAreLoading=false;
    });
  };

  reloadAssociatedFeatures = async (id: number) => {
    this.associatedFeatures = [];
    await this.fetchAssociatedFeatures(id);
  };

  constructor() {
    makeAutoObservable(this);
  }
}