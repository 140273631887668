export class ThingTypeDto {
    name: string;
    description?: string;

    constructor(name: string, description?: string) {
        this.name = name;
        this.description = description;
    }
}

export class ThingTypeCompleteDto extends ThingTypeDto {
    id: number;

    constructor(id: number,name: string, description?: string) {
        super(name, description);
        this.id = id;
    }
}