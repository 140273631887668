import React from "react";

import { Route } from "@plasma/ui.application.router";
import { SystemSelectionRoutes } from "./system-selection/systems.routes";
import { CreateSystemRoutes } from "./create-system";

const Systems = React.lazy(() => import("./system-selection"));

export const SystemRoutes = [
  new Route({
    path: "systems",
    handle: {
      crumb: (_params: any) => {
        return ["systems.title"];
      },
    },
    children:[
      ...SystemSelectionRoutes,
      ...CreateSystemRoutes
    ]
  }),
];
