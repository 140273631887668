import React, { useEffect, useState } from "react";
import services from "../../../api/agent";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import {
  useLoaderData,
  useNavigate,
  useParams,
} from "@plasma/ui.application.router";
import { queryClient } from "../../../stores/QueryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStore } from "../../../stores/store";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../components/shared/ConfirmModal/ConfirmModal";
import { SystemDto } from "../../../models/dtos/system/SystemDtos";
import { SystemAssociatedFeatureDto } from "../../../models/dtos/system/SystemAssociatedFeatureDto";
import SystemGeneralForm from "./SystemGeneralForm";
import SystemFeaturesForm from "./SystemFeaturesForm";
import SystemThingTypesForm from "./SystemThingTypesForm";
import { SystemAssociatedThingTypeDto } from "../../../models/dtos/system/SystemAssociatedThingTypeDto";
import { SystemAssociatedProfileDto } from "../../../models/dtos/system/SystemAssociatedProfileDto";
import SystemProfileCompositionForm from "./SystemProfileCompositionForm";
import SystemRelationTypesForm from "./SystemRelationTypesForm";
import SystemDataProvidersForm from "./SystemDataProvidersForm";
import { SystemAssociatedRelationTypeDto } from "../../../models/dtos/system/SystemAssociatedRelationTypeDto";
import { SystemAssociatedDataProviderDto } from "../../../models/dtos/system/SystemAssociatedDataProviderDto";
import SystemIdentificationForm from "./SystemIdentificationForm";
import { IdentifierRequirementBase, IdentifierRequirementDto } from "../../../models/dtos/system/IdentifierRequirementDto";
import _ from "lodash";
import { AxiosError } from "axios";

function useCreateSystemHook() {
  const loaderData = useLoaderData() as { type: "create" | "edit" | "view" };
  const actionType = loaderData.type;
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const deleteHandler = async (name:string|undefined) => {
    if (id) await services.systems.delete(parseInt(id));
    return name;
  };

  const { mutate: deleteSystem, isLoading: isDeleting } = useMutation({
    mutationFn: deleteHandler,
    onSuccess: (name) => {
      notificationant.success({
        message: t("notification.success.title"),
        description: (
          <Trans
            i18nKey={"notification.delete.success.description"}
            values={{
              itemType: t("systems.title.single"),
              itemName: name,
            }}
          />
        ),
      });
      closeHandler();
    },
    onError: (error) => {
      notificationant.error({
        message: t("notification.delete.error.title"),
        description: (
          <Trans
            i18nKey={"notification.delete.error.description"}
            values={{ itemType: t("systems.title.single"), itemName: "" }}
          />
        ),
      });
    },
  });
  const { data: selectedSystem } = useQuery(
    ["selectedSystem"],
    () =>
      Promise.all([
        services.systems.getById(parseInt(id!)),
        services.systems.getThingTypes(parseInt(id!)),
        services.systems.getFeatures(parseInt(id!)),
        services.systems.getProfileCompositions(parseInt(id!)),
        services.systems.getRelationTypes(parseInt(id!)),
        services.systems.getDataProviders(parseInt(id!)),
        services.systems.getIdentifiers(parseInt(id!))
      ]).then((r) => {
        setThingTypesData(r[1].data.map(e => e.id))
        return {
          system: r[0].data,
          associatedThingTypes: r[1].data,
          associatedFeatures: r[2].data,
          associatedProfileCompositions: r[3].data,
          associatedRelationTypes: r[4].data,
          associatedDataProviders: r[5].data,
          associatedIdentifiers: r[6].data,
        };
      }),
    { enabled: !!id, refetchOnWindowFocus: false }
  );
  const { ActionStore } = useStore();

  useEffect(() => {
    if (actionType === "edit" || actionType === "view") {
      ActionStore.setActions(
        <>
          {actionType === "view" ? (
            <Button
              title="Edit"
              onClick={() => navigate(`/systems/edit/${id}`)}
            />
          ) : null}
          <ConfirmModal
            valueToValidate={selectedSystem?.system.name}
            okButtonType="danger"
            content={
              <Trans
                i18nKey={"confirm.delete.description"}
                values={{ itemName: selectedSystem?.system.name }}
              />
            }
            title={t("confirm.delete.title")}
            okText={t("button.delete")}
            cancelText={t("button.cancel")}
            confirm={true}
            onOk={() => deleteSystem(selectedSystem?.system.name)}
          >
            <Button
              loading={isDeleting}
              type="secondary"
              title={t("button.delete")}
              icon={<Icon name="delete_outline" />}
            />
          </ConfirmModal>
        </>
      );
    }
  }, [i18n.language, isDeleting, selectedSystem, actionType]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(["selectedSystem"]);
      ActionStore.clearNodes();
    };
  }, []);

  const [currentStep, setCurrentStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [generalData, setGeneralData] = useState<{
    name: string;
    description?: string;
  }>();
  const [thingTypesData, setThingTypesData] = useState<number[]>();
  const [featuresData, setFeaturesData] = useState<number[]>();
  const [relationTypesData, setRelationTypesData] = useState<number[]>();
  const [dataProvidersData, setDataProvidersData] = useState<number[]>();
  const [compositionsData, setCompositionsData] = useState<string[]>();
  const [identifiersData, setIdentifiersData] = useState<{
    [key: string]: IdentifierRequirementDto[];
  }>({});
  const navigate = useNavigate();
  const nextStepHandler = () => {
    setCurrentStep(currentStep + 1);
  };

  const saveHandler = async () => {
    
    if (actionType === "create") {
      if (
        generalData
      ) {
        let systemId: number;
        try {
          setIsSaving(true);
  
          // General
          const systemBase = new SystemDto(
            generalData.name,
            generalData.description
          );
  
          
          try {
            systemId = (await services.systems.create(systemBase)).data.id;
          } 
          catch (e:any){
            const errorMessage = t("notification.create.error.description", {
                itemType: t("systems.title.single"),
                itemName: "",
                error: e instanceof AxiosError ? e.response?.data : ""
            });

            notificationant.error({
                message: t("notification.create.error.title"),
                description: (
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                ),
            });
            return;
          }
  
          // ThingTypes
          if (thingTypesData) {
            thingTypesData.forEach((thingTypeId) => {
              try {
                services.systems.addThingType(
                  systemId,
                  new SystemAssociatedThingTypeDto(thingTypeId)
                );
              } 
              catch (e:any){
                const errorMessage = t("notification.create.error.description", {
                    itemType: t("thing-types.associated"),
                    itemName: "",
                    error: e instanceof AxiosError ? e.response?.data : ""
                });
    
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    ),
                });
                throw e;
              }
            });
          }          
  
          // Features  
          if (featuresData) {
            featuresData.forEach((featureId) => {
              try {
                services.systems.addFeature(
                  systemId,
                  new SystemAssociatedFeatureDto(featureId)
                );
              } 
              catch (e:any){
                const errorMessage = t("notification.create.error.description", {
                    itemType: t("features.associated"),
                    itemName: "",
                    error: e instanceof AxiosError ? e.response?.data : ""
                });
    
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    ),
                });
                throw e;
              }
            });            
          }

          // ProfileCompositions
          if (compositionsData) {
            try {
              await services.systems.addProfileCompositions(
                systemId,
                compositionsData.map(
                  (item) => new SystemAssociatedProfileDto(parseInt(item))
                )
              );
            }
            catch (e:any){
              const errorMessage = t("notification.create.error.description", {
                  itemType: t("profiles.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.create.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          }
  
          // RelationTypes  
          if (relationTypesData) {
            relationTypesData.forEach((relationTypeId) => {
              try {
                services.systems.addRelationType(
                  systemId,
                  new SystemAssociatedRelationTypeDto(relationTypeId)
                );
              } 
              catch (e:any){
                const errorMessage = t("notification.create.error.description", {
                    itemType: t("relation-types.associated"),
                    itemName: "",
                    error: e instanceof AxiosError ? e.response?.data : ""
                });

                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    ),
                });
                throw e;
              }
            });
          }
           
          // Data Providers  
          if (dataProvidersData) {
            dataProvidersData.forEach((dataProviderId) => {
              try {
                services.systems.addDataProvider(
                  systemId,
                  new SystemAssociatedDataProviderDto(dataProviderId)
                );
              } 
              catch (e:any){
                const errorMessage = t("notification.create.error.description", {
                    itemType: t("data-providers.associated"),
                    itemName: "",
                    error: e instanceof AxiosError ? e.response?.data : ""
                });
    
                notificationant.error({
                    message: t("notification.create.error.title"),
                    description: (
                        <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    ),
                });
                throw e;
              }
            });
          }

          // Identifiers
          if (identifiersData) {
            for (const key in identifiersData) {
              if (Object.prototype.hasOwnProperty.call(identifiersData, key)) {
                const identifiers = identifiersData[key];              
                const identifiersToCreate = identifiers
                .filter(associated => !selectedSystem?.associatedIdentifiers.some(e =>(e.id === associated.id)));
    
                identifiersToCreate.forEach(async (identifier)=>{
                  const identifierBase = new IdentifierRequirementBase(
                    systemId,
                    parseInt(key),
                    identifier.name,
                    identifier.editable,
                    identifier.mandatory,
                    identifier.description
                  );
                  try {
                    await services.systems.addIdentifier(
                      systemId,
                      identifierBase
                    );
                  } 
                  catch (e:any){
                    const errorMessage = t("notification.create.error.description", {
                        itemType: t("system.identifier"),
                        itemName: "",
                        error: e instanceof AxiosError ? e.response?.data : ""
                    });
        
                    notificationant.error({
                        message: t("notification.create.error.title"),
                        description: (
                            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        ),
                    });
                    throw e;
                  }
                })
                
              }
            }
          }
  
          notificationant.success({
            message: t("notification.success.title"),
            description: (
              <Trans
                i18nKey={"notification.create.success.description"}
                values={{
                  itemType: t("systems.title"),
                  itemName: generalData.name,
                }}
              />
            ),
          });
          navigate("/systems");
        } catch (e) {
          try {
            await services.systems.delete(systemId!);
          } catch {}
        } finally {
          setIsSaving(false);
        }
      }
    }
    else{
      
      let systemId: number = selectedSystem!.system.id;
      try {
        setIsSaving(true);

        if (generalData) {
          // General
          const systemBase = new SystemDto(
            generalData.name,
            generalData.description
          );

          try {
            await services.systems.update(systemId, systemBase);
          }
          catch (e:any){
            const errorMessage = t("notification.update.error.description", {
                itemType: t("systems.title.single"),
                itemName: "",
                error: e instanceof AxiosError ? e.response?.data : ""
            });

            notificationant.error({
                message: t("notification.update.error.title"),
                description: (
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                ),
            });
            throw e;
          }  
        }
        
        if (thingTypesData)
        {
          // ThingTypes
          const thingTypesNotInThingTypesData = selectedSystem?.associatedThingTypes
          .map((e) => e.id)
          .filter((e) => !thingTypesData.includes(e));
          thingTypesNotInThingTypesData?.forEach((thingTypeId) => {
            try {
              services.systems.removeThingType(systemId, thingTypeId);
            }
            catch (e:any){
              const errorMessage = t("notification.delete.error.description", {
                  itemType: t("thing-types.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.delete.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });

          thingTypesData.forEach((thingTypeId) => {
            try {
              if (
                selectedSystem?.associatedThingTypes
                  .map((e) => e.id)
                  .includes(thingTypeId)
              ) {
                return;
              }
              services.systems.addThingType(
                systemId,
                new SystemAssociatedThingTypeDto(thingTypeId)
              );
            }
            catch (e:any){
              const errorMessage = t("notification.create.error.description", {
                  itemType: t("thing-types.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.create.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });
        }
        
        if (featuresData) {
          // Features
          const featuresNotInFeatureData = selectedSystem?.associatedFeatures
          .map((e) => e.id)
          .filter((e) => !featuresData.includes(e));
          featuresNotInFeatureData?.forEach((featureId) => {
            try {
              services.systems.removeFeature(systemId, featureId);
            }
            catch (e:any){
              const errorMessage = t("notification.delete.error.description", {
                  itemType: t("features.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.delete.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });

          featuresData.forEach((featureId) => {
            try {
              if (
                selectedSystem?.associatedFeatures
                  .map((e) => e.id)
                  .includes(featureId)
              ) {
                return;
              }
              services.systems.addFeature(
                systemId,
                new SystemAssociatedFeatureDto(featureId)
              );
            }
            catch (e:any){
              const errorMessage = t("notification.create.error.description", {
                  itemType: t("features.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.create.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });
        }
        
        if (compositionsData) {
          // ProfileCompositions
          try {
            await services.systems.addProfileCompositions(
              systemId,
              compositionsData.map(
                (item) => new SystemAssociatedProfileDto(parseInt(item))
              )
            );
          }
          catch (e:any){
            const errorMessage = t("notification.create.error.description", {
                itemType: t("profiles.associated"),
                itemName: "",
                error: e instanceof AxiosError ? e.response?.data : ""
            });

            notificationant.error({
                message: t("notification.create.error.title"),
                description: (
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                ),
            });
            throw e;
          }
        }
        
        if (relationTypesData) {
          // RelationTypes
          const relationTypesToDelete = selectedSystem?.associatedRelationTypes
          .map((e) => e.id)
          .filter((e) => !relationTypesData.includes(e));
          relationTypesToDelete?.forEach((relationTypeId) => {
            try {
              services.systems.removeRelationType(systemId, relationTypeId);
            }
            catch (e:any){
              const errorMessage = t("notification.delete.error.description", {
                  itemType: t("relation-types.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.delete.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });

          const relationTypesToCreate = relationTypesData.filter((relationTypeId) => !selectedSystem?.associatedRelationTypes.map((e) => e.id).includes(relationTypeId));
          relationTypesToCreate.forEach((relationTypeId) => {
            try {
              services.systems.addRelationType(
                systemId,
                new SystemAssociatedRelationTypeDto(relationTypeId)
              );
            }
            catch (e:any){
              const errorMessage = t("notification.create.error.description", {
                  itemType: t("relation-types.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.create.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });
        }
        
        if (dataProvidersData) {
          // Data Providers
          const dataProvidersNotInDataProvidersData = selectedSystem?.associatedDataProviders
          .map((e) => e.id)
          .filter((e) => !dataProvidersData.includes(e));
          dataProvidersNotInDataProvidersData?.forEach((dataProviderId) => {
            try {
              services.systems.removeDataProvider(systemId, dataProviderId);
            }
            catch (e:any){
              const errorMessage = t("notification.delete.error.description", {
                  itemType: t("data-providers.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.delete.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });

          dataProvidersData.forEach((dataProviderId) => {
            try {
              if (
                selectedSystem?.associatedDataProviders
                  .map((e) => e.id)
                  .includes(dataProviderId)
              ) {
                return;
              }
              services.systems.addDataProvider(
                systemId,
                new SystemAssociatedDataProviderDto(dataProviderId)
              );
            }
            catch (e:any){
              const errorMessage = t("notification.create.error.description", {
                  itemType: t("data-providers.associated"),
                  itemName: "",
                  error: e instanceof AxiosError ? e.response?.data : ""
              });
  
              notificationant.error({
                  message: t("notification.create.error.title"),
                  description: (
                      <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  ),
              });
              throw e;
            }
          });
        }

        if (identifiersData) {
          // Identifiers  
          for (const key in identifiersData) {
            if (Object.prototype.hasOwnProperty.call(identifiersData, key)) {
              const identifiers = identifiersData[key];
  
              const identifiersToRemove = selectedSystem?.associatedIdentifiers
              .filter(associated => associated.thingTypeId === parseInt(key) && !identifiers.some(e =>e.id === associated.id))??[];
              
              const identifiersToUpdate = identifiers
              .filter(associated => selectedSystem?.associatedIdentifiers.some(e =>(e.id === associated.id  && e.thingTypeId===parseInt(key) && !_.isEqual(associated,e))));
              
              const identifiersToCreate = identifiers
              .filter(associated => !selectedSystem?.associatedIdentifiers.some(e =>(e.id === associated.id  && e.thingTypeId===parseInt(key))));

              const removePromise = identifiersToRemove.map(async (identifier)=>{
                try {
                  await services.systems.removeIdentifier(
                    systemId,
                    identifier.id
                  );
                }
                catch (e:any){
                  const errorMessage = t("notification.delete.error.description", {
                      itemType: t("system.identifier"),
                      itemName: "",
                      error: e instanceof AxiosError ? e.response?.data : ""
                  });
      
                  notificationant.error({
                      message: t("notification.delete.error.title"),
                      description: (
                          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                      ),
                  });
                  throw e;
                }
              })   
  
              const updatePromises = identifiersToUpdate.map(async (identifier)=>{
                const identifierBase = new IdentifierRequirementBase(
                  systemId,
                  parseInt(key),
                  identifier.name,
                  identifier.editable,
                  identifier.mandatory,
                  identifier.description
                );
                try {
                  await services.systems.updateIdentifierById(
                    systemId,
                    identifier.id,
                    identifierBase
                  );
                }
                catch (e:any){
                  const errorMessage = t("notification.update.error.description", {
                      itemType: t("system.identifier"),
                      itemName: "",
                      error: e instanceof AxiosError ? e.response?.data : ""
                  });
      
                  notificationant.error({
                      message: t("notification.update.error.title"),
                      description: (
                          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                      ),
                  });
                  throw e;
                }
              })   
  
              const createPromises = identifiersToCreate.map(async (identifier)=>{
                const identifierBase = new IdentifierRequirementBase(
                  systemId,
                  parseInt(key),
                  identifier.name,
                  identifier.editable,
                  identifier.mandatory,
                  identifier.description
                );
                try {
                    await services.systems.addIdentifier(
                    systemId,
                    identifierBase
                    );
                  }
                  catch (e:any){
                    const errorMessage = t("notification.create.error.description", {
                        itemType: t("system.identifier"),
                        itemName: "",
                        error: e instanceof AxiosError ? e.response?.data : null
                      });
                    
                    notificationant.error({
                        message: t("notification.create.error.title"),
                        description: (
                            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        ),
                    });
                    throw e;
                }
              })
              
              await Promise.all([...createPromises,...updatePromises,...removePromise]);
            }
          } 
        }

        notificationant.success({
          message: t("notification.success.title"),
          description: (
            <Trans
              i18nKey={"notification.update.success.description"}
              values={{
                itemType: t("systems.title.single"),
                itemName: generalData?generalData.name:selectedSystem?.system.name,
              }}
            />
          ),
        });
        navigate("/systems");
      } catch {
      } finally {
        setIsSaving(false);
      }
  }
  };

  const steps = [
    {
      title: "systems.step.general.title",
      icon: "sell",
      description: "systems.step.general.description",
      element: (
        <SystemGeneralForm
          onSubmit={nextStepHandler}
          index={0}
          onChange={setGeneralData}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
      title: "systems.step.thing-types.title",
      icon: "class",
      description: "systems.step.thing-types.description",
      element: (
        <SystemThingTypesForm
          index={1}
          key={1}
          onSubmit={nextStepHandler}
          onChange={(x) => setThingTypesData(x["thing-types"]??[])}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
      title: "systems.step.features.title",
      icon: "category",
      description: "systems.step.features.description",
      element: (
        <SystemFeaturesForm
          index={2}
          key={2}
          onSubmit={nextStepHandler}
          onChange={(x) => setFeaturesData(x["features"]??[])}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
      title: "systems.step.profiles.title",
      icon: "inventory_2",
      description: "systems.step.profiles.description",
      element: (
        <SystemProfileCompositionForm
          index={3}
          key={3}
          onSubmit={nextStepHandler}
          onChange={(x) => (setCompositionsData(x??[]))}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
      title: "systems.step.relation-types.title",
      icon: "link",
      description: "systems.step.relation-types.description",
      element: (
        <SystemRelationTypesForm
          index={4}
          key={4}
          onSubmit={nextStepHandler}
          onChange={(x) => setRelationTypesData(x["relation-types"]??[])}
          viewOnly={actionType === "view"}
        />
      ),
    },
    {
        title: "systems.step.data-providers.title",
        icon: "contact_page",
        description: "systems.step.data-providers.description",
        element: (
          <SystemDataProvidersForm
          index={5}
          key={5}
          onSubmit={nextStepHandler}
          onChange={(x) => setDataProvidersData(x["data-providers"]??[])}
          viewOnly={actionType === "view"}
          />
        ),
    },
    {
        title: "systems.step.identifications.title",
        icon: "key",
        description: "systems.step.identifications.description",
        element: (
          <SystemIdentificationForm
          index={6}
          key={6}
          onSubmit={saveHandler}
          onChange={(x) => setIdentifiersData(x??[])}
          selectedThingTypeIds = {thingTypesData}
          viewOnly={actionType === "view"}
          />
        ),
    },
  ];

  const prevStepHandler = () => {
    setCurrentStep(currentStep - 1);
  };
  const closeHandler = () => {
    navigate("/systems");
  };

  return {
    currentStep,
    steps,
    nextStepHandler,
    closeHandler,
    prevStepHandler,
    setCurrentStep,
    isSaving,
    actionType,
  };
}

export default useCreateSystemHook;
