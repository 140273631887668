import baseStyled, { ThemedStyledInterface, } from 'styled-components';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const MainContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;

.item-container {
height: 100%;
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;  /* Align items within each row */
align-content: flex-start; /* Align rows at the top */
gap: 16px;
overflow: auto;
}
`;