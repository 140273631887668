import React from "react";
import { Route } from "@plasma/ui.application.router";
import services from "../../../../api/agent";

const CreateDataProvider = React.lazy(() => import("."));

export const CreateDataProviderRoutes = [
  new Route({
    path: "create",
    element: CreateDataProvider,
    children: [],
    loader: async ({ params }) => {
      return {type:"create"};
    },
    handle: {
      crumb: (_params: any) => {
        return ["data-providers.create.title"];
      },
    },
  }),

   
  
      new Route(  {
      path: "edit/:id",
      element: CreateDataProvider,
      children: [],
      loader: async ({ params }) => {
        const data = await services.dataProviders
          .getById(Number.parseInt(params.id!))
          .then((respone) => respone.data);
        return {name:data.name,type:"edit"};
      },
      handle: {
        crumb: (_params: any, data: any) => {
          return [data.name];
        },
      },
    }),
    new Route(  {
      path: "view/:id",
      element: CreateDataProvider,
      children: [],
      loader: async ({ params }) => {
        const data = await services.dataProviders
          .getById(Number.parseInt(params.id!))
          .then((respone) => respone.data);
        return {name:data.name,type:"view"};
      },
      handle: {
        crumb: (_params: any, data: any) => {
          return [data.name];
        },
      },
    })
  ]



