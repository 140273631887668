import { ExclamationCircleFilled } from "@ant-design/icons";
import { Icon } from "@plasma/ui.general.icon";
import React from "react";
import { ReactNode, useState } from "react";
import Modal from "@plasma/ui.layout.modal";
import TextInput from "@plasma/ui.input.text-input";
import { ButtonProps } from "antd";
interface Props {
  children?: ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  confirm?: boolean;
  title?: ReactNode;
  content?: ReactNode;
  icon?: ReactNode;
  okText?: string;
  okButtonType?:"text" | "danger" | "ghost" | "primary" | "secondary" | "tertiary" | "skeleton" | "warning" | "secondary-danger" | "secondary-warning" | "tertiary-danger" | undefined
  cancelButtonType?:"text" | "danger" | "ghost" | "primary" | "secondary" | "tertiary" | "skeleton" | "warning" | "secondary-danger" | "secondary-warning" | "tertiary-danger" | undefined
  cancelText?: string;
  valueToValidate?:string
}
export default function ConfirmModal(props: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [validationValue, setValidationValue] = useState<string>();
  const [isValid, setIsValid] = useState(true);
  const clickHandler = () => {
    if (props.confirm) setModalOpen(true);
    else if (props.onOk) props.onOk();
  };

  const validationHandler = () => {
    const valid = validationValue === props.valueToValidate;
    setIsValid(valid);
    return valid;
  };
  const okHandler = () => {
    if (props.valueToValidate && validationHandler()) {
      if (props.onOk) props.onOk();
      setModalOpen(false);
    } else if (!props.valueToValidate) {
      if (props.onOk) props.onOk();
      setModalOpen(false);
    }
  };

  const cancelhandler = () => {
    if (props.onCancel) props.onCancel();
    setModalOpen(false);
  };

  const inputHandler = (value: string) => {
    setIsValid(true);
    setValidationValue(value);
  };

  return (
    <>
      <div onClick={clickHandler}>{props.children}</div>

      <Modal
        destroyOnClose
        centered
        maskClosable={false}
        closable={true}
        visible={modalOpen}
        title={
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            {props.icon ?? <Icon name="warning" />}
            <div>{props.title ?? "Do you want to delete these items?"}</div>
          </div>
        }
        onOk={okHandler}
        onCancel={cancelhandler}
        okText={props.okText}
        okButtonProps={{ type: props.okButtonType }}
        cancelButtonProps={{ type: props.cancelButtonType }}
        cancelText={props.cancelText}
      >
        {props.content ?? "Some descriptions"}
       {props.valueToValidate? <TextInput
          status={!isValid ? "error" : undefined}
          style={{ marginTop: 16 }}
          value={validationValue}
          onChange={(e) => inputHandler(e.target.value)}
        />:null}
      </Modal>
    </>
  );
}
