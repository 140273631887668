import { useQuery } from "@tanstack/react-query";
import services from "../../../api/agent";
import { ProfileStatus } from "../../../models/enums/ProfileEnums";

const useThingTypesHook = () => {
    const { data: profileOverviewData,isLoading } = useQuery(["profiles"], () => Promise.all(
      [ 
        services.profiles.getAll(),
        services.thingTypes.getAll()]
    ).then((r) => {
      const profiles = r[0].data
      const thingTypes = r[1].data
      return profiles.map((item)=> {
        const thingTypeName = thingTypes?.find((e) => e.id == item.thingTypeId)?.name??"";
        return new ProfileOverviewData(item.id,item.name, thingTypeName, item.status, item.description)
      })
    })
  )

  return {
    profileOverviewData,
    isLoading
  };
}

export class ProfileOverviewData {
  id: number;
  name: string;
  thingType: string;
  status: ProfileStatus;
  description?: string;
  constructor(id:number, name: string, thingType: string, status: ProfileStatus, description?: string) {
    this.id = id;
    this.name = name;
    this.thingType = thingType;
    this.description = description;
    this.status = status       
  };
}

export default useThingTypesHook;