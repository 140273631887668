import React from 'react';
import { Route } from '@plasma/ui.application.router';


const Layout = React.lazy(() => import('.'));

export const LayoutRoutes = new Route({
  path: '',
  element: Layout,
});
