import baseStyled, { ThemedStyledInterface } from 'styled-components';

import Card from '@plasma/ui.display.card/dist/card';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const StyledCard = styled(Card)`
  height: 150px;
  width: 270px !important;
  margin: 0px !important;

  &:hover {
    background-color: #f0f0f0;
  }

  &:active {
    background-color: #e0e0e0;
    transform: scale(0.95);
  }

  .card-description{
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(var(--_plasma-size-card__anchor-line-height) * 3); /* Adjust the line height as needed */
}

.card-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
`;
