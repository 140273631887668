import { makeAutoObservable } from "mobx";

import { ThingTypeCompleteDto, ThingTypeDto } from "../models/dtos/thing-type/ThingTypeDtos";
import services from "../api/agent";
import { FeatureCompleteDto } from "../models/dtos/FeatureDtos";

export default class ThingTypeStore {
  thingTypes: ThingTypeCompleteDto[] = [];
  thingTypesAreLoading: boolean = false;

  chosenThingTypeIsLoading: boolean = false;
  chosenThingType: ThingTypeCompleteDto | null = null;

  associatedFeatures: FeatureCompleteDto[] = [];
  associatedFeaturesAreLoading: boolean = false;

  // ThingTypes
  fetchThingTypes = async () => {
    this.thingTypesAreLoading = true;
    
    await services.thingTypes.getAll()
    .then((response) => {
        this.thingTypes = response.data;
        this.thingTypesAreLoading = false;
    });
  };

  createThingType = async (thingTypeDto: ThingTypeDto) => {
    await services.thingTypes.create(thingTypeDto).then((response) => {
      this.thingTypes.push(response.data);
    });
  };

  deleteThingType = async (id: number) => {
    await services.thingTypes.delete(id).then(() => {
      const indexToRemove = this.thingTypes.findIndex((c) => c.id === id);
      this.thingTypes.splice(indexToRemove, 1);
    });
  };

  reloadThingTypes = async () => {
    this.thingTypes = [];
    await this.fetchThingTypes();
  };

  // Chosen thingType
  fetchChosenThingType = async (id: number) => {
    this.chosenThingTypeIsLoading = true;
    await services.thingTypes.getById(id)
        .then((respone) => {this.chosenThingType=respone.data; this.chosenThingTypeIsLoading=false;});
  }

  updateThingType = async (thingTypeDto: ThingTypeCompleteDto) => {
    const { id, ...updatedThingType } = thingTypeDto;
    await services.thingTypes.update(id, updatedThingType).then((respone) => {
      const responseData = respone.data;
      this.chosenThingType = responseData;
      this.thingTypes.map((c) => {
        if (c.id === responseData.id) {
          Object.assign(c, responseData);
        }
      });    
    });
  };

  reloadChosenThingType = async (id: number) => {
    this.chosenThingType = null;
    await this.fetchChosenThingType(id);
  }

  // Associated thingType features
  fetchAssociatedFeatures = async (thingTypeId: number) => {
    this.associatedFeaturesAreLoading = true;
    await services.thingTypes.getFeatures(thingTypeId)
        .then((response) => {this.associatedFeatures=response.data; this.associatedFeaturesAreLoading=false;});
  }

  reloadAssociatedFeatures = async (thingTypeId: number) => {
    this.associatedFeatures = [];
    await this.fetchAssociatedFeatures(thingTypeId);
  }


  constructor() {
    makeAutoObservable(this);
  }
}