import styled from "styled-components";

export const Selection = styled.div`
  height: 100%;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

  .item-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;  /* Align items within each row */
  align-content: flex-start; /* Align rows at the top */
  gap: 16px;
  overflow: auto;
}
`;
