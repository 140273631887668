import React from "react";

import { Route } from "@plasma/ui.application.router";

const Systems = React.lazy(() => import("."));

export const SystemSelectionRoutes = [
  new Route({
    path: "",
    element: Systems,
  }),
];
