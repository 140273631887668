import React from 'react';

import { Route } from '@plasma/ui.application.router';
const Configuration = React.lazy(() => import('.'));

export const ConfigurationSelectionRoutes = [
  new Route({
    path: '',
    element:Configuration
  }),
];
