import baseStyled, { ThemedStyledInterface } from 'styled-components';

import Card from '@plasma/ui.display.card/dist/card';

import { SemanticsTheme } from '../../../themes/types';

const styled = baseStyled as ThemedStyledInterface<SemanticsTheme>;

export const StyledCheckboxGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    .checkbox-item{
        margin-left: 0px;
    }
`;
