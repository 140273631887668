import React from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../stores/QueryClient";
import { SystemCompleteDto } from "../../../models/dtos/system/SystemDtos";

export default function SystemGeneralForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const cached = queryClient.getQueryData<{ system: SystemCompleteDto }>([
    "selectedSystem",
  ]);
  return (
    <div className="form-container">
      <Form
        initialValues={cached?.system}
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={onChange}
      >
        <FormField required name={"name"} label={t("system.name")}>
          <TextInput disabled={viewOnly}/>
        </FormField>
        <FormField name={"description"} label={t("system.description")}>
          <TextArea rows={5} disabled={viewOnly}/>
        </FormField>
      </Form>
    </div>
  );
}
