export class MetricDto {
    name: string;
    description?: string;
    sampling: SamplingInterval;
    unitId: number;

    constructor(
        name: string,       
        unitId: number,
        sampling: SamplingInterval,
        description?: string,
    ) {
        this.name = name;
        this.unitId = unitId;
        this.sampling=sampling;
        this.description = description;
    }
}

export class MetricCompleteDto extends MetricDto {
    id: number;
    profileId: number;

    constructor(
        id: number,
        profileId: number,
        name: string,
        unitId: number,
        sampling: SamplingInterval,
        description?: string        
    ) {
        super(name, unitId, sampling, description);
        this.id = id;
        this.profileId = profileId;
    }
}

export enum SamplingInterval {
    None="None",
    Second="Second",
    Minute="Minute",
    Hour="Hour",
    Day="Day",
    Week="Week",
    Month="Month",
    Year="Year",
}
