import React, { useEffect, useMemo, useState } from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../../stores/QueryClient";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";
import Transfer from "@plasma/ui.input.transfer";
import { TransferData } from "../../../../models/types/TransferData";
import { ProfilePartDto } from "../../../../models/dtos/profile/ProfileComposition";

export default function ProfileCompositionForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();

  const { data: availableProfiles } = useQuery(["availableProfiles"], () =>
    services.profiles.getAll().then((r) => {
      return r.data;
    })
  );

  const cached = queryClient.getQueryData<{ profile: ProfileCompleteDto, compositions: ProfilePartDto[] }>([
    "selectedProfile",
  ]);

  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [transferData, setTransferData] = useState<TransferData[]>([]);
  const [inheritanceToBeSet, setInheritanceToBeSet] = useState<boolean>(true);

  useEffect(() => {
    if (availableProfiles) {
      buildTransferData(availableProfiles??[]);
    }
    buildTargetKeys(cached?.compositions ?? []) 
  }, [availableProfiles, cached])

  useMemo(() => {
    if (targetKeys.length && transferData.length && inheritanceToBeSet) {
      transferData.map((dataItem) => {
        const keySelected = targetKeys.includes(dataItem.key);
        const associatedProfile = cached?.compositions.find((associatedProfile) => associatedProfile.profileId === Number(dataItem.key));
        const keyInherited = associatedProfile?.inherited === true;
        dataItem.disabled = keySelected && keyInherited;
      });
      setInheritanceToBeSet(false);
    }
  }, [targetKeys, transferData]);

  const handleOnChange = async (newSelectedTargetKeys: string[]) => {
    
    if (newSelectedTargetKeys !== undefined) {
      setInheritanceToBeSet(false);
      const removePromises = removeKeysFromTargetKeys(newSelectedTargetKeys);
      const addPromises = addKeysToTargetKeys(newSelectedTargetKeys);

      await Promise.all([...removePromises, ...addPromises]).then(() => {
        setTargetKeys(newSelectedTargetKeys);
        onChange(transferData.filter(e=> newSelectedTargetKeys.includes(e.key) && e.disabled === false).map(e=>e.key));
      });      
    }    
  };

  const buildTargetKeys = (associatedProfiles: ProfilePartDto[]) => {
    const associatedProfileIds = associatedProfiles.map((profile) => profile.profileId.toString());
    setTargetKeys(associatedProfileIds);
  };

  const buildTransferData = (profiles: ProfileCompleteDto[]) => {
    const sufficientProfiles = profiles.filter((profile) => profile.id !== cached?.profile.id);
    setTransferData(sufficientProfiles.map((profile) => new TransferData(profile.id.toString(), profile.name, profile.description)))
  };

  const removeKeysFromTargetKeys = (newSelectedTargetKeys: string[]) => {
    const keysToRemove = targetKeys.filter((t) => !newSelectedTargetKeys.includes(t));
    const promises = keysToRemove.map(async (key) => {
      await services.profiles.getCompositions(Number(key))
        .then((response) => {
          const childrenToRemoveProfileIds = response.data.map((profile) => profile.profileId.toString());
          childrenToRemoveProfileIds.map((childId) => {
            const indexToRemove = newSelectedTargetKeys.indexOf(childId);
            newSelectedTargetKeys.splice(indexToRemove, 1);
            const dataItem = transferData.find((dataItem) => dataItem.key === childId);
            if (dataItem) {
              dataItem.disabled = false;
            }
          });
        });
    });
    return promises;
  };

  const addKeysToTargetKeys = (newSelectedTargetKeys: string[]) => {
    const keysToAdd = newSelectedTargetKeys.filter((t) => !targetKeys.includes(t));
    const promises = keysToAdd.map(async (key) => {
      await services.profiles.getCompositions(Number(key))
        .then((response) => {
          const childrenToAddProfileIds = response.data.map((profile) => profile.profileId.toString());
          childrenToAddProfileIds.map((childId) => {
            if (!newSelectedTargetKeys.includes(childId)) {
              newSelectedTargetKeys.push(childId);
            }
            const dataItem = transferData.find((dataItem) => dataItem.key === childId);
            if (dataItem) {
              dataItem.disabled = true;
            }
          });
        });
    });
    return promises;
  };

  return (
    <div className="form-container">
      <Form
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={(x) => handleOnChange(x['compositions'])}
      >
        <FormField name={"compositions"} >
          <Transfer
            className="transfer"
            dataSource={transferData}
            targetKeys={targetKeys}
            render={(item) => item.title}
            titles={[t("transfer.available"), t("transfer.associated")]}
            listStyle={{ height: "50vh", width: "25vw" }}
            disabled={viewOnly}
          />
        </FormField>
      </Form>
    </div>
  );
}