import React, { useEffect, useState } from "react";
import services from "../../../../api/agent";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import {
    useLoaderData,
    useNavigate,
    useParams
} from "@plasma/ui.application.router";
import { queryClient } from "../../../../stores/QueryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStore } from "../../../../stores/store";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";
import { RelationTypeDto } from "../../../../models/dtos/relation-type/RelationTypeDtos";
import RelationTypeGeneralForm from "./RelationTypeGeneralForm";
import RelationTypeConstraintsForm from "./RelationTypeConstraintsForm";
import { RelationTypeConstraintBase, RelationTypeConstraintDto } from "../../../../models/dtos/relation-type/RelationTypeConstraintDtos";
import { AxiosError } from "axios";

function useCreateRelationTypeHook() {
    const loaderData = useLoaderData() as { type: "create" | "edit" | "view" };
    const actionType = loaderData.type;
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const deleteHandler = async (name:string|undefined) => {
        if (id) await services.relationTypes.delete(parseInt(id));
        return name;
    };

    const { mutate: deleteRelationType, isLoading: isDeleting } = useMutation({
        mutationFn: deleteHandler,
        onSuccess: (name) => {
              notificationant.success({
                message: t("notification.success.title"),
                description: (
                  <Trans
                    i18nKey={"notification.delete.success.description"}
                    values={{
                      itemType: t("relation-types.title.single"),
                      itemName: name,
                    }}
                  />
                ),
              });
              closeHandler();
            },
            onError: (error) => {
              notificationant.error({
                message: t("notification.delete.error.title"),
                description: (
                  <Trans
                    i18nKey={"notification.delete.error.description"}
                    values={{ itemType: t("relation-types.title.single"), itemName: "" }}
                  />
                ),
              });
            },
    });
    const { data: selectedRelationType } = useQuery(
        ["selectedRelationType"],
        () =>
            Promise.all([
                services.relationTypes.getById(parseInt(id!)),
                services.relationTypes.getAllConstraints(parseInt(id!))
            ]).then((r) => {
                return { relationType: r[0].data, constraints: r[1].data};
            }),
        { enabled: !!id, refetchOnWindowFocus: false }
    );
    const { ActionStore } = useStore();

    useEffect(() => {
        if (actionType === "edit" || actionType === "view") {
            ActionStore.setActions(
                <>
                    {actionType === "view" ? (
                        <Button
                            title="Edit"
                            onClick={() =>
                                navigate(`/configuration/relation-types/edit/${id}`)
                            }
                        />
                    ) : null}
                    <ConfirmModal
                        valueToValidate={selectedRelationType?.relationType.name}
                        okButtonType="danger"
                        content={
                            <Trans
                                i18nKey={"confirm.delete.description"}
                                values={{ itemName: selectedRelationType?.relationType.name }}
                            />
                        }
                        title={t("confirm.delete.title")}
                        okText={t("button.delete")}
                        cancelText={t("button.cancel")}
                        confirm={true}
                        onOk={() => deleteRelationType(selectedRelationType?.relationType.name)}
                    >
                        <Button
                            loading={isDeleting}
                            type="secondary"
                            title={t("button.delete")}
                            icon={<Icon name="delete_outline" />}
                        />
                    </ConfirmModal>
                </>
            );
        }
    }, [i18n.language, isDeleting, selectedRelationType, actionType]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries(["selectedRelationType"]);
            ActionStore.clearNodes();
        };
    }, [])


    const [currentStep, setCurrentStep] = useState(0);
    const [isSaving, setIsSaving] = useState(false)
    const [generalData, setGeneralData] = useState<{
        name: string;
        description?: string;
        fromThingTypeId: number;
        toThingTypeId: number;
    }>();
    const [constraintData, setConstraintData] = useState<{
        constrained: boolean;
        constraints: RelationTypeConstraintBase[];
    }>({constrained: false, constraints: []});
    const navigate = useNavigate();
    const nextStepHandler = () => {
        setCurrentStep(currentStep + 1);
    };

    const saveHandler = async () => {

        if (actionType === "create")
        {
            if (generalData && constraintData) {

                let relationTypeId:number
                try {
                    setIsSaving(true)
                    const relationTypeBase = new RelationTypeDto(
                        generalData.fromThingTypeId,
                        generalData.toThingTypeId,
                        generalData.name,
                        constraintData.constrained,
                        generalData.description
                    );

                    try {
                        relationTypeId = (await services.relationTypes.create(relationTypeBase)).data.id
                    }
                    catch (e:any){
                        const errorMessage = t("notification.create.error.description", {
                            itemType: t("relation-types.title.single"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
            
                        notificationant.error({
                            message: t("notification.create.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        return;
                    }
                                
                    const constraintsToCreate = constraintData.constraints.filter(newConstraint => 
                        !selectedRelationType?.constraints?.some( existingConstraint => 
                            newConstraint.fromProfileId === existingConstraint.fromProfileId &&
                            newConstraint.toProfileId === existingConstraint.toProfileId
                        ));

                    if(constraintsToCreate){
                        try {
                            await services.relationTypes.createConstraint(
                                relationTypeId,
                                constraintsToCreate.map((e) => new RelationTypeConstraintBase(
                                    e.fromProfileId,
                                    e.toProfileId
                                ))
                            );
                        } 
                        catch (e:any){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("relation-type.constraint"),
                                itemName: "",
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e;
                        }
                    }               

                    notificationant.success({
                        message: t("notification.success.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.create.success.description"}
                                values={{ itemType: t("relation-types.title"), itemName: generalData.name }}
                            />
                        ),
                    });
                    navigate('/configuration/relation-types')
                }
                catch (e) {
                    try {
                        await services.relationTypes.delete(relationTypeId!);
                    } catch {}
                }
                finally {
                    setIsSaving(false)
                }
        }
    }
    else{
        if (generalData && constraintData) {
            let relationTypeId:number = selectedRelationType!.relationType.id
            try {
                setIsSaving(true)
                const relationTypeBase = new RelationTypeDto(
                    generalData.fromThingTypeId,
                    generalData.toThingTypeId,
                    generalData.name,
                    constraintData.constrained,
                    generalData.description
                );
                
                try {
                    await services.relationTypes.update(relationTypeId,relationTypeBase);
                }
                catch (e:any){
                    const errorMessage = t("notification.update.error.description", {
                        itemType: t("relation-types.title.single"),
                        itemName: generalData.name,
                        error: e instanceof AxiosError ? e.response?.data : ""
                    });
        
                    notificationant.error({
                        message: t("notification.update.error.title"),
                        description: (
                            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        ),
                    });
                    return;
                }

                const constraintsToDelete = selectedRelationType?.constraints.filter(existingConstraint => 
                    !constraintData.constraints.some( newConstraint =>
                        newConstraint.toProfileId === existingConstraint.toProfileId &&
                        newConstraint.fromProfileId === existingConstraint.fromProfileId
                    )
                ).map(existingConstraint => existingConstraint.id)

                constraintsToDelete?.forEach((constraintId) =>{
                    try {
                        services.relationTypes.deleteConstraintById(relationTypeId,constraintId);
                    }
                    catch (e:any){
                        const errorMessage = t("notification.delete.error.description", {
                            itemType: t("relation-type.constraint"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
            
                        notificationant.error({
                            message: t("notification.delete.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        throw e;
                    }
                })
                              
                const constraintsToCreate = constraintData.constraints.filter(newConstraint => 
                    !selectedRelationType?.constraints?.some( existingConstraint => 
                        newConstraint.fromProfileId === existingConstraint.fromProfileId &&
                        newConstraint.toProfileId === existingConstraint.toProfileId
                    ));

                if(constraintsToCreate){
                    try {
                        await services.relationTypes.createConstraint(
                            relationTypeId,
                            constraintsToCreate.map((e) => new RelationTypeConstraintBase(
                                e.fromProfileId,
                                e.toProfileId
                              ))
                        );
                    }
                    catch (e:any){
                        const errorMessage = t("notification.create.error.description", {
                            itemType: t("relation-type.constraint"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
            
                        notificationant.error({
                            message: t("notification.create.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        throw e;
                    }
                }               

                notificationant.success({
                    message: t("notification.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.success.description"}
                            values={{ itemType: t("relation-types.title"), itemName: generalData.name }}
                        />
                    ),
                });
                navigate('/configuration/relation-types')
            }
            catch (e) {
            }
            finally {
                setIsSaving(false)
            }
        }
    }
        

    };

    const steps = [
        {
            title: "relation-types.step.general.title",
            icon: "sell",
            description: "relation-types.step.general.description",
            element: (
                <RelationTypeGeneralForm
                    onSubmit={nextStepHandler}
                    index={0}
                    onChange={setGeneralData}
                    viewOnly={actionType === "view"}
                />
            )
        },
        {
            title: "relation-types.step.constraints.title",
            icon: "blocked",
            description: "relation-types.step.constraints.description",
            element: (
                <RelationTypeConstraintsForm
                    index={1}
                    key={1}
                    onSubmit={saveHandler}
                    onChange={setConstraintData}
                    viewOnly={actionType === "view"}
                />
            ),
        },
    ];

    const prevStepHandler = () => {
        setCurrentStep(currentStep - 1);
    };
    const closeHandler = () => {
        navigate("/configuration/relation-types");
    };

    return {
        currentStep,
        steps,
        nextStepHandler,
        closeHandler,
        prevStepHandler,
        setCurrentStep,
        isSaving,
        actionType
    };
}

export default useCreateRelationTypeHook;
