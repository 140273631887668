import React, { useEffect, useState } from "react";
import Form, {
  useForm,
} from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import { useTranslation } from "react-i18next";
import Select from "@plasma/ui.input.select";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";
import { queryClient } from "../../../../stores/QueryClient";
import { DataProvider } from "../../../../models/dtos/data-provider/DataProviderDto";
import { DataProviderConnectorDto } from "../../../../models/dtos/data-provider/ConnectorDto";
import TextArea from "antd/lib/input/TextArea";

export default function DataProviderConnectionForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const { data } = useQuery(["connectors"], services.connectors.getAll);
  const [selectedType, setSelectedType] = useState<string>();
  const methods = useForm();
    const cached = queryClient.getQueryData<{ connector: DataProviderConnectorDto }>([
      "selectedDataProvider",
    ]); 
  useEffect(()=>{
    if(cached?.connector.connectorName){
      methods.setValue("connectionType",cached.connector.connectorName)
      cached?.connector.parameterValues?.map((v)=>
        methods.setValue(v.name,v.value)
      )
    }
  },[cached])

  return (
    <div style={{ padding: 16, width: "45%", minWidth: "400px" }}>
      <Form
        methods={methods}
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        onChange={(x) => {
          setSelectedType(x.connectionType);
          onChange(x);
        }}
        className="form"
      >
        <FormField
          valuePropName="value"
          required
          name={"connectionType"}
          label={t("data-provider.connection-type")}
        >
          <Select
            allowClear
            onClear={() => {
              methods.reset();
            }}
            options={
              data?.data?.map((p) => ({ value: p.name, label: p.displayName })) ?? []
            }
            disabled={viewOnly}
          />
        </FormField>
        {data?.data
          ?.find((c) => selectedType !== null && c.name === selectedType)
          ?.connectionParameters.map((c, ii) => {
            return (
              <FormField
                key={ii}
                name={c.name}
                label={c.displayName}
                extra={<div>Type: {c.type}</div>}
                hint={c.description}
                required={c.isRequired}
              >
                <TextArea rows={1} style={{ flexGrow: 1 }} disabled={viewOnly} />
              </FormField>
            );
          })}
      </Form>
    </div>
  );
}
