import React from 'react';

import Checkbox from "@plasma/ui.input.checkbox";
import { StyledCheckboxGroup } from './CheckboxGroup.styles';

export interface CheckboxGroupProps { 
  data: { id: number, name: string }[], 
  value?: number[], 
  onChange?: (value: number[]) => void,
  disabled: boolean
}

const CheckboxGroup = ({data, value, onChange, disabled}: CheckboxGroupProps) => {
  const changeHandler = (id:number) => {
    let selectedIds: number[] = [...value??[]]
    const index = selectedIds.indexOf(id)
    if (index === -1) {
      selectedIds = [...selectedIds, id]
    }
    else {
      const newArray = [...selectedIds]
      newArray.splice(index, 1)
      selectedIds = newArray
    }
    if(onChange)
    onChange(selectedIds)
  }
  return(
  <StyledCheckboxGroup>
      {data.map((item,ii) =>
          <Checkbox disabled={disabled} className="checkbox-item" checked={value?.includes(item.id)}  key={ii} label={item.name} onChange={() => changeHandler(item.id)}/>)}
  </StyledCheckboxGroup>)
};

export default CheckboxGroup;