import React from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../../stores/QueryClient";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import Select from "@plasma/ui.input.select";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";

export default function ProfileGeneralForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();

  const { data: availableThingTypes } = useQuery(["availableThingTypes"], () =>
    services.thingTypes.getAll().then((r) => {
      return r.data.map((p) => ({ value: p.id, label: p.name }));
    })
  );

  const cached = queryClient.getQueryData<{ profile: ProfileCompleteDto }>([
    "selectedProfile",
  ]);
  return (
    <div className="form-container">
      <Form
        initialValues={cached?.profile}
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={onChange}
      >
        <FormField required name={"name"} label={t("profile.property.name")}>
          <TextInput disabled={viewOnly}/>
        </FormField>
        <FormField name={"description"} label={t("profile.property.description")}>
          <TextArea rows={5} disabled={viewOnly}/>
        </FormField>
        <FormField name={"thingTypeId"} label={t("profile.property.thingType")}>
          <Select options={availableThingTypes ?? []} disabled={viewOnly}/>
        </FormField>
      </Form>
    </div>
  );
}
