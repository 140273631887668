import React, { useEffect, useState } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import services from "../../../api/agent";
import { queryClient } from "../../../stores/QueryClient";
import Collapse from "@plasma/ui.layout.collapse";
import styled from "styled-components";
import Select from "@plasma/ui.input.select";
import Button from "@plasma/ui.general.button";
import TextInput from "@plasma/ui.input.text-input";
import { Icon } from "@plasma/ui.general.icon";
import { IdentifierRequirementDto } from "../../../models/dtos/system/IdentifierRequirementDto";
import { ThingTypeCompleteDto } from "../../../models/dtos/thing-type/ThingTypeDtos";
import Popconfirm from "@plasma/ui.feedback.pop-confirm";


export default function SystemIdentificationForm({
  onChange,
  index,
  onSubmit,
  selectedThingTypeIds,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  selectedThingTypeIds: number[] | undefined;
  viewOnly: boolean;
}) {

  const { t } = useTranslation();
  const { data } = useQuery(["thingTypes"], services.thingTypes.getAll);
  const cached = queryClient.getQueryData<{ associatedThingTypes: ThingTypeCompleteDto[], associatedIdentifiers: IdentifierRequirementDto[] }>([
      "selectedSystem",
    ]);

  const [selectedThingTypes, setSelectedThingTypes] = useState<ThingTypeCompleteDto[]>([]);
    

  const loadSystemThingTypes = () => {
    let systemThingTypes:ThingTypeCompleteDto[] = []
    if (cached?.associatedThingTypes)
    {
      systemThingTypes =  cached?.associatedThingTypes
    }
    if(!viewOnly)
    {
      if (data?.data && selectedThingTypeIds) {
        systemThingTypes = data.data.filter(item => selectedThingTypeIds?.includes(item.id))
      }
    }
    setSelectedThingTypes(systemThingTypes)
  }

  const methods = useForm()
  useEffect(()=>{
    loadSystemThingTypes()
    if (cached?.associatedIdentifiers && selectedThingTypes) {
      selectedThingTypes.forEach((stt) => {
        const identifiers = cached?.associatedIdentifiers.filter((e) => e.thingTypeId == stt.id)
        methods.setValue(stt.id.toString(), identifiers)
      });
    }
    
  },[selectedThingTypeIds, cached, data, viewOnly])
  return (
    <div className="form-container">
      <Form
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={(e) => onChange(e)}
        methods={methods}
      >
        <Collapse>
        {selectedThingTypes.map((d, ii) => (
          <Collapse.Panel header={d.name} key={ii}>
          <FormField
            name={d.id.toString()}
          >
            <IdentifierConfigurator disabled={viewOnly}/>
          </FormField>
          </Collapse.Panel>
        ))}
        </Collapse>
      </Form>
    </div>
  );
}


const IdentifierConfigurator = (identifiers:any ) => {
  const { t } = useTranslation();
  const types = [
    { value: 1, label: "True" },
    { value: 0, label: "False" }
  ];
  const changeHandler = (index: number, key: string, value: string) => {
    if (identifiers.value) {
      const newValue = [...identifiers.value];
      newValue[index][key] = value;
      identifiers.onChange(newValue);
    }
  };

  const addHandler = () => {
    if (identifiers.value) {
      const newValue = [...identifiers.value];
      newValue.push({
        id: undefined,
        name: "",
        editable: true,
        mandatory: false
      });
      identifiers.onChange(newValue);
    } else {
      identifiers.onChange([
        { id: undefined, name: "", editable: true,
          mandatory: false },
      ]);
    }
  };
  const deleteHandler = (index: number) => {
    if (identifiers.value) {
      const newValue = [...identifiers.value];
      newValue.splice(index, 1);
      identifiers.onChange(newValue);
    }
  };

  return (
    <>
      <IdentifierGrid>
        <div className="row header">
          <span className="cell">{t("identifier.name")}</span>
          <span className="cell">{t("identifier.editable")}</span>
          <span className="cell">{t("identifier.mandatory")}</span>          
          <span className="cell">
            <div
              style={{ height: 0, overflow: "hidden" }}
              className="cell toolbar"
            >
              {!identifiers.disabled ? (
              <Button
                style={{ border: 0 }}
                icon={<Icon name="delete" />}
                type="tertiary"
              />):null}
            </div>
          </span>
        </div>
        {identifiers?.value?.map((p: IdentifierRequirementDto, ii: any) => (
          <div key={ii} className="row">
            <div className="cell">
              <TextInput
                onChange={(v) => changeHandler(ii, "name", v.target.value)}
                value={p.name}
                disabled={identifiers.disabled}
              />
            </div>
            <div className="cell">
              <Select
                options={types}
                onChange={(v) => changeHandler(ii, "editable", v)}
                value={Number(p.editable)}
                disabled={identifiers.disabled}
              />
            </div>
            <div className="cell">
              <Select
                options={types}
                onChange={(v) => changeHandler(ii, "mandatory", v)}
                value={Number(p.mandatory)}
                disabled={identifiers.disabled}
              />
            </div>
            <div className="cell toolbar">
            {!identifiers.disabled ? (
              <Popconfirm
                okButtonProps={{ type: "tertiary" }}
                cancelButtonProps={{ type: "tertiary" }}
                icon={<Icon name="delete" />}
                onConfirm={() => deleteHandler(ii)}
                title={
                  <Trans
                    i18nKey={"delete-modalconfirm-message.short"}
                    values={{ name: p.name }}
                  />
                }
              >
                <Button
                  style={{ border: 0 }}
                  icon={<Icon name="delete" />}
                  type="tertiary"
                />
              </Popconfirm>):null}
            </div>
          </div>
        ))}
      </IdentifierGrid>
      {!identifiers.disabled ? (
      <div>
        <Button
          style={{ marginLeft: 2 }}
          onClick={addHandler}
          icon={<Icon name="add" />}
          type="tertiary"
          title={t("button.add")}
        />
      </div>):null}
    </>
  );
};

const ParameterForm = styled.div`
  padding: 16px;
  width: 45%;
  min-width: 600px;
`;

const IdentifierGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  border-collapse: collapse;
  margin-bottom: 16px;
  .icon-button {
    border: 0px;
  }
  .row {
    display: contents;
  }
  .cell {
    padding-top: 16px;
    padding-right: 16px;
    &.toolbar {
      display: flex;
      padding-right: 0px;
      padding-right: 2px;
    }
  }
  .header .cell {
    padding-top: 0px;
  }
`;
