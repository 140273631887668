import React, { useEffect } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import services from "../../../api/agent";
import { queryClient } from "../../../stores/QueryClient";
import CheckboxGroup from "../../../components/shared/CheckboxGroup/CheckboxGroup";
import { RelationTypeCompleteDto } from "../../../models/dtos/relation-type/RelationTypeDtos";

export default function SystemRelationTypesForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean
}) {
  const { t } = useTranslation();
  const { data: availableRelationTypes } = useQuery(["availableRelationTypes"], () => services.relationTypes.getAll().then((r) => r.data));
  const cached = queryClient.getQueryData<{ associatedRelationTypes: RelationTypeCompleteDto[] }>([
      "selectedSystem",
    ]);

  const methods = useForm()
  useEffect(()=>{
    if(cached?.associatedRelationTypes)
      methods.setValue("relation-types",cached?.associatedRelationTypes.map((e)=>(e.id)))
  },[])
  return (
    <div className="form-container">
      <Form
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={(e) => onChange(e)}
        methods={methods}
      >
        <FormField name={"relation-types"}>
          <CheckboxGroup 
            data={availableRelationTypes?.map((d)=>({id:d.id,name:d.name}))??[]}
            disabled={viewOnly}
          />
        </FormField>
      </Form>
    </div>
  );
}