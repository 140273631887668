import { t } from "i18next";

export class ClickableItemMetadata {
    id: number;
    title: string;
    description?: React.ReactNode;
    footer?: React.ReactNode;
    path: string;

  constructor(id: number, title: string, path: string, description?: React.ReactNode, footer?: React.ReactNode) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.path = path;
    this.footer = footer;
  }
}