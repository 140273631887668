import React, { useEffect, useState } from "react";
import services from "../../../../api/agent";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import {
    useLoaderData,
    useNavigate,
    useParams
} from "@plasma/ui.application.router";
import { queryClient } from "../../../../stores/QueryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStore } from "../../../../stores/store";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";
import { ProfileBase } from "../../../../models/dtos/profile/ProfileDtos";
import { ProfileComposition } from "../../../../models/dtos/profile/ProfileComposition";
import ProfileGeneralForm from "./ProfileGeneralForm";
import ProfileCompositionForm from "./ProfileCompositionForm";
import ProfileAttributeForm, { ProfileAttributesData } from "./ProfileAttributeForm";
import ProfileMetricForm, { ProfileMetricsData } from "./ProfileMetricForm";
import { AttributeDto } from "../../../../models/dtos/AttributeDtos";
import { MetricDto } from "../../../../models/dtos/MetricDto";
import _ from "lodash";
import { AxiosError } from "axios";

const useCreateProfileHook = () => {
    const loaderData = useLoaderData() as { type: "create" | "edit" | "view" };
    const actionType = loaderData.type;
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const deleteHandler = async (name:string|undefined) => {
        if (id) await services.profiles.delete(parseInt(id));
        return name;
    };

    const { mutate: deleteProfile, isLoading: isDeleting } = useMutation({
        mutationFn: deleteHandler,
        onSuccess: (name) => {
              notificationant.success({
                message: t("notification.success.title"),
                description: (
                  <Trans
                    i18nKey={"notification.delete.success.description"}
                    values={{
                      itemType: t("profiles.title.single"),
                      itemName: name,
                    }}
                  />
                ),
              });
              closeHandler();
            },
            onError: (error) => {
              notificationant.error({
                message: t("notification.delete.error.title"),
                description: (
                  <Trans
                    i18nKey={"notification.delete.error.description"}
                    values={{ itemType: t("profiles.title.single"), itemName: "" }}
                  />
                ),
              });
            },
    });
    const { data: selectedProfile } = useQuery(
        ["selectedProfile"],
        () =>
            Promise.all([
                services.profiles.getById(parseInt(id!)),
                services.profiles.getCompositions(parseInt(id!)),
                services.profiles.getAllAttributes(parseInt(id!)),
                services.profiles.getAllMetrics(parseInt(id!))
            ]).then((r) => {
                setCompositionsData(r[1].data.filter(e=>e.inherited==false).map(e=>e.profileId.toString()))
                return { profile: r[0].data, compositions: r[1].data, attributes:r[2].data, metrics:r[3].data};
            }),
        { enabled: !!id, refetchOnWindowFocus: false }
    );
    const { ActionStore } = useStore();

    useEffect(() => {
        if (loaderData.type === "edit" || loaderData.type === "view") {
            ActionStore.setActions(
                <>
                    {actionType === "view" ? (
                        <Button
                            title="Edit"
                            onClick={() =>
                                navigate(`/configuration/profiles/edit/${id}`)
                            }
                        />
                    ) : null}
                    <ConfirmModal
                        valueToValidate={selectedProfile?.profile.name}
                        okButtonType="danger"
                        content={
                            <Trans
                                i18nKey={"confirm.delete.description"}
                                values={{ itemName: selectedProfile?.profile.name }}
                            />
                        }
                        title={t("confirm.delete.title")}
                        okText={t("button.delete")}
                        cancelText={t("button.cancel")}
                        confirm={true}
                        onOk={() => deleteProfile(selectedProfile?.profile.name)}
                    >
                        <Button
                            loading={isDeleting}
                            type="secondary"
                            title={t("button.delete")}
                            icon={<Icon name="delete_outline" />}
                        />
                    </ConfirmModal>
                </>
            );
        }
    }, [i18n.language, isDeleting, selectedProfile, actionType]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries(["selectedProfile"]);
            ActionStore.clearNodes();
        };
    }, [])


    const [currentStep, setCurrentStep] = useState(0);
    const [isSaving, setIsSaving] = useState(false)
    const [generalData, setGeneralData] = useState<{
        thingTypeId: number,
        name: string;
        description?: string;
    }>();
    const [compositionsData, setCompositionsData] = useState<string[]>();
    const [attributesData, setAttributesData] = useState<ProfileAttributesData[]>();
    const [metricsData, setMetricsData] = useState<ProfileMetricsData[]>();
    const navigate = useNavigate();
    const nextStepHandler = () => {
        setCurrentStep(currentStep + 1);
    };

    const [shouldSave, setShouldSave] = useState(false)

    useEffect(() => {
        if(shouldSave){
            saveHandler();
            setShouldSave(false);
        }
    },[metricsData,shouldSave]);

    const saveHandler = async () => {
    
        if (actionType === "create")
        {
            if (generalData && attributesData && metricsData) {
                
                let profileId:number
                try {
                    setIsSaving(true)
                    const profileBase = new ProfileBase(
                        generalData.name,
                        generalData.thingTypeId,
                        generalData.description
                    );
                                       
                    try {
                        profileId = (await services.profiles.create(profileBase)).data.id;
                    }
                    catch (e){
                        const errorMessage = t("notification.create.error.description", {
                            itemType: t("profiles.title.single"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
                        notificationant.error({
                            message: t("notification.create.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        return;
                    }

                    if(compositionsData)
                    {
                        try {
                            await services.profiles.addCompositions(profileId,compositionsData.map(item => new ProfileComposition(parseInt(item))))
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("profile.composition"),
                                itemName: "",
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e;
                        }
                    }

                    const relevantAttributes = attributesData.filter(item => item.isNew)

                    relevantAttributes.forEach(async attribute => {
                        const newAttribute = new AttributeDto(
                            attribute.name,
                            attribute.dataType,
                            attribute.uomId,
                            attribute.mandatory,
                            attribute.description
                        )

                        try {
                            await services.profiles.createAttribute(profileId,newAttribute)
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("profile.attribute"),
                                itemName: attribute.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e;
                        }
                    });

                    const relevantMetrics = metricsData.filter(item => item.isNew)

                    relevantMetrics.forEach(async metric => {
                        const newMetric = new MetricDto(
                            metric.name,
                            metric.uomId,
                            metric.sampling,
                            metric.description
                        )

                        try {
                            await services.profiles.createMetric(profileId,newMetric)
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("profile.metric"),
                                itemName: metric.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e;
                        }
                    });
                    

                    notificationant.success({
                        message: t("notification.success.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.create.success.description"}
                                values={{ itemType: t("profiles.title"), itemName: generalData.name }}
                            />
                        ),
                    });
                    navigate('/configuration/profiles')
                }
                catch (e) {
                    try {
                        await services.profiles.delete(profileId!);
                    }
                    catch { }
                }
                finally {
                    setIsSaving(false)
                }
            }
    
        }
        else{
            let profileId:number = selectedProfile!.profile.id
            try {
                setIsSaving(true)
                if (generalData) {
                    const profileBase = new ProfileBase(
                        generalData.name,
                        generalData.thingTypeId,
                        generalData.description
                    );
                    
                    try {
                        await services.profiles.update(profileId, profileBase);
                    }
                    catch (e:any){
                        const errorMessage = t("notification.update.error.description", {
                            itemType: t("profiles.title.single"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
            
                        notificationant.error({
                            message: t("notification.update.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        return;
                    }
                }
                
                if(compositionsData)
                {
                    try {
                        await services.profiles.addCompositions(profileId,compositionsData.map(item => new ProfileComposition(parseInt(item))))
                    }
                    catch (e){
                        const errorMessage = t("notification.update.error.description", {
                            itemType: t("profile.composition"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
                        notificationant.error({
                            message: t("notification.update.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        return;
                    }
                }

                if(attributesData) {
                    const profileSpecificAttributes = attributesData.filter(item => item.profileId===selectedProfile?.profile.id)

                    const attributesToDelete = selectedProfile?.attributes.filter(item => item.profileId===selectedProfile.profile.id && !profileSpecificAttributes.some(e=>e.id===item.id))
                    attributesToDelete?.forEach(async attribute => {
                        try {
                            await services.profiles.deleteAttribute(selectedProfile?.profile.id!,attribute.id)
                        }
                        catch (e){
                            const errorMessage = t("notification.delete.error.description", {
                                itemType: t("profile.attribute"),
                                itemName: attribute.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.delete.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e
                        }
                    });

                    const attributesToUpdate = profileSpecificAttributes.filter(item => selectedProfile?.attributes.some(e=>e.id===item.id) && !_.isEqual(item,selectedProfile?.attributes.find(e=>e.id===item.id)))                    
                    attributesToUpdate.forEach(async attribute => {
                        const updatedAttribute = new AttributeDto(
                            attribute.name,
                            attribute.dataType,
                            attribute.uomId,
                            attribute.mandatory,
                            attribute.description
                        )
                        try {
                            await services.profiles.updateAttribute(profileId,attribute.id,updatedAttribute)
                        }
                        catch (e){
                            const errorMessage = t("notification.update.error.description", {
                                itemType: t("profile.attribute"),
                                itemName: attribute.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.update.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e
                        }
                    });

                    const attributesToCreate = profileSpecificAttributes.filter(item => item.isNew)
                    attributesToCreate.forEach(async attribute => {
                        const newAttribute = new AttributeDto(
                            attribute.name,
                            attribute.dataType,
                            attribute.uomId,
                            attribute.mandatory,
                            attribute.description
                        )

                        try {
                            await services.profiles.createAttribute(profileId,newAttribute)
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("profile.attribute"),
                                itemName: attribute.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                           throw e
                        }
                    });                    
                }

                if (metricsData) {
                    const profileSpecificMetrics = metricsData.filter(item => item.profileId===selectedProfile?.profile.id)
                    const metricsToDelete = selectedProfile?.metrics.filter(item => item.profileId===selectedProfile.profile.id && !profileSpecificMetrics.some(e=>e.id===item.id))

                    metricsToDelete?.forEach(async metric => {
                        try {
                            await services.profiles.deleteMetric(selectedProfile?.profile.id!,metric.id)
                        }
                        catch (e){
                            const errorMessage = t("notification.delete.error.description", {
                                itemType: t("profile.metric"),
                                itemName: metric.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.delete.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                           throw e
                        }
                    });

                    const metricsToUpdate = profileSpecificMetrics.filter(item => selectedProfile?.metrics.some(e=>e.id===item.id) && !_.isEqual(item,selectedProfile?.metrics.find(e=>e.id===item.id)))                    
                    metricsToUpdate.forEach(async metric => {
                        const updatedMetric = new MetricDto(
                            metric.name,
                            metric.uomId,
                            metric.sampling,
                            metric.description
                        )
                        try {
                            await services.profiles.updateMetric(profileId,metric.id,updatedMetric)
                        }
                        catch (e){
                            const errorMessage = t("notification.update.error.description", {
                                itemType: t("profile.metric"),
                                itemName: metric.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.update.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                           throw e
                        }
                    });

                    metricsData.filter(item => item.isNew).forEach(async metric => {
                        const newMetric = new MetricDto(
                            metric.name,
                            metric.uomId,
                            metric.sampling,
                            metric.description
                        )

                        try {
                            await services.profiles.createMetric(profileId,newMetric)
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("profile.metric"),
                                itemName: metric.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                           throw e
                        }
                    });
                }
                
                notificationant.success({
                    message: t("notification.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.update.success.description"}
                            values={{ itemType: t("profiles.title"), itemName: generalData?generalData.name:selectedProfile?.profile.name }}
                        />
                    ),
                });
                
                navigate('/configuration/profiles')
            }
            catch (e) {
            }
            finally {
                setIsSaving(false)
            }
        }
        
    };

    const steps = [
        {
            title: "profiles.step.general.title",
            icon: "sell",
            description: "profiles.step.general.description",
            element: (
                <ProfileGeneralForm
                    onSubmit={nextStepHandler}
                    index={0}
                    onChange={setGeneralData}
                    viewOnly={actionType==="view"}
                />
            )
        },
        {
            title: "profiles.step.compositions.title",
            icon: "extension",
            description: "profiles.step.compositions.description",
            element: (
                <ProfileCompositionForm
                    index={1}
                    key={1}
                    onSubmit={nextStepHandler}
                    onChange={setCompositionsData}
                    viewOnly={actionType==="view"}
                />
            ),
        },
        {
            title: "profiles.step.attributes.title",
            icon: "sticky_note_2",
            description: "profiles.step.attributes.description",
            element: (
                <ProfileAttributeForm
                    index={2}
                    key={2}
                    onSubmit={(data) => {setAttributesData(data); nextStepHandler()}}
                    viewOnly={actionType==="view"}
                    profileName={generalData?.name!}
                    compositionedProfiles={compositionsData}
                />
            ),
        },
        
        {
            title: "profiles.step.metrics.title",
            icon: "legend_toggle",
            description: "profiles.step.metrics.description",
            element: (
                <ProfileMetricForm
                    index={3}
                    key={3}
                    onSubmit={(data) => {setMetricsData(data);setShouldSave(true)}}
                    viewOnly={actionType === "view"} 
                    profileName={generalData?.name!}
                    compositionedProfiles={compositionsData}
                />
            ),
        },
    ];

    const prevStepHandler = () => {
        setCurrentStep(currentStep - 1);
    };
    const closeHandler = () => {
        navigate("/configuration/profiles");
    };

    return {
        currentStep,
        steps,
        nextStepHandler,
        closeHandler,
        prevStepHandler,
        setCurrentStep,
        isSaving,
        actionType
    };
}

export default useCreateProfileHook;