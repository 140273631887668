import React from 'react';

import { Route } from '@plasma/ui.application.router';

const Profiles = React.lazy(() => import('.'));

export const ProfilesRoutes = [
  new Route({
    path: '',
    element: Profiles,
  }),
];
