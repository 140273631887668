import { createContext, useContext } from 'react';

import SystemStore from './SystemStore';
import ThingTypeStore from './ThingTypeStore';
import RelationTypeStore from './RelationTypeStore';
import FeatureStore from './FeatureStore';
import ProfileStore from './ProfileStore';
import UnitOfMeasurementStore from './UnitOfMeasurementStore';
import { ActionStore } from './ActionStore';

interface Store {
    SystemStore: SystemStore;
    ThingTypeStore: ThingTypeStore;
    RelationTypeStore: RelationTypeStore;
    FeatureStore: FeatureStore;
    ProfileStore: ProfileStore;
    UnitOfMeasurementStore: UnitOfMeasurementStore;
    ActionStore:ActionStore;
}

const store: Store = {
    SystemStore: new SystemStore(),
    ThingTypeStore: new ThingTypeStore(),
    RelationTypeStore: new RelationTypeStore(),
    FeatureStore: new FeatureStore(),
    ProfileStore: new ProfileStore(),
    UnitOfMeasurementStore: new UnitOfMeasurementStore(),
    ActionStore:new ActionStore()
};

const StoreContext = createContext(store);

export const useStore = () => useContext(StoreContext);
