export class DataQueryBase {
  dataQueryTypeId: number;
  dataProviderId: number;
  action?: string;
  parameterMapping?: ParameterMapping[]; 

  constructor(
    dataQueryTypeId: number,
    dataProviderId: number,
    action?: string,
    parameterMapping?: ParameterMapping[]
  )
  {
    this.dataQueryTypeId = dataQueryTypeId;
    this.dataProviderId = dataProviderId;
    this.action = action;
    this.parameterMapping = parameterMapping;
  }
}

export class DataQuery extends DataQueryBase {
  id: number;

  constructor(
    id: number,
    dataQueryTypeId: number,
    dataProviderId: number,
    action?: string,
    parameterMapping?: ParameterMapping[]
  )
  {
    super(dataQueryTypeId, dataProviderId,action,parameterMapping);
    this.id = id;
  }  
}

export class ParameterMapping {
  actionInputName?: string;
  type?: ParameterMappingType;
  value?: string;
  providerParameter?: string;
  parameters?: string[];

  constructor(
    actionInputName: string,
    type?: ParameterMappingType,
    value?: string,
    providerParameter?: string,    
    parameters?: string[]
  )
  {
    this.actionInputName = actionInputName;
    this.type = type;
    this.value = value;
    this.providerParameter = providerParameter;
    this.parameters = parameters;
  }
}

export type ParameterMappingType = "QueryParameter" | "FixedValue";
export enum ParameterMappingTypeEnum {
    QueryParameter = "QueryParameter",
    FixedValue = "FixedValue",
  }