import React, { useEffect, useState } from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { Trans, useTranslation } from "react-i18next";
import { queryClient } from "../../../../stores/QueryClient";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "@plasma/ui.general.icon";
import services from "../../../../api/agent";
import { AttributeCompleteDto } from "../../../../models/dtos/AttributeDtos";
import Button from "@plasma/ui.general.button";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import Drawer from "@plasma/ui.layout.drawer";
import { AttributeType } from "../../../../models/enums/AttributeEnums";
import Select from "@plasma/ui.input.select";
import Switch from "@plasma/ui.input.switch";
import Checkbox from "@plasma/ui.input.checkbox";
import { Col, Divider, Row, Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";

export default function ProfileAttributeForm({
  index,
  onSubmit,
  viewOnly,
  profileName,
  compositionedProfiles,
}: {
  index: number;
  onSubmit: (value: any) => void;
  viewOnly: boolean;
  profileName: string;
  compositionedProfiles: string[] | undefined;
}) {
  const { t } = useTranslation();
  const { data: availableProfiles } = useQuery(["availableProfiles"], () =>
    services.profiles.getAll().then((r) => r.data)
  );
  const { data: availableUnits } = useQuery(["availableUoMs"], () =>
    services.unitsOfMeasurement.getAll().then((r) => r.data)
  );
  const cached = queryClient.getQueryData<{
    profile: ProfileCompleteDto;
    attributes: AttributeCompleteDto[];
  }>(["selectedProfile"]);

  const [attributesData, setAttributesData] = useState<ProfileAttributesData[]>(
    []
  );
  const [createdAttributesData, setCreatedAttributesData] = useState<
    ProfileAttributesData[]
  >([]);

  const sortAttributesData = (data: ProfileAttributesData[]) => {
    return data.sort((a, b) => {
      if (a.profileName === profileName && b.profileName !== profileName) {
        return -1;
      }
      if (a.profileName !== profileName && b.profileName === profileName) {
        return 1;
      }
      if (a.profileName === profileName && b.profileName === profileName) {
        if (a.isNew && !b.isNew) {
          return -1;
        }
        if (!a.isNew && b.isNew) {
          return 1;
        }
      }
      return a.profileName.localeCompare(b.profileName);
    });
  };

  const loadProfileSpecificAttributes = () => {
    if (!cached?.attributes) return [];
    return cached?.attributes
      .filter(
        (e) => e.profileId === cached?.profile.id || e.profileId === undefined
      )
      .map((e) => {
        const unit = availableUnits?.find((u) => u.id === e.unitId)?.name;
        return new ProfileAttributesData(
          e.id,
          e.profileId,
          cached.profile.name,
          e.name,
          e.type,
          e.unitId,
          unit!,
          false,
          e.mandatory,
          e.description
        );
      });
  };

  const loadCompositionedProfileAttributes = async () => {
    let loadedCompositionedAttributesData: ProfileAttributesData[] = [];
    if (compositionedProfiles) {
      loadedCompositionedAttributesData = (
        await Promise.all(
          compositionedProfiles!.map(async (profileId) => {
            const profile = availableProfiles?.find(
              (p) => p.id === Number(profileId)
            );
            const profileAttributes = await services.profiles
              .getAllAttributes(Number(profileId))
              .then((r) => {
                return r.data;
              });
            const inherited = profile?.id !== cached?.profile.id;
            const attributesData = profileAttributes.map((e) => {
              const unit = availableUnits?.find(
                (u) => u.id === e.unitId
              )?.name;
              return new ProfileAttributesData(
                e.id,
                e.profileId,
                profile?.name!,
                e.name,
                e.type,
                e.unitId,
                unit!,
                inherited,
                e.mandatory,
                e.description
              );
            });
            return attributesData;
          })
        )
      ).flat();
    }
    const profileSpecificAttributeData = loadProfileSpecificAttributes();
    setAttributesData(sortAttributesData([
      ...createdAttributesData,
      ...loadedCompositionedAttributesData,
      ...profileSpecificAttributeData!,
    ]));
  };

  useEffect(() => {
    
    if (viewOnly) {
      
      let loadedAttributesData: ProfileAttributesData[] = [];
      if (cached?.attributes) {
        loadedAttributesData = cached.attributes.map((e) => {
          const profileName = availableProfiles?.find(
            (p) => p.id === e.profileId
          )?.name;
          const unit = availableUnits?.find((u) => u.id === e.unitId)?.name;
          const inherited = e.profileId !== cached.profile.id;
          return new ProfileAttributesData(
            e.id,
            e.profileId,
            profileName!,
            e.name,
            e.type,
            e.unitId,
            unit!,
            inherited,
            e.mandatory,
            e.description
          );
        });
    }
    setAttributesData(sortAttributesData(loadedAttributesData));        
    }else {
      loadCompositionedProfileAttributes();
    }
  }, [availableProfiles, availableUnits, compositionedProfiles]);

  const columns = [
    {
      title: t("profiles.title.single"),
      dataIndex: "profileName",
      key: "profileName",
      width: "19%",
      render: (_: string, record: GroupedData | ProfileAttributesData) => {
        // Only display the group name for parent rows
        return isGrouped
          ? "children" in record
            ? record.profileName
            : null
          : record.profileName;
      }
    },
    {
      title: t("profile.attribute.name"),
      dataIndex: "name",
      key: "name",
      width: "19%",
    },
    {
      title: t("profile.attribute.description"),
      dataIndex: "description",
      key: "description",
      width: "19%",
    },
    {
      title: t("profile.attribute.dataType"),
      dataIndex: "dataType",
      key: "dataType",
      width: "19%",
    },
    {
      title: t("profile.attribute.uom"),
      dataIndex: "uom",
      key: "uom",
      width: "19%",
    },
    {
      key: "actions",
      width: "5%",
      render: (_: string, record: GroupedData | ProfileAttributesData) => {
        const isGroupingRow = isGrouped && "children" in record;
        const isCompositionedProfile = record.profileName != profileName && record.profileName != cached?.profile.name;
        return isGroupingRow ? null : 
          (<div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              style={{ border: 0 }}
              type="tertiary"
              onClick={() => {
                onOpenCreateAttributeDrawerHandler(
                  record as ProfileAttributesData
                );
              }}
              icon={
                <Icon name={isCompositionedProfile || viewOnly?"visibility":"edit"} />
              }
            />
          </div>)
      }
    },
  ];

  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [deletable, setDeletable] = useState(true);
  const showDrawer = () => {
    setEditable(true)
    setDeletable(false);
    setVisible(true);
  };
  const onClose = () => {
    setEditable(false);
    setVisible(false);
    setDeletable(true);
  };

  const [tempAttributeData, setTempAttributeData] =
    useState<ProfileAttributesData>();

  const onOpenCreateAttributeDrawerHandler = (row: ProfileAttributesData) => {
    setTempAttributeData(row);
    if (!viewOnly) {
      setEditable(!row.inherited);
      setDeletable(!row.inherited);
    }
    setVisible(true);
  };

  const onSaveAttributeData = (data: ProfileAttributesData) => {
    const newAttributeData = [...attributesData];
    const newCreatedAttributesData = [...createdAttributesData];
    let attributesDataIndex = newAttributeData.findIndex(
      (e) => e.id === data.id
    );
    if (attributesDataIndex > -1) {
      if (data.isNew) {
        const createdAttributesDataIndex = newCreatedAttributesData.findIndex(
          (e) => e.id === data.id
        );
        newCreatedAttributesData[createdAttributesDataIndex] =
          data as ProfileAttributesData;
      }
      newAttributeData[attributesDataIndex] = data as ProfileAttributesData;
    } else {
      if (cached?.profile) {
        data.profileId = cached.profile.id;
        data.profileName = cached.profile.name;
      }
      else{
        data.profileName = profileName;
      }
      if (availableUnits && data.uomId) {
        data.uom = availableUnits.find((e) => e.id === data.uomId)!.name;
      }
      data.isNew = true;

      setCreatedAttributesData([...newCreatedAttributesData, data]);
      newAttributeData.push(data);
    }

    setAttributesData(sortAttributesData(newAttributeData));
    setVisible(false);
  };

  interface GroupedData {
    key: string;
    profileName: string;
    children: ProfileAttributesData[];
  }

  const [isGrouped, setIsGrouped] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleCheckboxChange = (checked: boolean) => {
    setIsGrouped(checked);
    if (!checked) {
      // Reset expanded row keys when ungrouping
      setExpandedRowKeys([]);
    }
  };

  // Group the data by 'group'
  const [groupedData, setGroupedData] = useState<GroupedData[]>([]);

  useEffect(() => {
    const newGroupedData: GroupedData[] = Object.entries(
      attributesData.reduce<Record<string, ProfileAttributesData[]>>(
        (acc, item) => {
          if (!acc[item.profileName]) {
            acc[item.profileName] = [];
          }
          acc[item.profileName].push(item);
          return acc;
        },
        {}
      )
    ).map(([profileName, children]) => ({
      key: profileName,
      profileName,
      children,
    }));
    setGroupedData(newGroupedData);
  }, [attributesData]);

  const TableHeader = () => {
    return (
      <Row
        justify="space-between"
        align="middle"
      >
        <Col>
          <Row align="middle">
            <h1>{t("profile.attributes.title")}</h1>
            <Divider
              type="vertical"
              style={{
                width: "1px",
                height: "2rem",
                background: "#d9d9d9",
                margin: "0 5px 0 5px",
              }}
            />
            <Checkbox
              onChange={(e) => handleCheckboxChange(e.target.checked)}
              label={t("profile.groupBy")}
            />
          </Row>
        </Col>
        <Col>
          <Button
            hidden={viewOnly}
            onClick={showDrawer}
            type="secondary"
            title={t("button.create.attribute")}
          />
        </Col>
      </Row>
    );
  };

  const commonPaginationProps = {
    showTotal(total: number, range: [number, number]) {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    size: "small" as "small",
    defaultPageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
    defaultCurrent: 1,
    showSizeChanger: true,
  };

  const commonTableProps = {
    size: "small" as SizeType,
    title: () => TableHeader(),
    bordered: true,
    rowKey: "key",
  };

  function deleteAttribute(attributeId: number): void {
    const newAttributeData = [...attributesData];
    let attributesDataIndex = newAttributeData.findIndex(
      (e) => e.id === attributeId
    );
    if (attributesDataIndex > -1) {
      newAttributeData.splice(attributesDataIndex, 1);
    }
    setAttributesData(newAttributeData);
    setVisible(false);
  }

  return (
    <div className="form-container">
      <>
        <Form
          notifications={{ disabled: true }}
          submitCallback={() => {
            onSubmit(attributesData);
          }}
          id={`submit-outside${index}`}
          className="form"
        >
          <FormField name={"attributes"} className="profile-attributes">
            {isGrouped ? (
              <Table<GroupedData>
                {...commonTableProps}
                pagination={commonPaginationProps}
                columns={columns}
                dataSource={groupedData}
                expandable={{
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => {
                    setExpandedRowKeys((prevKeys) =>
                      expanded
                        ? [...prevKeys, record.key]
                        : prevKeys.filter((key) => key !== record.key)
                    );
                  },
                }}
              />
            ) : (
              <Table<ProfileAttributesData>
                {...commonTableProps}
                pagination={commonPaginationProps}
                dataSource={attributesData}
                columns={columns}
              />
            )}
          </FormField>
        </Form>
        <Drawer
          maskClosable={false}
          destroyOnClose
          onClose={onClose}
          afterVisibleChange={() =>
            visible ? null : setTempAttributeData(undefined)
          }
          visible={visible}
          title={t("profile.attribute.configuration")}
          icon={{ name: "edit" }}
          footer={
            <div
              style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}
            >
              <Button
                onClick={onClose}
                type="secondary"
                title={t("button.cancel")}
              />
              {editable ? (
                <Button
                  form={`submit-drawer`}
                  htmlType="submit"
                  title={t("button.save")}
                />
              ) : null}
            </div>
          }
        >
          <Form
            initialValues={tempAttributeData}
            notifications={{ disabled: true }}
            submitCallback={onSaveAttributeData}
            id={`submit-drawer`}
            className="form"
          >
            <FormField required name={"name"} label={t("profile.attribute.name")}>
              <TextInput disabled={!editable} />
            </FormField>
            <FormField name={"description"} label={t("profile.attribute.description")}>
              <TextArea rows={5} disabled={!editable} />
            </FormField>
            <FormField required name={"dataType"} label={t("profile.attribute.dataType")}>
              <Select
                options={Object.values(AttributeType).map((type) => ({
                  label: type,
                  value: type,
                }))}
                disabled={!editable}
              />
            </FormField>
            <FormField name={"uomId"} label={t("profile.attribute.uom")}>
              <Select
                options={availableUnits?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
                disabled={!editable}
              />
            </FormField>
            <FormField
              name={"mandatory"}
              label={t("profile.attribute.required")}
              valuePropName="checked"
            >
              <Switch disabled={!editable} />
            </FormField>
          </Form>
          {(editable&& deletable)  ? (
            <ConfirmModal
              valueToValidate={tempAttributeData?.name}
              okButtonType="danger"
              content={
                <Trans
                  i18nKey={"confirm.delete.description"}
                  values={{ itemName: tempAttributeData?.name }}
                />
              }
              title={t("confirm.delete.title")}
              okText={t("button.delete")}
              cancelText={t("button.cancel")}
              confirm={true}
              onOk={() => deleteAttribute(tempAttributeData?.id!)}
            >
              <Button
                type="secondary"
                style={{ float: "right" }}
                title={t("button.delete")}
                icon={<Icon name="delete_outline" />}
              />
            </ConfirmModal>
          ) : null}
        </Drawer>
      </>
    </div>
  );
}
export class ProfileAttributesData {
  id: number;
  profileId: number;
  profileName: string;
  name: string;
  description?: string;
  dataType: AttributeType;
  uomId: number;
  uom: string;
  mandatory: boolean;
  inherited: boolean;
  isNew: boolean;

  constructor(
    id: number,
    profileId: number,
    profileName: string,
    name: string,
    dataType: AttributeType,
    uomId: number,
    uom: string,
    inherited: boolean,
    mandatory: boolean,
    description?: string,
    isNew: boolean = false
  ) {
    this.id = id;
    this.profileId = profileId;
    this.profileName = profileName;
    this.name = name;
    this.description = description;
    this.dataType = dataType;
    this.uomId = uomId;
    this.uom = uom;
    this.mandatory = mandatory;
    this.inherited = inherited;
    this.isNew = isNew;
  }
}
