import { AttributeType } from "../enums/AttributeEnums";

export class AttributeDto {
    name: string;
    description?: string;
    type: AttributeType;
    unitId: number;
    mandatory: boolean;

    constructor(
        name: string,
        type: AttributeType,
        unitId: number,
        mandatory: boolean,
        description?: string,
    ) {
        this.name = name;
        this.type = type;
        this.unitId = unitId;
        this.mandatory = mandatory;
        this.description = description;
    }
}

export class AttributeCompleteDto extends AttributeDto {
    id: number;
    profileId: number;

    constructor(
        id: number,
        profileId: number,
        name: string,
        type: AttributeType,
        unitId: number,
        mandatory: boolean,
        description?: string,
    ) {
        super(name, type, unitId, mandatory, description);
        this.id = id;
        this.profileId = profileId;
    }
}
