import React, { memo } from "react";
import ThemeProvider from "@plasma/ui.themes.theme-provider";
import * as styled from "./layout.styles";
import { Outlet } from "@plasma/ui.application.router";
import Breadcrumbs from "../shared/Breadcrumbs/Breadcrumbs";
import GlobalStyle from '@plasma/ui.themes.global-style';

export default function Layout() {
  return (
    <ThemeProvider>
      <GlobalStyle />
      <styled.Layout
        className={`layout}`.trim()}
        backgroundType="lit"
        id="tsd"
        header={<Breadcrumbs />}
      >
        <Outlet />
      </styled.Layout>
    </ThemeProvider>
  );
}

