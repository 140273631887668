import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SystemCompleteDto, SystemDto } from '../models/dtos/system/SystemDtos';
import { ThingTypeCompleteDto, ThingTypeDto } from '../models/dtos/thing-type/ThingTypeDtos';
import { SystemAssociatedThingTypeDto } from '../models/dtos/system/SystemAssociatedThingTypeDto';
import { RelationTypeCompleteDto, RelationTypeDto } from '../models/dtos/relation-type/RelationTypeDtos';
import { SystemAssociatedRelationTypeDto } from '../models/dtos/system/SystemAssociatedRelationTypeDto';
import { SystemAssociatedProfileDto } from '../models/dtos/system/SystemAssociatedProfileDto';
import { FeatureCompleteDto } from '../models/dtos/FeatureDtos';
import { SystemAssociatedFeatureDto } from '../models/dtos/system/SystemAssociatedFeatureDto';
import { ThingTypeAssociatedFeatureDto } from '../models/dtos/thing-type/ThingTypeAssociatedFeatureDto';
import { ProfileComposition, ProfilePartDto } from '../models/dtos/profile/ProfileComposition';
import { ProfileBase, ProfileCompleteDto, ProfileDto } from '../models/dtos/profile/ProfileDtos';
import { UnitOfMeasurementCompleteDto } from '../models/dtos/UnitOfMeasurementDtos';
import { AttributeCompleteDto, AttributeDto } from '../models/dtos/AttributeDtos';
import { setupCache } from 'axios-cache-interceptor';
import keycloak from '../../keycloak';
import { Connector, DataProviderConnectorDto } from '../models/dtos/data-provider/ConnectorDto';
import { DataQueryType } from '../models/dtos/data-provider/DataQueryType';
import { DataProvider, DataProviderBase } from '../models/dtos/data-provider/DataProviderDto';
import { DataQueryBase } from '../models/dtos/data-provider/DataQueryDto';
import { RelationTypeConstraintBase, RelationTypeConstraintCompleteDto, RelationTypeConstraintDto } from '../models/dtos/relation-type/RelationTypeConstraintDtos';
import { SystemAssociatedDataProviderDto } from '../models/dtos/system/SystemAssociatedDataProviderDto';
import { IdentifierRequirementBase, IdentifierRequirementDto } from '../models/dtos/system/IdentifierRequirementDto';
import { MetricCompleteDto, MetricDto } from '../models/dtos/MetricDto';

//@ts-ignore
const baseURL = window.env.BACKEND_URL;
//create axios instance with 250ms caching
const axiosInstance =setupCache(axios.create({
    baseURL: baseURL
  }),{ttl:250});


axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
      config.headers['x-api-version'] = apiVersion
      return config;
    },
    (error) => {
      // Handle the error
      console.error('Request Interceptor Error:', error);
      return Promise.reject(error);
    }
  );



const apiVersion = '1.0';

const systems = {

    // Systems
    getAll: () => axiosInstance.get<SystemCompleteDto[]>(
        '/systems'
    ),
    getById: (id: number) => axiosInstance.get<SystemCompleteDto>(`systems/${id}`),
    create: (data: SystemDto) => axiosInstance.post<SystemCompleteDto>(
        '/systems', 
        data
    ),
    update: (id: number, data: SystemDto) => axiosInstance.put<SystemCompleteDto>(
        `/systems/${id}`, 
        data
    ),
    delete: (id: number) => axiosInstance.delete(`systems/${id}`),

    // System thing-types
    getThingTypes: (id: number) => axiosInstance.get<ThingTypeCompleteDto[]>(`systems/${id}/thing-types`),
    addThingType: (id: number, data: SystemAssociatedThingTypeDto) => axiosInstance.post<ThingTypeCompleteDto>(`systems/${id}/thing-types`, data),
    removeThingType: (id: number, thingTypeId: number) => axiosInstance.delete(`systems/${id}/thing-types/${thingTypeId}`),

    // System relation types
    getRelationTypes: (id: number) => axiosInstance.get<RelationTypeCompleteDto[]>(`systems/${id}/relation-types`),
    addRelationType: (id: number, data: SystemAssociatedRelationTypeDto) => axiosInstance.post<RelationTypeCompleteDto>(`systems/${id}/relation-types`, data),
    removeRelationType: (id: number, relationTypeId: number) => axiosInstance.delete(`systems/${id}/relation-types/${relationTypeId}`),

    // System profiles
    getProfileCompositions: (id: number) => axiosInstance.get<ProfilePartDto[]>(`systems/${id}/profiles`),
    addProfileCompositions: (id: number, data: SystemAssociatedProfileDto[]) => axiosInstance.post<ProfilePartDto[]>(`systems/${id}/profiles`, data),

    // System features
    getFeatures: (id: number) => axiosInstance.get<FeatureCompleteDto[]>(`systems/${id}/features`),
    addFeature: (id: number, data: SystemAssociatedFeatureDto) => axiosInstance.post<FeatureCompleteDto>(`systems/${id}/features`, data),
    removeFeature: (id: number, featureId: number) => axiosInstance.delete(`systems/${id}/features/${featureId}`),

    // System data providers
    getDataProviders: (id: number) => axiosInstance.get<DataProvider[]>(`systems/${id}/dataproviders`),
    addDataProvider: (id: number, data: SystemAssociatedDataProviderDto) => axiosInstance.post<DataProvider>(`systems/${id}/dataproviders`, data),
    removeDataProvider: (id: number, dataProviderId: number) => axiosInstance.delete(`systems/${id}/dataproviders/${dataProviderId}`),

    // System identifiers
    getIdentifiers: (systemId: number) => axiosInstance.get<IdentifierRequirementDto[]>(`systems/${systemId}/identifiers`),
    addIdentifier: (systemId: number, data: IdentifierRequirementBase) => axiosInstance.post<IdentifierRequirementDto>(`systems/${systemId}/identifiers`, data),
    updateIdentifierById: (systemId: number, identifierRequirementId : number, data: IdentifierRequirementBase)=>axiosInstance.put(`systems/${systemId}/identifiers/${identifierRequirementId}`, data),
    removeIdentifier: (systemId: number, identifierRequirementId : number) => axiosInstance.delete(`systems/${systemId}/identifiers/${identifierRequirementId }`),
}

const thingTypes = {

    // ThingTypes
    getAll: () => axiosInstance.get<ThingTypeCompleteDto[]>(
        '/thing-types'
    ),
    getById: (id: number) => axiosInstance.get<ThingTypeCompleteDto>(
        `/thing-types/${id}`
    ),
    create: (data: ThingTypeDto) => axiosInstance.post<ThingTypeCompleteDto>(
        '/thing-types', 
        data
    ),
    update: (id: number, data: ThingTypeDto) => axiosInstance.put<ThingTypeCompleteDto>(
        `/thing-types/${id}`, 
        data
    ),
    delete: (id: number) => axiosInstance.delete(`thing-types/${id}`),

    // ThingType features
    getFeatures: (id: number) => axiosInstance.get<FeatureCompleteDto[]>(`thing-types/${id}/features`),
    addFeature: (id: number, data: ThingTypeAssociatedFeatureDto) => axiosInstance.post<FeatureCompleteDto>(`thing-types/${id}/features`, data),
    removeFeature: (id: number, featureId: number) => axiosInstance.delete(`thing-types/${id}/features/${featureId}`),
}

const relationTypes = {
    // Relation types
    getAll: () => axiosInstance.get<RelationTypeCompleteDto[]>(
        '/relation-types'
    ),
    getById: (id: number) => axiosInstance.get<RelationTypeCompleteDto>(
        `/relation-types/${id}`
    ),
    create: (data: RelationTypeDto) => axiosInstance.post<RelationTypeCompleteDto>(
        '/relation-types', 
        data
    ),
    update: (id: number, data: RelationTypeDto) => axiosInstance.put<RelationTypeCompleteDto>(
        `/relation-types/${id}`, 
        data
    ),
    delete: (id: number) => axiosInstance.delete(`relation-types/${id}`),

    // Relation type constraints

    getAllConstraints: (relationTypeId: number) => axiosInstance.get<RelationTypeConstraintCompleteDto[]>(`/relation-types/${relationTypeId}/constraints`),
    getConstraintById: (relationTypeId: number, constraintId: number) => axiosInstance.get<RelationTypeConstraintCompleteDto>(`/relation-types/${relationTypeId}/constraints/${constraintId}`),
    createConstraint: (relationTypeId: number, data:RelationTypeConstraintBase[]) => axiosInstance.post<RelationTypeConstraintCompleteDto[]>(`/relation-types/${relationTypeId}/constraints`, data),
    deleteConstraintById: (relationTypeId: number, constraintId: number) => axiosInstance.delete<RelationTypeConstraintCompleteDto>(`/relation-types/${relationTypeId}/constraints/${constraintId}`),
}

const profiles = {

    // Profiles
    getAll: () => axiosInstance.get<ProfileCompleteDto[]>(
        '/profiles'
    ),
    getById: (id: number) => axiosInstance.get<ProfileCompleteDto>(
        `/profiles/${id}`
    ),
    create: (data: ProfileBase) => axiosInstance.post<ProfileCompleteDto>(
        '/profiles', 
        data
    ),
    update: (id: number, data: ProfileBase) => axiosInstance.put<ProfileCompleteDto>(
        `/profiles/${id}`, 
        data
    ),
    delete: (id: number) => axiosInstance.delete(`profiles/${id}`),

    // Profile compositions
    getCompositions: (id: number) => axiosInstance.get<ProfilePartDto[]>(`profiles/${id}/sub-profiles`),
    addCompositions: (id: number, data: ProfileComposition[]) => axiosInstance.post<ProfilePartDto[]>(`profiles/${id}/sub-profiles`, data),

    // Profile attributes
    getAllAttributes: (profileId: number) => axiosInstance.get<AttributeCompleteDto[]>(`profiles/${profileId}/features/attributes`),
    getAttributeById: (profileId: number, attributeId: number) => axiosInstance.get<AttributeCompleteDto>(`profiles/${profileId}/features/attributes/${attributeId}`),
    createAttribute: (profileId: number, data: AttributeDto) => axiosInstance.post<AttributeCompleteDto>(`profiles/${profileId}/features/attributes`, data),
    updateAttribute: (profileId: number, attributeId: number, data: AttributeDto) => axiosInstance.put<AttributeCompleteDto>(`profiles/${profileId}/features/attributes/${attributeId}`, data),
    deleteAttribute: (profileId: number, attributeId: number) => axiosInstance.delete(`profiles/${profileId}/features/attributes/${attributeId}`),

    // Profile metrics
    getAllMetrics: (profileId: number) => axiosInstance.get<MetricCompleteDto[]>(`profiles/${profileId}/features/metrics`),
    getMetricById: (profileId: number, metricId: number) => axiosInstance.get<MetricCompleteDto>(`profiles/${profileId}/features/metrics/${metricId}`),
    createMetric: (profileId: number, data: MetricDto) => axiosInstance.post<MetricCompleteDto>(`profiles/${profileId}/features/metrics`, data),
    updateMetric: (profileId: number, metricId: number, data: MetricDto) => axiosInstance.put<MetricCompleteDto>(`profiles/${profileId}/features/metrics/${metricId}`, data),
    deleteMetric: (profileId: number, metricId: number) => axiosInstance.delete(`profiles/${profileId}/features/metrics/${metricId}`),
}

const features = {
    getAll: () => axiosInstance.get<FeatureCompleteDto[]>(
        '/features'
    ),
}

const unitsOfMeasurement = {
    getAll: () => axiosInstance.get<UnitOfMeasurementCompleteDto[]>(
        '/uom'
    ),
    getById: (id: number) => axiosInstance.get<UnitOfMeasurementCompleteDto>(
        `/uom/${id}`
    ),
}

const connectors = {
    getAll:() => axiosInstance.get<Connector[]>("/connectors")
}

const dataProviders = {
getAll:()=>axiosInstance.get<DataProvider[]>("/data-providers"),
getById:(dataProviderId:number)=>axiosInstance.get<DataProvider>(`/data-providers/${dataProviderId}`),
deleteById:(dataProviderId:number)=>axiosInstance.delete<DataProvider>(`/data-providers/${dataProviderId}`),
getTypes:() => axiosInstance.get<{id:number,name:string}[]>("/data-providers/types"),
updateById:(dataProviderId:number, data:DataProviderBase)=>axiosInstance.put<DataProvider>(`/data-providers/${dataProviderId}`,data),
createDataProvider:(data:DataProviderBase)=>axiosInstance.post("/data-providers",data),

createConnection:(dataProviderId:number,data:DataProviderConnectorDto)=>axiosInstance.post(`/data-providers/${dataProviderId}/connection`,data),
getConnection:(dataProviderId:number)=>axiosInstance.get<DataProviderConnectorDto>(`data-providers/${dataProviderId}/connection`),
updateConnection:(dataProviderId:number, data:DataProviderConnectorDto)=>axiosInstance.put(`/data-providers/${dataProviderId}/connection`,data),
createDataQuery:(dataProviderId:number,data:DataQueryBase)=>axiosInstance.post(`/data-providers/${dataProviderId}/data-queries`,data),
getDataQueries:(dataProviderId:number)=>axiosInstance.get<DataQueryBase[]>(`/data-providers/${dataProviderId}/data-queries`),
updateDataQuery:(dataProviderId:number, dataQueryId:number, data:DataQueryBase)=>axiosInstance.put(`/data-providers/${dataProviderId}/data-queries/${dataQueryId}`,data),
};

const dataQueryType = {
    getAll:()=>axiosInstance.get<DataQueryType[]>("/data-query-type")
}

const services = { systems, thingTypes, relationTypes, profiles, features, unitsOfMeasurement, dataProviders, connectors, dataQueryType };
export { axiosInstance };
export default services;


