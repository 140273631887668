import React, { useEffect, useState } from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { Trans, useTranslation } from "react-i18next";
import { queryClient } from "../../../../stores/QueryClient";
import { ProfileCompleteDto } from "../../../../models/dtos/profile/ProfileDtos";
import { useQuery } from "@tanstack/react-query";
import { Icon } from "@plasma/ui.general.icon";
import services from "../../../../api/agent";
import { MetricCompleteDto, SamplingInterval } from "../../../../models/dtos/MetricDto";
import Button from "@plasma/ui.general.button";
import TextInput from '@plasma/ui.input.text-input'
import TextArea from "@plasma/ui.input.text-area";
import Drawer from '@plasma/ui.layout.drawer';
import Select from "@plasma/ui.input.select";
import Checkbox from "@plasma/ui.input.checkbox"
import { Col, Divider, Row, Table } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";

export default function ProfileMetricForm({
  index,
  onSubmit,
  viewOnly,
  profileName,
  compositionedProfiles,
}: {
  index: number;
  onSubmit: (value: any) => void;
  viewOnly: boolean;
  profileName: string;
  compositionedProfiles: string[] | undefined;
}) {
  const { t } = useTranslation();

  const { data: availableProfiles } = useQuery(["availableProfiles"], () =>
    services.profiles.getAll().then((r) => r.data));

  const { data: availableUnits } = useQuery(["availableUoMs"], () =>
    services.unitsOfMeasurement.getAll().then((r) => r.data));

  const cached = queryClient.getQueryData<{ profile: ProfileCompleteDto, metrics: MetricCompleteDto[] }>([
    "selectedProfile",
  ]);

  const [metricsData, setMetricsData] = useState<ProfileMetricsData[]>([]);

  const [createdMetricsData, setCreatedMetricsData] = useState<
      ProfileMetricsData[]
    >([]);

  
  const sortMetricsData = (data: ProfileMetricsData[]) => {
    return data.sort((a, b) => {
      if (a.profileName === profileName && b.profileName !== profileName) {
        return -1;
      }
      if (a.profileName !== profileName && b.profileName === profileName) {
        return 1;
      }
      if (a.profileName === profileName && b.profileName === profileName) {
        if (a.isNew && !b.isNew) {
          return -1;
        }
        if (!a.isNew && b.isNew) {
          return 1;
        }
      }
      return a.profileName.localeCompare(b.profileName);
    });
  };

  const loadProfileSpecificMetrics = () => {
    if (!cached?.metrics) return [];
    return cached?.metrics
      .filter(
        (e) => e.profileId === cached?.profile.id || e.profileId === undefined
      )
      .map((e) => {
        const unit = availableUnits?.find((u) => u.id === e.unitId)?.name;
        return new ProfileMetricsData(
          e.id,
          e.profileId,
          cached.profile.name,
          e.name,
          e.unitId,
          unit!,
          false,
          e.sampling,
          e.description
        );
      });
  };

  const loadCompositionedProfileMetrics = async () => {
    let loadedCompositionedMetricsData: ProfileMetricsData[] = [];
    if (compositionedProfiles) {
      loadedCompositionedMetricsData = (
        await Promise.all(
          compositionedProfiles!.map(async (profileId) => {
            const profile = availableProfiles?.find(
              (p) => p.id === Number(profileId)
            );
            const profileMetrics = await services.profiles
              .getAllMetrics(Number(profileId))
              .then((r) => {
                return r.data;
              });
            const inherited = profile?.id !== cached?.profile.id;
            const metricsData = profileMetrics.map((e) => {
              const unit = availableUnits?.find(
                (u) => u.id === e.unitId
              )?.name;
              return new ProfileMetricsData(
                e.id,
                e.profileId,
                profile?.name!,
                e.name,
                e.unitId,
                unit!,
                inherited,
                e.sampling,
                e.description
              );
            });
            return metricsData;
          })
        )
      ).flat();
    }
    const profileSpecificMetricsData = loadProfileSpecificMetrics();
    setMetricsData(sortMetricsData([
      ...createdMetricsData,
      ...loadedCompositionedMetricsData,
      ...profileSpecificMetricsData!,
    ]));
  };

  useEffect(() => {    

    if (viewOnly) {
      let loadedMetricsData: ProfileMetricsData[] = [];
      if (cached?.metrics) {
        loadedMetricsData = cached.metrics.map((e) => {
          const profileName = availableProfiles?.find(
            (p) => p.id === e.profileId
          )?.name;
          const unit = availableUnits?.find((u) => u.id === e.unitId)?.name;
          const inherited = e.profileId !== cached.profile.id;
          return new ProfileMetricsData(
            e.id,
            e.profileId,
            profileName!,
            e.name,
            e.unitId,
            unit!,
            inherited,
            e.sampling,
            e.description
          );
        });
      }
      setMetricsData(sortMetricsData(loadedMetricsData));
    } else {
      loadCompositionedProfileMetrics();
    }
  }, [availableProfiles, availableUnits, compositionedProfiles]);

  const columns = [
    {
      title: t("profiles.title.single"),
      dataIndex: 'profileName',
      key: 'profileName',
      width: "23.5%",
      render: (_: string, record: GroupedData | ProfileMetricsData) => {
        // Only display the group name for parent rows
        return isGrouped ? ('children' in record ? record.profileName : null) : record.profileName;
      },
    },
    {
      title:t("profile.metric.name"),
      dataIndex: "name",
      key: "name",
      width: "23.5%",
    },
    {
      title:t("profile.metric.description"),
      dataIndex: "description",
      key: "description",
      width: "23.5%",
    },
    {
      title:t("profile.metric.uom"),
      dataIndex: "uom",
      key: "uom",
      width: "23.5%",
    },
    {
      key:"actions",
      width:"5%",
      render: (_: string, record: GroupedData | ProfileMetricsData) => {
        const isGroupingRow = isGrouped && 'children' in record
        const isCompositionedProfile = record.profileName != profileName && record.profileName != cached?.profile.name;
        
        return isGroupingRow? null :
        (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            style={{ border: 0 }}
            type="tertiary"
            onClick={() => {onOpenCreateMetricDrawerHandler(record as ProfileMetricsData)}}
            icon={
              <Icon name={isCompositionedProfile || viewOnly?"visibility":"edit"} />
            }
          />
        </div>)
      }
    }
  ]

  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [deletable, setDeletable] = useState(true);
  const showDrawer = () => {
    setEditable(true)
    setDeletable(false);
    setVisible(true);
  };
  const onClose = () => {
    setEditable(false);
    setVisible(false);
    setDeletable(true);
  };

  const [tempMetricData, setTempMetricData] = useState<ProfileMetricsData>()

  const onOpenCreateMetricDrawerHandler = (row: ProfileMetricsData) => {
    setTempMetricData(row)
    if (!viewOnly) {
      setEditable(!row.inherited);
      setDeletable(!row.inherited);
    }
    setVisible(true);
  }

  const onSaveMetricData = (data:ProfileMetricsData) => {
    const newMetricData = [...metricsData]
    const newCreatedMetricsData = [...createdMetricsData];
    let changedMetricIndex = newMetricData.findIndex(e => e.id===data.id)
    if (changedMetricIndex>-1)
    {
      if (data.isNew) {
              const createdMetricsDataIndex = newCreatedMetricsData.findIndex(
                (e) => e.id === data.id
              );
              newCreatedMetricsData[createdMetricsDataIndex] =
                data as ProfileMetricsData;
            }
      newMetricData[changedMetricIndex] = data as ProfileMetricsData
    }      
    else
    {
      if (cached?.profile) {
        data.profileId = cached.profile.id;
        data.profileName= cached.profile.name;
      }
      else{
        data.profileName = profileName;
      }
      if (availableUnits && data.uomId)
      {
        data.uom = availableUnits.find(e => e.id === data.uomId)!.name
      }
      data.isNew = true;

      setCreatedMetricsData([...newCreatedMetricsData, data]);
      newMetricData.push(data)
    }      

    setMetricsData(sortMetricsData(newMetricData))
    setVisible(false)
  }

  interface GroupedData {
    key: string;
    profileName: string;
    children: ProfileMetricsData[];
  }

  const [isGrouped, setIsGrouped] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleCheckboxChange = (checked:boolean) => {
    setIsGrouped(checked);
    if (!checked) {
      // Reset expanded row keys when ungrouping
      setExpandedRowKeys([]);
    }
  };

  // Group the data by 'group'
  const [groupedData, setGroupedData] = useState<GroupedData[]>([]);

  useEffect(() => {
    const newGroupedData: GroupedData[] = Object.entries(
      metricsData.reduce<Record<string, ProfileMetricsData[]>>((acc, item) => {
        if (!acc[item.profileName]) {
          acc[item.profileName] = [];
        }
        acc[item.profileName].push(item);
        return acc;
      }, {})
    ).map(([profileName, children]) => ({
      key: profileName,
      profileName,
      children,
    }));
    setGroupedData(newGroupedData);
  }, [metricsData]);

  const TableHeader = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle">
            <h1>{t("profile.metric.title")}</h1>
            <Divider type="vertical" style={{
              width: "1px",
              height: "2rem",
              background: "#d9d9d9",
              margin: "0 5px 0 5px",
            }}
              />
            <Checkbox onChange={(e) => handleCheckboxChange(e.target.checked)} label={t("profile.groupBy")}/>
          </Row>
        </Col>
        <Col>
          <Button
            hidden={viewOnly}
                onClick={showDrawer}
                type="secondary"
                title={t("button.create.metric")}
              />
        </Col>
      </Row>)
  }

  const commonPaginationProps = {
    showTotal(total: number, range:[number,number]) {
      return `${range[0]}-${range[1]} of ${total} items`
  },
  size:"small" as "small",
  defaultPageSize:25,
  pageSizeOptions:[10,25,50,100],
  defaultCurrent:1,
  showSizeChanger:true
  }

  const commonTableProps = {
    size:'small' as SizeType,
    title:() => TableHeader(),
    bordered:true,
    rowKey:"key"
  }
  

  function deleteMetric(metricId: number): void {
    const newMetricData = [...metricsData];
    let metricsDataIndex = newMetricData.findIndex(
      (e) => e.id === metricId
    );
    if (metricsDataIndex > -1) {
      newMetricData.splice(metricsDataIndex, 1);
    }
    setMetricsData(newMetricData);
    setVisible(false);
  }

  return (
    <div className="form-container">
        <>          
          <Form
            notifications={{ disabled: true }}
            submitCallback={() => {
              onSubmit(metricsData);
            }}
            id={`submit-outside${index}`}
            className="form"
          >
            <FormField name={"metrics"} className="profile-metrics">
            {isGrouped ? (
            <Table<GroupedData>
              {...commonTableProps}
              pagination={commonPaginationProps}
              columns={columns}
              dataSource={groupedData}          
              expandable={{
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => {
                  setExpandedRowKeys((prevKeys) =>
                    expanded ? [...prevKeys, record.key] : prevKeys.filter((key) => key !== record.key)
                  );
                },
              }}
              rowKey={(record) => record.key}
            />
          ) : (
            <Table<ProfileMetricsData>
              {...commonTableProps}
              pagination={commonPaginationProps}
              dataSource={metricsData}
              columns={columns}
              rowKey={(record) => record.id}
            />
          )}
            </FormField>
          </Form>
          <Drawer
        maskClosable={false}
        destroyOnClose
        onClose={onClose}
        afterVisibleChange={() =>visible?null:setTempMetricData(undefined)}
        visible={visible}
        title={t("profile.metric.configuration")}
        icon={{ name: "edit" }}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
            <Button
              onClick={onClose}
              type="secondary"
              title={t("button.cancel")}
            />
            {editable ? (
              <Button form={`submit-drawer-metric`} htmlType="submit" title={t("button.save")} />
            ) : null}
          </div>
        }
      >
        <Form
          initialValues={tempMetricData}
          notifications={{ disabled: true }}
          submitCallback={onSaveMetricData}
          id={`submit-drawer-metric`}
          className="form"
        >
          <FormField required name={"name"} label={t("profile.metric.name")}>
            <TextInput disabled={!editable}/>
          </FormField>
          <FormField name={"description"} label={t("profile.metric.description")}>
            <TextArea rows={5} disabled={!editable}/>
          </FormField>
          <FormField name={"uomId"} label={t("profile.metric.uom")}>
            <Select 
            options={availableUnits?.map((e) => ({ value: e.id, label: e.name}))}
              disabled={!editable}/>
          </FormField>
          <FormField name={"sampling"} label={t("profile.metric.sampling")}>
          <Select 
            options={Object.values(SamplingInterval).map((interval) => ({
              label: interval,  
              value: interval, 
              }))} 
              disabled={!editable}/>
          </FormField>
        </Form>
        {(editable&& deletable) ? (
            <ConfirmModal
              valueToValidate={tempMetricData?.name}
              okButtonType="danger"
              content={
                <Trans
                  i18nKey={"confirm.delete.description"}
                  values={{ itemName: tempMetricData?.name }}
                />
              }
              title={t("confirm.delete.title")}
              okText={t("button.delete")}
              cancelText={t("button.cancel")}
              confirm={true}
              onOk={() => deleteMetric(tempMetricData?.id!)}
            >
              <Button
                type="secondary"
                style={{ float: "right" }}
                title={t("button.delete")}
                icon={<Icon name="delete_outline" />}
              />
            </ConfirmModal>
          ) : null}        
      </Drawer>
      </>
    </div>
  );
}


export class ProfileMetricsData {
  id: number;
  profileId: number;
  profileName: string;
  name: string;
  description?: string;
  uomId: number;
  uom: string;
  sampling: SamplingInterval;
  inherited: boolean;
  isNew: boolean;

  constructor(id: number, profileId: number, profileName: string, name: string, uomId: number, uom: string, 
    inherited: boolean, sampling: SamplingInterval, description?: string,isNew:boolean=false) {
      this.id = id;
      this.profileId = profileId;
      this.profileName = profileName;
      this.name = name;
      this.description = description;
      this.uomId = uomId;
      this.uom = uom;
      this.sampling = sampling;
      this.inherited = inherited;
      this.isNew=isNew;
  }
}