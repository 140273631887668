import React, { useEffect, useState } from "react";
import services from "../../../../api/agent";
import { notification as notificationant } from "antd";
import { Trans, useTranslation } from "react-i18next";
import ThingTypeFeaturesForm from "./ThingTypeFeaturesForm";
import ThingTypeGeneralForm from "./ThingTypeGeneralForm";
import { ThingTypeDto } from "../../../../models/dtos/thing-type/ThingTypeDtos";
import { ThingTypeAssociatedFeatureDto } from "../../../../models/dtos/thing-type/ThingTypeAssociatedFeatureDto";
import {
    useLoaderData,
    useNavigate,
    useParams
} from "@plasma/ui.application.router";
import { queryClient } from "../../../../stores/QueryClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStore } from "../../../../stores/store";
import { Icon } from "@plasma/ui.general.icon";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";
import { AxiosError } from "axios";

function useCreateThingTypeHook() {
    const loaderData = useLoaderData() as { type: "create" | "edit" | "view" };
    const actionType = loaderData.type;
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const deleteHandler = async (name:string|undefined) => {
        if (id) await services.thingTypes.delete(parseInt(id));
        return name;
    };

    const { mutate: deleteThingType, isLoading: isDeleting } = useMutation({
        mutationFn: deleteHandler,
        onSuccess: (name) => {
              notificationant.success({
                message: t("notification.success.title"),
                description: (
                  <Trans
                    i18nKey={"notification.delete.success.description"}
                    values={{
                      itemType: t("thing-types.title.single"),
                      itemName: name,
                    }}
                  />
                ),
              });
              closeHandler();
            },
            onError: (error) => {
              notificationant.error({
                message: t("notification.delete.error.title"),
                description: (
                  <Trans
                    i18nKey={"notification.delete.error.description"}
                    values={{ itemType: t("thing-types.title.single"), itemName: "" }}
                  />
                ),
              });
            },
    });
    const { data: selectedThingType } = useQuery(
        ["selectedThingType"],
        () =>
            Promise.all([
                services.thingTypes.getById(parseInt(id!)),
                services.thingTypes.getFeatures(parseInt(id!)),
            ]).then((r) => {
                return { thingType: r[0].data, associatedFeatures: r[1].data };
            }),
        { enabled: !!id, refetchOnWindowFocus: false }
    );
    const { ActionStore } = useStore();

    useEffect(() => {
        if (loaderData.type === "edit" || loaderData.type === "view") {
            ActionStore.setActions(
                <>
                    {actionType === "view" ? (
                        <Button
                            title="Edit"
                            onClick={() =>
                                navigate(`/configuration/thing-types/edit/${id}`)
                            }
                        />
                    ) : null}
                    <ConfirmModal
                        valueToValidate={selectedThingType?.thingType.name}
                        okButtonType="danger"
                        content={
                            <Trans
                                i18nKey={"confirm.delete.description"}
                                values={{ itemName: selectedThingType?.thingType.name }}
                            />
                        }
                        title={t("confirm.delete.title")}
                        okText={t("button.delete")}
                        cancelText={t("button.cancel")}
                        confirm={true}
                        onOk={() => deleteThingType(selectedThingType?.thingType.name)}
                    >
                        <Button
                            loading={isDeleting}
                            type="secondary"
                            title={t("button.delete")}
                            icon={<Icon name="delete_outline" />}
                        />
                    </ConfirmModal>
                </>
            );
        }
    }, [i18n.language, isDeleting, selectedThingType, actionType]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries(["selectedThingType"]);
            ActionStore.clearNodes();
        };
    }, [])


    const [currentStep, setCurrentStep] = useState(0);
    const [isSaving, setIsSaving] = useState(false)
    const [generalData, setGeneralData] = useState<{
        name: string;
        description?: string;
    }>();
    const [featuresData, setFeaturesData] = useState<number[]>();

    const navigate = useNavigate();
    const nextStepHandler = () => {
        setCurrentStep(currentStep + 1);
    };


    const saveHandler = async () => {
    
        if (actionType === "create")
        {
            if (generalData && featuresData) {
                
                let thingTypeId:number
                try {
                    setIsSaving(true)
                    const thingTypeBase = new ThingTypeDto(
                        generalData.name,
                        generalData.description
                    );
                    try {
                        thingTypeId = (await services.thingTypes.create(thingTypeBase)).data.id;                        
                    }
                    catch (e){
                        const errorMessage = t("notification.create.error.description", {
                            itemType: t("thing-types.title.single"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
                        notificationant.error({
                            message: t("notification.create.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        return;
                    }
                    
                    featuresData.forEach((featureId) => {
                        try {
                            
                            if (selectedThingType?.associatedFeatures.map((e)=>e.id).includes(featureId)) {
                                return
                            }
                            services.thingTypes.addFeature(thingTypeId, new ThingTypeAssociatedFeatureDto(featureId));
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("features.associated"),
                                itemName: "",
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            return;
                        }
                    });

                    notificationant.success({
                        message: t("notification.success.title"),
                        description: (
                            <Trans
                                i18nKey={"notification.create.success.description"}
                                values={{ itemType: t("thing-types.title"), itemName: generalData.name }}
                            />
                        ),
                    });
                    navigate('/configuration/thing-types')
                }
                catch (e) {
                    try {
                        await services.thingTypes.delete(thingTypeId!);
                    }
                    catch { }
                }
                finally {
                    setIsSaving(false)
                }
            }
        }
        else{
            let thingTypeId:number = selectedThingType!.thingType.id
            try {
                setIsSaving(true)
                if (generalData) {
                    const thingTypeBase = new ThingTypeDto(
                        generalData.name,
                        generalData.description
                    );

                    try {
                        await services.thingTypes.update(thingTypeId, thingTypeBase);                    
                    }
                    catch (e:any){
                        const errorMessage = t("notification.update.error.description", {
                            itemType: t("thing-types.title.single"),
                            itemName: "",
                            error: e instanceof AxiosError ? e.response?.data : ""
                        });
            
                        notificationant.error({
                            message: t("notification.update.error.title"),
                            description: (
                                <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                            ),
                        });
                        return;
                    }         
                }
                
                if (featuresData) {
                    const featuresToDelete = selectedThingType?.associatedFeatures.filter(
                            e => !featuresData.includes(e.id)
                        )
                    featuresToDelete?.forEach((feature) =>{
                        try {
                            services.thingTypes.removeFeature(thingTypeId,feature.id);
                        }
                        catch (e){
                            const errorMessage = t("notification.delete.error.description", {
                                itemType: t("features.associated"),
                                itemName: feature.name,
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.delete.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e
                        }
                    })

                    
                
                    featuresData.forEach((featureId) => {
                        try {
                            
                            if (selectedThingType?.associatedFeatures.map((e)=>e.id).includes(featureId)) {
                                return
                            }
                            services.thingTypes.addFeature(thingTypeId, new ThingTypeAssociatedFeatureDto(featureId));
                        }
                        catch (e){
                            const errorMessage = t("notification.create.error.description", {
                                itemType: t("features.associated"),
                                itemName: "",
                                error: e instanceof AxiosError ? e.response?.data : ""
                            });
                            notificationant.error({
                                message: t("notification.create.error.title"),
                                description: (
                                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                ),
                            });
                            throw e
                        }
                    });

                }

                
                notificationant.success({
                    message: t("notification.success.title"),
                    description: (
                        <Trans
                            i18nKey={"notification.create.success.description"}
                            values={{ itemType: t("thing-types.title"), itemName: generalData?generalData.name:selectedThingType?.thingType.name }}
                        />
                    ),
                });
                navigate('/configuration/thing-types')
            }
            catch (e) {
            }
            finally {
                setIsSaving(false)
            }
        }
    };

    const steps = [
        {
            title: "thing-types.step.general.title",
            icon: "sell",
            description: "thing-types.step.general.description",
            element: (
                <ThingTypeGeneralForm
                    onSubmit={nextStepHandler}
                    index={0}
                    onChange={setGeneralData}
                    viewOnly={actionType === "view"}
                />
            )
        },
        {
            title: "thing-types.step.features.title",
            icon: "category",
            description: "thing-types.step.features.description",
            element: (
                <ThingTypeFeaturesForm
                    index={1}
                    key={1}
                    onSubmit={saveHandler}
                    onChange={(x) => setFeaturesData(x['features'])}
                    viewOnly={actionType === "view"}
                />
            ),
        },
    ];

    const prevStepHandler = () => {
        setCurrentStep(currentStep - 1);
    };
    const closeHandler = () => {
        navigate("/configuration/thing-types");
    };

    return {
        currentStep,
        steps,
        nextStepHandler,
        closeHandler,
        prevStepHandler,
        setCurrentStep,
        isSaving,
        actionType
    };
}

export default useCreateThingTypeHook;
