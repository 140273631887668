import React, { useEffect } from "react";

import ThemeProvider from "@plasma/ui.themes.theme-provider";
import { ThemeOverride } from "@plasma/ui.utils.types";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import themes from "../../../themes";
import { MainContainer } from "./profiles.styles";
import SmsSpin from "../../../components/core/SmsSpin/SmsSpin";
import { useStore } from "../../../stores/store";
import useProfilesHook, { ProfileOverviewData } from "./profiles.hook";
import TitlePage from "../../../components/shared/TitlePage";


import Button from '@plasma/ui.general.button';

import { Icon } from "@plasma/ui.general.icon";
import { useNavigate } from "@plasma/ui.application.router";

import Badge from '@plasma/ui.display.badge'
import { ProfileStatus } from "../../../models/enums/ProfileEnums";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";

function Profiles(props: Readonly<ThemeOverride>) {
    const { size, themeMode } = props;
    const { i18n, t } = useTranslation();
    const {profileOverviewData, isLoading} = useProfilesHook();
    const { ActionStore } = useStore();
    const navigate = useNavigate()
    useEffect(() => {
        ActionStore.setActions(<Button  title={t("button.create.profile")} icon={<Icon name="add"/>} onClick={()=>navigate("create")} />);
        return(()=>{
          ActionStore.clearNodes()
        })
      }, [i18n.language]);

    const columns:ColumnsType<ProfileOverviewData> = [
        {
            title:"Name",
            dataIndex: "name",
            key: "name",
            width: "77.5%",
            sortOrder:"ascend"
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',            
            render: (value:ProfileStatus, _:ProfileOverviewData) => {
                if (value === ProfileStatus.Active) {
                    return <Badge count="Active" type="success" />
                }
                if (value === ProfileStatus.Inactive) {
                    return <Badge count="Active" type="warning" />
                }
            },
            width: "11.25%",
        },
        {
            title:"Thing Type",
            dataIndex: "thingType",
            key: "thingType",
            width: "11.25%",
            sorter: (a,b) => a.thingType.localeCompare(b.thingType),
            sortDirections:["ascend","descend"]
        },
    ];

    return (
        <ThemeProvider themes={themes} selectedSize={size} selectedMode={themeMode}>
            <MainContainer>
                {isLoading ? (
                    <SmsSpin />
                ) : (
                    <div className="item-container">
                        <Table<ProfileOverviewData>
                            size='small'
                            bordered
                            rowKey="key"
                            pagination={
                                {
                                    showTotal(total: number, range:[number,number]) {
                                        return `${range[0]}-${range[1]} of ${total} items`
                                    },
                                    size:"small",
                                    defaultPageSize:25,
                                    pageSizeOptions:[10,25,50,100],
                                    defaultCurrent:1,
                                    showSizeChanger:true,
                                    className:"table-pagination",
                                }                                
                            }
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => navigate(`view/${record.id}`), // click row
                                }}}
                            dataSource={profileOverviewData}
                            columns={columns}
                        />
                    </div>
                )}
            </MainContainer>
        </ThemeProvider>
    );
}

export default observer(Profiles);