import { MenuItem } from '@plasma/ui.application.app-provider';

export const menuItems: MenuItem[] = [
    {
        label: 'systems.title',
        path: '/systems',
    },
    {
        label: 'configuration.title',
        path: '/configuration',
    },
];
