export class SystemDto {
    name: string;
    description?: string;

    constructor(name: string, description?: string) {
        this.name = name;
        this.description = description;
    };
}

export class SystemCompleteDto extends SystemDto {
    id: number;

    constructor(id: number, name: string, description: string) {
        super(name, description);
        this.id = id;
    };
}