import React from "react";

import { Route } from "@plasma/ui.application.router";

const ThingTypes = React.lazy(() => import("."));

export const ThingTypesRoutes = [
  new Route({
    path: "",
    element: ThingTypes,
  }),
];
