import Page from "@plasma/ui.layout.page";
import styled from "styled-components";

export const Layout = styled(Page)`
  height: 100%;
  overflow: hidden;
  width: 100%;
  background-color: white;
  .plasma-panel {
    width: 100%;
    padding: 0px;
    background-color: #f2f2f2;
    overflow: hidden;
  }
`;
