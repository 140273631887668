import { ProfileStatus } from "../../enums/ProfileEnums";

export class ProfileBase {
    name: string;
    thingTypeId: number;
    description?: string;    

    constructor(name: string, thingTypeId: number, description?: string) {
        this.name = name;
        this.thingTypeId = thingTypeId;
        this.description = description;        
    };
}

export class ProfileDto extends ProfileBase{
    thingSpecific: boolean;
    status: ProfileStatus;
    misconfigured: boolean;


    constructor(name: string, thingTypeId: number, thingSpecific: boolean, status: ProfileStatus, misconfigured: boolean, description?: string) {
        super(name,thingTypeId, description)
        this.thingSpecific = thingSpecific;
        this.status = status;
        this.misconfigured = misconfigured;
    };
}

export class ProfileCompleteDto extends ProfileDto {
    id: number;

    constructor(id: number, name: string, thingTypeId: number, thingSpecific: boolean, status: ProfileStatus, misconfigured: boolean, description?: string) {
        super(name, thingTypeId, thingSpecific, status, misconfigured, description);
        this.id = id;
    };
}
