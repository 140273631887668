import { useQuery } from "@tanstack/react-query";
import services from "../../../api/agent";

const useRelationTypesHook = () => {
    const { data:relationTypeOverviewData,isLoading } = useQuery(["relationTypesOverview"], () => Promise.all(
      [
        services.relationTypes.getAll(),
        services.thingTypes.getAll()
      ]
    ).then((r) => {
      const relationTypes = r[0].data
      const thingTypes = r[1].data
      return relationTypes.map((item)=> {
        const toThingTypeName = thingTypes?.find((e) => e.id == item.toThingTypeId)?.name??"";
        const fromThingTypeName = thingTypes?.find((e) => e.id == item.fromThingTypeId)?.name??"";
        return new RelationTypeOverviewData(item.id,item.name, toThingTypeName, fromThingTypeName, item.description)
      })
    })
  )
  return {
    relationTypeOverviewData,
    isLoading
  };
}


export class RelationTypeOverviewData {
  id: number;
  name: string;
  toThingType: string;
  fromThingType: string;
  description?: string;
  constructor(id:number, name: string, toThingType: string, fromThingType: string, description?: string) {
    this.id = id;
    this.name = name;
    this.toThingType = toThingType;
    this.description = description;
    this.fromThingType = fromThingType       
  };
}

export default useRelationTypesHook;