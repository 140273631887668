import { useQuery } from "@tanstack/react-query";
import services from "../../../api/agent";

const useThingTypesHook = () => {
    const { data:thingTypes,isLoading } = useQuery(["thingTypes"], services.thingTypes.getAll);
  return {
    thingTypes,
    isLoading
  };
}

export default useThingTypesHook;