import React from 'react';

import { StyledCard } from './ClickableItem.styles';
import { ClickableItemMetadata } from './ClickableItem.types';

export interface ClickableItemCardProps {
  item: ClickableItemMetadata;
  onSelect: () => void;
}

const ClickableItemCard = ({ item, onSelect }: ClickableItemCardProps) => {
  return (
    <StyledCard
      key={item.id}
      onClick={() => onSelect()}
      cardClassType='informationCard'
      metaCardClassType='metaInformationCard'
      meta = {{
        title: item.title
      }}
      hoverable
      contentAreaSlot={
        <>
          <div className="card-description">{item.description}</div>
        </>
        
      }
      actions={[item.footer?(<div className="card-footer">{item.footer}</div>):(null)]}
    />
  );
};

export default ClickableItemCard;