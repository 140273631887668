import React, { useEffect, useState } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import { Trans, useTranslation } from "react-i18next";
import Button from "@plasma/ui.general.button";
import Select from "@plasma/ui.input.select";
import { Icon } from "@plasma/ui.general.icon";
import styled from "styled-components";
import Popconfirm from "@plasma/ui.feedback.pop-confirm";
import {
  DataProvider,
  Parameter,
} from "../../../../models/dtos/data-provider/DataProviderDto";
import { queryClient } from "../../../../stores/QueryClient";

export default function DataProviderParameterForm({
  onChange,
  index,
  onSubmit,
  viewOnly,
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const cached = queryClient.getQueryData<{ provider: DataProvider }>([
    "selectedDataProvider",
  ]);
  const methods = useForm();
  useEffect(() => {
    if (cached?.provider.parameters)
      methods.setValue("parameter", cached?.provider.parameters);
  }, []);

  return (
    <ParameterForm>
      <Form
        className="form"
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        onChange={onChange}
        id={`submit-outside${index}`}
        methods={methods}
      >
        <FormField name={"parameter"} >
          <ParameterConfigurator disabled={viewOnly} />
        </FormField>
      </Form>
    </ParameterForm>
  );
}

const ParameterConfigurator = (parameters: any) => {

  const { t } = useTranslation();
  const [drawerIndex, setDrawerIndex] = useState<number>();
  const [tempEnum, setTempEnum] = useState<string[]>();
  const types = [
    { value: "Text", label: "Text" },
    { value: "Number", label: "Number" },
    { value: "Boolean", label: "Boolean" },
    { value: "Date", label: "Date" },
    { value: "Enum", label: "Enum" },
    { value: "Json", label: "Json" },
  ];
  const changeHandler = (index: number, key: string, value: string) => {
    if (parameters.value) {
      const newValue = [...parameters.value];
      newValue[index][key] = value;
      parameters.onChange(newValue);
    }
  };

  const addHandler = () => {
    if (parameters.value) {
      const newValue = [...parameters.value];
      newValue.push({
        name: "",
        type: undefined,
        description: "",
        enum: undefined,
      });
      parameters.onChange(newValue);
    } else {
      parameters.onChange([
        { name: "", type: undefined, description: "", enum: undefined },
      ]);
    }
  };
  const deleteHandler = (index: number) => {
    if (parameters.value) {
      const newValue = [...parameters.value];
      newValue.splice(index, 1);
      parameters.onChange(newValue);
    }
  };
  const addEnum = () => {
    const newValue = [...(tempEnum ?? [])];
    newValue.push("");
    setTempEnum(newValue);
  };

  const openEnumDrawer = (index: number) => {
    const newValue = [...parameters.value];
    setTempEnum(newValue[index].enum);
    setDrawerIndex(index);
  };

  const changeEnum = (enumIndex: number, value: string) => {
    const newValue = [...(tempEnum ?? [])];
    newValue[enumIndex] = value;
    setTempEnum(newValue);
  };
  const deleteEnum = (enumIndex: number) => {
    const newValue = [...(tempEnum ?? [])];
    newValue.splice(enumIndex, 1);
    setTempEnum(newValue);
  };

  const saveEnum = () => {
    const newValue = [...parameters.value];
    newValue[drawerIndex!].enum = tempEnum;
    parameters.onChange(newValue);
    setTempEnum(undefined);
    setDrawerIndex(undefined);
  };

  const cancelEnum = () => {
    setTempEnum(undefined);
    setDrawerIndex(undefined);
  };
  return (
    <>
      <ParameterGrid>
        <div className="row header">
          <span className="cell">{t("data-provider.name")}</span>
          <span className="cell">{t("data-provider.type")}</span>
          <span className="cell">{t("data-provider.description")}</span>
          <span className="cell">
            <div
              style={{ height: 0, overflow: "hidden" }}
              className="cell toolbar"
            >
              {!parameters.disabled ? (
                <Button
                  style={{ border: 0 }}
                  icon={<Icon name="delete" />}
                  type="tertiary"
                />
              ) : null}
            </div>
          </span>
        </div>
        {parameters?.value?.map((p: Parameter, ii: any) => (
          <div key={ii} className="row">
            <div className="cell">
              <TextInput
                onChange={(v) => changeHandler(ii, "name", v.target.value)}
                value={p.name}
                disabled={parameters.disabled}
              />
            </div>
            <div className="cell">
              <Select
                options={types}
                onChange={(v) => changeHandler(ii, "dataType", v)}
                value={p.dataType}
                disabled={parameters.disabled}
              />
            </div>
            <div className="cell">
              <TextInput
                onChange={(v) =>
                  changeHandler(ii, "description", v.target.value)
                }
                value={p.description}
                disabled={parameters.disabled}
              />
            </div>
            <div className="cell toolbar">
              {!parameters.disabled ? (
                <Popconfirm
                  okButtonProps={{ type: "tertiary" }}
                  cancelButtonProps={{ type: "tertiary" }}
                  icon={<Icon name="delete" />}
                  onConfirm={() => deleteHandler(ii)}
                  title={
                    <Trans
                      i18nKey={"delete-modalconfirm-message.short"}
                      values={{ name: p.name }}
                    />
                  }
                >
                  <Button
                    style={{ border: 0 }}
                    icon={<Icon name="delete" />}
                    type="tertiary"
                  />
                </Popconfirm>
              ) : null}
            </div>
          </div>
        ))}
      </ParameterGrid>
      {!parameters.disabled ? (
        <div>
          <Button
            style={{ marginLeft: 2 }}
            onClick={addHandler}
            icon={<Icon name="add" />}
            type="tertiary"
            title={t("button.add")}
          />
        </div>
      ) : null}
      {/* <Drawer
        closable={false}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
            <Button
              onClick={cancelEnum}
              type="secondary"
              title={t("button.cancel")}
            />
            <Button onClick={saveEnum} title={t("button.save")} />
          </div>
        }
        visible={drawerIndex !== undefined}
        icon={{ name: "edit" }}
        title="Enum"
      >
        <EnumCreator>
          {tempEnum?.map((x: any, ii: any) => (
            <div key={ii} className="enum-field">
              <TextInput
                className="input"
                onChange={(e) => changeEnum(ii, e.target.value)}
                value={x}
              />
              <Popconfirm
                okButtonProps={{ type: "tertiary" }}
                cancelButtonProps={{ type: "tertiary" }}
                icon={<Icon name="delete" />}
                onConfirm={() => deleteEnum(ii)}
                title={
                  <Trans
                    i18nKey={"delete-modalconfirm-message.short"}
                    values={{ name: x }}
                  />
                }
              >
                <Button
                  style={{ border: 0 }}
                  icon={<Icon name="delete" />}
                  type="tertiary"
                />
              </Popconfirm>
            </div>
          ))}
          <div>
            <Button
              onClick={addEnum}
              icon={<Icon name="add" />}
              type="tertiary"
              title={t("button.add")}
            />
          </div>
        </EnumCreator>
      </Drawer> */}
    </>
  );
};

const ParameterForm = styled.div`
  padding: 16px;
  width: 45%;
  min-width: 600px;
`;

const ParameterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  border-collapse: collapse;
  margin-bottom: 16px;
  .icon-button {
    border: 0px;
  }
  .row {
    display: contents;
  }
  .cell {
    padding-top: 16px;
    padding-right: 16px;
    &.toolbar {
      display: flex;
      padding-right: 0px;
      padding-right: 2px;
    }
  }
  .header .cell {
    padding-top: 0px;
  }
`;

const EnumCreator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .enum-field {
    gap: 10px;
    display: flex;
    .input {
      flex-grow: 1;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
  }
`;
