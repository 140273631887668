import React from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../../../stores/QueryClient";
import { RelationTypeDto } from "../../../../models/dtos/relation-type/RelationTypeDtos";
import Select from "@plasma/ui.input.select";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";

import Row from '@plasma/ui.layout.row'
import Column from '@plasma/ui.layout.col'

export default function RelationTypeGeneralForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const { data: availableThingTypes } = useQuery([], () =>
    services.thingTypes.getAll().then((r) => {
      return r.data.map((p) => ({ value: p.id, label: p.name }));
    })
  );
  const cached = queryClient.getQueryData<{ relationType: RelationTypeDto }>([
    "selectedRelationType",
  ]);
  return (
    <div className="form-container">
      <Form
        initialValues={cached?.relationType}
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={onChange}
      >
        <FormField required name={"name"} label={t("relation-type.property.name")}>
          <TextInput disabled={viewOnly}/>
        </FormField>
        <FormField name={"description"} label={t("relation-type.property.description")}>
          <TextArea rows={5} disabled={viewOnly}/>
        </FormField>
        <Row style={{margin:0}}>
          <Column span={6}>
            <FormField required name={"fromThingTypeId"} label={t("relation-type.property.from")}>
              <Select options={availableThingTypes ?? []} disabled={viewOnly}/>
            </FormField>
          </Column>
          <Column span={6}>
            <FormField required name={"toThingTypeId"} label={t("relation-type.property.to")}>
              <Select options={availableThingTypes ?? []} disabled={viewOnly}/>
            </FormField>
          </Column>
        </Row>
      </Form>
    </div>
  );
}
