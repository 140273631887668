import React from "react";
import Form from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import TextArea from "@plasma/ui.input.text-area";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";
import Select from "@plasma/ui.input.select";

import { queryClient } from "../../../../stores/QueryClient";
import { DataProvider } from "../../../../models/dtos/data-provider/DataProviderDto";

export default function DataProviderGeneralForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const { data } = useQuery([], () =>
    services.dataProviders.getTypes().then((r) => {
      return r.data.map((p) => ({ value: p.id, label: p.name }));
    })
  );
  const cached = queryClient.getQueryData<{ provider: DataProvider }>([
    "selectedDataProvider",
  ]);
  
  return (
    <div style={{ padding: 16, width: "45%", minWidth: "400px" }}>
      <Form
      initialValues={cached?.provider}
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={onChange}
      >
        <FormField
          required
          name={"dataProviderTypeId"}
          label={t("data-provider.feature-type")}
          validations={{
            fieldType: "string",
          }}
        >
          <Select options={data ?? []} disabled={viewOnly}/>
        </FormField>
        <FormField required name={"name"} label={t("data-provider.name")}>
          <TextInput disabled={viewOnly}/>
        </FormField>
        <FormField name={"description"} label={t("data-provider.description")}>
          <TextArea rows={5} disabled={viewOnly}/>
        </FormField>
      </Form>
    </div>
  );
}
