import React, { useEffect } from "react";
import ThemeProvider from "@plasma/ui.themes.theme-provider";
import useSelectionHook from "./selection.hook";
import * as styled from "./selection.styles";
import { useStore } from "../../../../stores/store";
import Button from "@plasma/ui.general.button";
import { useTranslation } from "react-i18next";
import { Icon } from "@plasma/ui.general.icon";
import { useNavigate } from "@plasma/ui.application.router";
import ClickableItemCard from "../../../../components/shared/ClickableItem/ClickableItem";
import TitlePage from "../../../../components/shared/TitlePage";
import { ClickableItemMetadata } from "../../../../components/shared/ClickableItem/ClickableItem.types";
import SmsSpin from "../../../../components/core/SmsSpin/SmsSpin";

export default function Selection() {
  const { providers, isLoading } = useSelectionHook();
  const { i18n, t } = useTranslation();
  const { ActionStore } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    ActionStore.setActions(
      <Button
        title={t("button.create.data-provider")}
        icon={<Icon name="add" />}
        onClick={() => navigate("create")}
      />
    );
    return () => {
      ActionStore.clearNodes();
    };
  }, [i18n.language]);
  return (
    <ThemeProvider>
      <styled.Selection>
          {isLoading ? (
            <SmsSpin />
          ) : providers?.data ? (
            <div className="item-container">
              {providers?.data.map((p) => (
                <ClickableItemCard
                  item={
                    new ClickableItemMetadata(p.id, p.name!, "", p.description)
                  }
                  onSelect={() =>navigate(`view/${p.id}`)}
                />
              ))}
            </div>
          ) : (
            "no elements"
          )}
      </styled.Selection>
    </ThemeProvider>
  );
}
