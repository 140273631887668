import React, { useEffect } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";
import { queryClient } from "../../../../stores/QueryClient";
import CheckboxGroup from "../../../../components/shared/CheckboxGroup/CheckboxGroup";
import { FeatureCompleteDto } from "../../../../models/dtos/FeatureDtos";

export default function ThingTypeFeaturesForm({
  onChange,
  index,
  onSubmit,
  viewOnly
}: {
  onChange: (value: any) => void;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean
}) {
  const { t } = useTranslation();
  const { data } = useQuery(["features"], services.features.getAll);
  const cached = queryClient.getQueryData<{ associatedFeatures: FeatureCompleteDto[] }>([
      "selectedThingType",
    ]);

  const methods = useForm()
  useEffect(()=>{
    if(cached?.associatedFeatures)
      methods.setValue("features",cached?.associatedFeatures.map((e)=>(e.id)))
  },[])
  return (
    <div className="form-container">
      <Form
        notifications={{ disabled: true }}
        submitCallback={() => {
          onSubmit();
        }}
        id={`submit-outside${index}`}
        className="form"
        onChange={(e) => onChange(e)}
        methods={methods}
      >
        <FormField name={"features"}>
          <CheckboxGroup 
            data={data?.data.map((d)=>({id:d.id,name:d.name}))??[]}
            disabled={viewOnly}
          />
        </FormField>
      </Form>
    </div>
  );
}