export type DataType = "Text" | "Number" | "Boolean" | "Date" | "Enum" | "Json";

export class DataProviderBase {
  dataProviderTypeId: number;
  name: string;
  description?: string;
  parameters?: Parameter[];

  constructor(
    dataProviderTypeId: number,
    name: string,
    description?: string,
    parameters?: Parameter[]
  ) {
    this.dataProviderTypeId = dataProviderTypeId;
    this.name = name;
    this.description = description;
    this.parameters = parameters;
  }
}

export class DataProvider extends DataProviderBase {
  id: number;

  constructor(
    id: number,
    dataProviderTypeId: number,
    name: string,
    parameters: Parameter[],
    description?: string,
  ) {
    super(dataProviderTypeId, name, description, parameters);
    this.id = id;
  }
}

export class Parameter {
  name?: string;
  description?: string;
  dataType: DataType;


  constructor(
    name: string,
    description: string,
    dataType: DataType,

  ) {
    this.name = name;
    this.description = description;
    this.dataType = dataType;
  }
}


