import { makeAutoObservable } from "mobx";
import { ReactNode } from "react";

export class ActionStore {
  actions: ReactNode = null;

  constructor() {
    makeAutoObservable(this);
  }
  setActions(actions: ReactNode) {
    this.actions = actions;
  }
  clearNodes() {
    this.actions = null;
  }
}
