import React from "react";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";


function useSelectionHook() {
  const { data:providers,isLoading } = useQuery(["dataProviders"], services.dataProviders.getAll);


  return {
    providers,
    isLoading
  };
}

export default useSelectionHook;
