import { ConfigurationSelectionRoutes } from "./configuration-selection";
import { ThingTypesRoutes } from "./thing-types";
import { CreateThingTypeRoutes } from "./thing-types/create-thing-type";
import { ProfilesRoutes } from "./profiles";
import { RelationTypeRoutes } from "./relation-types";
import { CreateRelationTypeRoutes } from "./relation-types/create-relation-type";
import { Route } from "@plasma/ui.application.router";
import { DataProviderSelectionRoutes } from "./data-providers/selection";
import { CreateProfileRoutes } from "./profiles/create-profile";
import { CreateDataProviderRoutes } from "./data-providers/create-data-provider";

const thingTypeRoutes = [
  new Route({
    path: "thing-types",
    handle: {
      crumb: (_params: any) => {
        return ["thing-types.title"];
      },
    },
    children: [...ThingTypesRoutes, ...CreateThingTypeRoutes],
  }),
];

const profileRoutes = [
  new Route({
    path: "profiles",
    handle: {
      crumb: (_params: any) => {
        return ["profiles.title"];
      },
    },
    children: [
      ...ProfilesRoutes,
      ...CreateProfileRoutes,
    ],
  }),
];

const relationTypeRoutes = [
  new Route({
    path: "relation-types",
    handle: {
      crumb: (_params: any) => {
        return ["relation-types.title"];
      },
    },
    children: [
      ...RelationTypeRoutes,
      ...CreateRelationTypeRoutes
    ],
  }),
];

const dataProviderRoutes = [
    new Route({
      path: 'data-providers',
      handle: {
        crumb: (params: any) => {
          return ["data-providers.title"];
        },
      },
      children: [
        ...DataProviderSelectionRoutes,
        ...CreateDataProviderRoutes
      ],
    }),
]

export const ConfigurationRoutes = [
  new Route({
    path: "configuration",
    handle: {
      crumb: (_params: any) => {
        return ["configuration.title"];
      },
    },
    children: [
      ...ConfigurationSelectionRoutes,
      ...thingTypeRoutes,
      ...profileRoutes,
      ...relationTypeRoutes,
      ...dataProviderRoutes
    ],
  }),
];
