import React, { useEffect, useState } from "react";
import Form, { useForm } from "@plasma/ui.input.form";
import FormField from "@plasma/ui.input.form-field";
import TextInput from "@plasma/ui.input.text-input";
import { Trans, useTranslation } from "react-i18next";
import { Divider, Table } from "antd";
import { queryClient } from "../../../../stores/QueryClient";
import { useQuery } from "@tanstack/react-query";
import services from "../../../../api/agent";
import Select from "@plasma/ui.input.select";
import Collapse from "@plasma/ui.layout.collapse";
import { DataQueryType } from "../../../../models/dtos/data-provider/DataQueryType";
import { Parameter } from "../../../../models/dtos/data-provider/DataProviderDto";
import { ColumnsType } from "antd/lib/table";
import {
  DataQuery,
  ParameterMappingTypeEnum,
} from "../../../../models/dtos/data-provider/DataQueryDto";
import {
  Connector,
  DataProviderConnectorDto,
  IActionInputInfo,
} from "../../../../models/dtos/data-provider/ConnectorDto";
import Button from "@plasma/ui.general.button";
import { Icon } from "@plasma/ui.general.icon";
import Drawer from "@plasma/ui.layout.drawer";
import Checkbox from "@plasma/ui.input.checkbox";
import styled from "styled-components";
import Popconfirm from "@plasma/ui.feedback.pop-confirm";
import { DataQueryForm, DataQueryFormParameterMapping } from "./QueriesClass";
import Tag from "@plasma/ui.display.tag";

export default function DataProviderQueriesForm({
  parameters,
  onChange,
  connectorName,
  index,
  onSubmit,
  viewOnly,
}: {
  parameters?: Parameter[];
  onChange: (value: { [key: string]: DataQueryForm }) => void;
  connectorName: string;
  index: number;
  onSubmit: () => any;
  viewOnly: boolean;
}) {
  const { t } = useTranslation();
  const { data } = useQuery(["dataQueryType"], services.dataQueryType.getAll, {
    refetchOnWindowFocus: false,
  });
  const cached = queryClient.getQueryData<{
    connector: DataProviderConnectorDto;
    queries: DataQuery[];
  }>(["selectedDataProvider"]);
  const cachedConnectors = queryClient.getQueryData<{ data: Connector[] }>([
    "connectors",
  ])?.data;

  useEffect(() => {
    if (cached?.queries && data) {
      data?.data.forEach((q) => {
        const queryData = cached.queries.find(
          (qd) => qd.dataQueryTypeId === q.id
        );
        const actionParams = cachedConnectors
          ?.find((c) => c.name === cached.connector.connectorName)
          ?.actions.find((x) => x.name === queryData?.action)?.inputs;
        methods.setValue(q.name, {
          ...queryData,
          parameterMapping: queryData?.parameterMapping?.map((p) => ({
            ...p,
            standard: !!actionParams?.find((x) => x.name === p.actionInputName),
          })),
        });
      });
    }
  }, [cached, data]);

  const methods = useForm();
  return (
    <div style={{ padding: 16, width: "100%", minWidth: "400px" }}>
      {data ? (
        <Form
          className="form"
          onChange={(x) => onChange(x)}
          notifications={{ disabled: true }}
          submitCallback={() => {
            onSubmit();
          }}
          id={`submit-outside${index}`}
          methods={methods}
        >
          <Collapse>
            {data.data?.map((d, ii) => (
              <Collapse.Panel header={d.name} key={ii}>
                <FormField
                  valuePropName="value"
                  required
                  name={d.name}
                  label={t("data-provider.action")}
                >
                  <QueriesTable
                    parameters={parameters}
                    connector={connectorName}
                    dataQueryType={d}
                    connectors={cachedConnectors}
                    disabled={viewOnly}
                  />
                </FormField>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Form>
      ) : null}
    </div>
  );
}

const QueriesTable = ({
  parameters,
  onChange,
  connector,
  dataQueryType,
  value,
  connectors,
  disabled,
}: {
  parameters?: Parameter[];
  connector: string;
  dataQueryType: DataQueryType;
  onChange?: (x: DataQueryForm) => void;
  value?: DataQueryForm;
  connectors?: Connector[];
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  const [paramToConfigure, setParamToConfigure] = useState<string>();
  const actionSelect = (actionName?: string) => {
    const params =
      connectors
        ?.filter((x) => x.name === connector)[0]
        .actions?.find((a) => a.name === actionName)?.inputs ?? [];

    onChange!({
      dataQueryTypeId: dataQueryType.id,
      action: actionName,
      parameterMapping: params.map(
        (p: IActionInputInfo) => new DataQueryFormParameterMapping(p)
      ),
    });
  };
  const paramChanged = (
    param: string,
    prop: string,
    newValue?: string | string[]
  ) => {
    let newObject = { ...value! };

    if (prop === "parameters") {
      let newTempObject = { ...value! };

      var configProps = value!.parameterMapping.find(
        (p) => p.actionInputName === paramToConfigure!
      )?.parameters;

      const propsToDelete =
        configProps?.filter((p) => !newValue?.includes(p)) ?? undefined;

      value!.parameterMapping.forEach((p) => {
        if (
          propsToDelete?.includes(p.actionInputName) &&
          p.parameters &&
          p.parameters.length > 0
        ) {
          propsToDelete.push(...p.parameters);
        }
      });

      const newParams = (newValue as string[])
        ?.filter((p) => !configProps?.includes(p))
        .map(
          (p) =>
            ({
              actionInputName: p,
              standard: false,
            }) as DataQueryFormParameterMapping
        );

      if (propsToDelete) {
        const indexes = newTempObject.parameterMapping
          .map((value, index) => ({
            actionInputName: value.actionInputName,
            index: index,
          }))
          .filter((item) => !propsToDelete.includes(item.actionInputName))
          .map((item) => item.index);

        newTempObject.parameterMapping = newTempObject.parameterMapping?.filter(
          (_, ii) => indexes?.includes(ii)
        );
      }

      newParams?.forEach((p) => {
        newTempObject.parameterMapping?.push(p);
      });

      var flatParamMapping = [
        ...(newTempObject.parameterMapping
          .filter((p) => p.parameters !== undefined)
          .flatMap((p) => p.parameters) ?? []),
        ...(newValue ?? []),
      ];

      newTempObject.parameterMapping = newTempObject.parameterMapping.filter(
        (p) => p.standard || flatParamMapping.includes(p.actionInputName)
      );

      newObject = newTempObject;
    }

    (newObject.parameterMapping.find((p) => p.actionInputName === param)![
      prop as keyof DataQueryFormParameterMapping
    ] as string | undefined | string[]) = newValue as
      | string
      | undefined
      | string[];

    onChange!(newObject);
  };

  const [tempConfig, setTempConfig] = useState<string>();
  const [tempParam, setTempParam] = useState<string[]>();
  const [valueChecked, setValueChecked] = useState(false);
  const [paramChecked, setParamChecked] = useState(false);

  const saveConfig = () => {
    paramChanged(
      paramToConfigure!,
      "providerParameter",
      valueChecked ? tempConfig : undefined
    );
    paramChanged(
      paramToConfigure!,
      "parameters",
      paramChecked ? tempParam : undefined
    );
    resetConfig();
  };

  const cancelConfig = () => {
    resetConfig();
  };

  const resetConfig = () => {
    setTempParam(undefined);
    setTempConfig(undefined);
    setParamToConfigure(undefined);
    setParamChecked(false);
    setValueChecked(false);
  };

  const columns: ColumnsType<DataQueryFormParameterMapping> = [
    {
      title: t("data-provider.parameter.name"),
      dataIndex: "actionInputName",
      key: "actionInputName",
      width: "16.7%",
    },
    {
      title: t("data-provider.parameter.description"),
      dataIndex: "description",
      key: "description",
      width: "16.7%",
    },
    {
      title: t("data-provider.parameter.type"),
      dataIndex: "dataType",
      key: "type",
      width: "16.7%",
      render: (_, row) => {
        return connectors
          ?.find((c) => c.name === connector)
          ?.actions.find((a) => a.name === value?.action)
          ?.inputs.find((i) => i.name === row.actionInputName)?.type;
      },
    },
    {
      title: t("data-provider.parameter.mapping-type"),
      key: "mappingType",
      dataIndex: "type",
      width: "16.7%",
      render: (value, row) => (
        <Select
          style={{ maxWidth: "100%" }}
          value={value}
          onChange={(x) => paramChanged(row.actionInputName!, "type", x)}
          options={Object.values(ParameterMappingTypeEnum).map((value) => ({
            value,
            label: value,
          }))}
          disabled={disabled}
        />
      ),
    },
    {
      title: t("data-provider.parameter.mapping"),
      key: "mapping",
      dataIndex: "value",
      width: "16.7%",
      render: (value, row) =>
        row.type === "FixedValue" ? (
          <TextInput
            style={{ maxWidth: "100%" }}
            onChange={(x) =>
              paramChanged(row.actionInputName!, "value", x.target.value)
            }
            value={row.value}
            disabled={disabled}
          />
        ) : row.type === "QueryParameter" ? (
          <Select
            allowClear
            style={{ maxWidth: "100%" }}
            value={value}
            onChange={(x) => paramChanged(row.actionInputName!, "value", x)}
            options={dataQueryType.parameters?.map((p) => ({
              label: p.name,
              value: p.name,
            }))}
            disabled={disabled}
          />
        ) : null,
    },
    {
      width: "16.7%",
      title: t("data-provider.parameter.configuration"),
      key: "actions",
      render: (value, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {row.providerParameter ? (
            <Tag color="#A8E6CF">{t("data-provider.parameter.value")}</Tag>
          ) : row.parameters ? (
            <Tag color="#FFAAA5">{t("data-provider.parameter")}</Tag>
          ) : null}

          <Button
            style={{ border: 0 }}
            type="tertiary"
            onClick={() => {
              setParamToConfigure(row.actionInputName);
            }}
            icon={
              disabled === true ? (
                <Icon name="visibility" />
              ) : (
                <Icon name="edit" />
              )
            }
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    const parameters = value?.parameterMapping.find(
      (p) => p.actionInputName === paramToConfigure
    )?.parameters;
    const config = value?.parameterMapping.find(
      (p) => p.actionInputName === paramToConfigure
    )?.providerParameter;

    if (parameters) {
      setTempParam(parameters);
      setParamChecked(true);
    } else if (config) {
      setTempConfig(config);
      setValueChecked(true);
    }
  }, [paramToConfigure]);

  const addParam = () => {
    const newValue = [...(tempParam ?? [])];
    newValue.push("");
    setTempParam(newValue);
  };

  const changeParam = (paramIndex: number, value: string) => {
    const newValue = [...(tempParam ?? [])];
    newValue[paramIndex] = value;
    setTempParam(newValue);
  };

  const deleteParam = (paramIndex: number) => {
    const newValue = [...(tempParam ?? [])];
    newValue.splice(paramIndex, 1);
    setTempParam(newValue);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Select
          value={value?.action}
          style={{ width: "50%", minWidth: 200 }}
          onClear={() => actionSelect(undefined)}
          allowClear
          onChange={(x) => actionSelect(x)}
          options={connectors
            ?.filter((x) => x.name === connector)[0]
            ?.actions.map((c: { name: string }) => ({
              label: c.name,
              value: c.name,
            }))}
          disabled={disabled}
        />
        <Table
          bordered
          pagination={false}
          dataSource={
            value?.parameterMapping ? [...value.parameterMapping] : []
          }
          columns={columns}
        />
      </div>
      <Drawer
        maskClosable={false}
        destroyOnClose
        onClose={() => setParamToConfigure(undefined)}
        visible={!!paramToConfigure}
        title={t("data-provider.parameter.configuration")}
        icon={{ name: "edit" }}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
            <Button
              onClick={cancelConfig}
              type="secondary"
              title={t("button.cancel")}
            />
            {!disabled ? (
              <Button onClick={saveConfig} title={t("button.save")} />
            ) : null}
          </div>
        }
      >
        <Checkbox
          onChange={(x) => {
            if (x.target.checked) setParamChecked(false);
            setValueChecked(x.target.checked);
          }}
          checked={valueChecked}
          label={t("data-provider.parameter.value")}
          disabled={disabled}
        />
        <Select
          value={tempConfig}
          allowClear
          onClear={() => setTempConfig(undefined)}
          onChange={(x) => setTempConfig(x)}
          options={parameters?.map((p) => ({ label: p.name, value: p.name }))}
          disabled={disabled}
        />
        <Divider style={{ width: "100%" }} />
        <Checkbox
          checked={paramChecked}
          onChange={(x) => {
            setParamChecked(x.target.checked);
            if (x.target.checked) setValueChecked(false);
          }}
          label={t("data-provider.parameter")}
          disabled={disabled}
        />
        <ParamCreator>
          {tempParam?.map((p, ii) => (
            <div key={ii} className="enum-field">
              <TextInput
                className="input"
                onChange={(e) => changeParam(ii, e.target.value)}
                value={p}
                disabled={disabled}
              />
              {!disabled ? (
              <Popconfirm
                okButtonProps={{ type: "tertiary" }}
                cancelButtonProps={{ type: "tertiary" }}
                icon={<Icon name="delete" />}
                onConfirm={() => deleteParam(ii)}
                title={
                  <Trans
                    i18nKey={"delete-modalconfirm-message.short"}
                    values={{ name: p }}
                  />
                }
              >
                <Button
                  style={{ border: 0 }}
                  icon={<Icon name="delete" />}
                  type="tertiary"
                />
              </Popconfirm>
              ):null}
            </div>
          ))}
          {!disabled ? (
          <div>
            <Button
              onClick={addParam}
              icon={<Icon name="add" />}
              type="tertiary"
              title={t("button.add")}
            />
          </div>
          ):null}
        </ParamCreator>
      </Drawer>
    </>
  );
};

const ParamCreator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .enum-field {
    gap: 10px;
    display: flex;
    .input {
      flex-grow: 1;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
  }
`;
