export class RelationTypeConstraintBase {
    fromProfileId: number;
    toProfileId: number;
    constructor(
        fromProfileId: number,
        toProfileId: number,
    ) 
    {
        this.fromProfileId = fromProfileId;
        this.toProfileId = toProfileId
    };
    
}

export class RelationTypeConstraintDto extends RelationTypeConstraintBase{
    
    relationTypeId: number;

    constructor(
        fromProfileId: number,
        toProfileId: number,
        relationTypeId: number,
    ) 
    {
        super(fromProfileId, toProfileId)
        this.relationTypeId = relationTypeId;
    };
}

export class RelationTypeConstraintCompleteDto extends RelationTypeConstraintDto {
    id: number;

    constructor(
        id: number, 
        fromProfileId: number,
        toProfileId: number,
        relationTypeId: number,) 
    {
        super(fromProfileId, toProfileId, relationTypeId);
        this.id = id;
    };
}