import React, { memo, useState } from "react";
import ThemeProvider from "@plasma/ui.themes.theme-provider";
import useCreateDataProviderHook from "./create-data-provider.hook";
import * as styled from "./create-data-provider.styles";
import Steps from "@plasma/ui.navigation.steps";
import { useTranslation } from "react-i18next";
import Button from "@plasma/ui.general.button";
import ConfirmModal from "../../../../components/shared/ConfirmModal/ConfirmModal";
import Tabs, { CustomTabPane } from "@plasma/ui.navigation.tabs";
import { Icon } from "@plasma/ui.general.icon";

function CreateDataProvider() {

  const { steps, currentStep, closeHandler,prevStepHandler, setCurrentStep, isSaving, actionType } = useCreateDataProviderHook();

  const { t } = useTranslation();

  return (
    <ThemeProvider>
      <styled.CreateDataProvider className={`create-data-provider`}>
        {actionType !== "view" ?
          <div className="step-container">
            <Steps direction="vertical" current={currentStep}>
              {steps.map((step, ii) => (
                <Steps.Step
                  key={ii}
                  title={t(step.title)}
                />
              ))}
            </Steps>
          </div>
          : null}
        <div className="create-container">
          <div className="create-content">
            <Tabs
              onTabClick={(x) => { if (actionType === "view") setCurrentStep(parseInt(x)) }}
              className="tabs"
              tabBarStyle={{ display: actionType !== "view" ? "none" : "unset" }}
              activeKey={currentStep.toString()}
            >
              {steps.map((s, ii) => (
                <Tabs.TabPane tab={
                  <CustomTabPane
                    icon={<Icon name={s.icon} variant="filled" />}
                    title={t(s.title)}
                  />
                } key={ii.toString()}>
                  {s.element}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </div>
          <div className="create-toolbar">
          {actionType !== "view" ? (
            <>
              <ConfirmModal
                okButtonType="danger"
                onOk={closeHandler}
                content={t("confirm.creation.exit.description")}
                title={t("confirm.creation.exit.title")}
                okText={t("button.exit")}
                confirm={true}
                cancelText={t("button.stay")}
              >
                <Button type="text" title={t("button.cancel")} />
              </ConfirmModal>
              <div className="button-container">
              {currentStep > 0 ? (
                <Button
                  type="secondary"
                  onClick={prevStepHandler}
                  title={t("button.previous")}
                />
              ) : null}
              {(currentStep < steps.length - 1) ? (
                <Button htmlType="submit" form={`submit-outside${currentStep}`} title={t("button.next")} />
              ) : <Button loading={isSaving} htmlType="submit" form={`submit-outside${currentStep}`} title={t("button.save")} />}
            </div>
            </>
            ) : (
              <Button
                type="text"
                title={t("button.close")}
                onClick={closeHandler}
              />
            )}
          </div>
        </div>
      </styled.CreateDataProvider>
    </ThemeProvider>
  );
}

export default memo(CreateDataProvider) as unknown as typeof CreateDataProvider;